/* eslint-disable no-nested-ternary */
import { atom } from 'jotai';
import { DateTime } from 'luxon';
import { isDate } from 'lodash';
import { IDistrictSemester } from '@edanalytics/ff_be_se';
import { inRange } from '../../../utils/TimeHelper';
import { DistrictServiceAtoms } from '../../../services/DistrictAtoms';
import { LESSON_PLAN_DAYS_AHEAD } from '../../../config/constants';

function generateMonthsBetweenDates(semesters: IDistrictSemester[]) {
  // Find earliest startDate and latest endDate
  if (!semesters || semesters.length === 0) return [];
  const earliestStart = new Date(
    Math.min.apply(
      null,
      semesters.map((semester) => {
        const startDateString = isDate(semester.startDate) ? semester.startDate.toISOString() : (semester.startDate as string);
        const startDate = DateTime.fromISO(startDateString);
        return startDate.toMillis();
      }),
    ),
  );
  const latestEnd = new Date(
    Math.max.apply(
      null,
      semesters.map((semester) => {
        const endDateString = isDate(semester.endDate) ? semester.endDate.toISOString() : (semester.endDate as string);
        const endDate = DateTime.fromISO(endDateString);
        return endDate.toMillis();
      }),
    ),
  );

  const result = [];

  const nextMonth = DateTime.now().plus({ days: LESSON_PLAN_DAYS_AHEAD }).toISODate().substring(0, 7);

  while (earliestStart.getTime() <= latestEnd.getTime()) {
    const formattedValue = earliestStart.toISOString().substring(0, 7);
    const formattedLabel = DateTime.fromJSDate(earliestStart).toLocaleString({ month: 'long', year: 'numeric' });
    const pastToNextMonth = formattedValue <= nextMonth;

    result.push({
      val: formattedValue,
      label: formattedLabel,
      pastToNextMonth,
    });
    earliestStart.setMonth(earliestStart.getMonth() + 1);
  }

  return result;
}

function getCurrentSchoolYearSemesters(semesters: IDistrictSemester[]) {
  const semestersBySchoolYear = semesters.reduce((result, semester: any) => {
    const schoolYear = semester.startSchoolYear;
    if (!result[schoolYear]) {
      result[schoolYear] = [];
    }
    result[schoolYear].push(semester);
    return result;
  }, {} as { [key: string]: IDistrictSemester[] });

  // go through each school year and find the one that contains today's date
  const currentSchoolYearSemesters = Object.keys(semestersBySchoolYear).reduce((result, year: string) => {
    const semestersArray = semestersBySchoolYear[year];
    const earliestStart = new Date(
      Math.min.apply(
        null,
        semestersArray.map((semester: { startDate: string | number | Date }) => {
          const startDate = new Date(semester.startDate);
          return startDate.getTime();
        }),
      ),
    );
    const latestEnd = new Date(
      Math.max.apply(
        null,
        semesters.map((semester) => {
          const endDate = new Date(semester.endDate);
          return endDate.getTime();
        }),
      ),
    );
    // if today's date is between the earliest start and latest end, then this is the current school year
    const today = new Date();
    const isCurrentSchoolYear = inRange(today, { start: earliestStart, end: latestEnd });
    if (isCurrentSchoolYear) {
      result = semestersArray;
    }
    return result;
  }, [] as IDistrictSemester[]);

  return currentSchoolYearSemesters;
}

export const currentSchoolYearSemestersAtom = atom((get) => {
  const district = get(DistrictServiceAtoms.getCurrentDistrict);
  const currentSchoolYearSemesters = getCurrentSchoolYearSemesters(district?.semesters!);

  return currentSchoolYearSemesters;
});

export const currentSchoolYearAtom = atom((get) => {
  const currentSchoolYearSemesters = get(currentSchoolYearSemestersAtom);
  const currentSchoolYear = currentSchoolYearSemesters[0].startSchoolYear;
  return currentSchoolYear;
});

export const districtMonthsAtom = atom((get) => {
  const currentSchoolYearSemesters = get(currentSchoolYearSemestersAtom);
  return generateMonthsBetweenDates(currentSchoolYearSemesters);
});

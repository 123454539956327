/* eslint-disable no-nested-ternary */
import { FormErrorMessage, ButtonGroup, Box, Flex, FormControl, FormLabel, Stack, Text, Input, SimpleGrid } from '@chakra-ui/react';
import _, { forEach, isEmpty } from 'lodash';
import { Form } from 'formik';
import { useAtom, useAtomValue } from 'jotai';
import { RESET, useResetAtom } from 'jotai/utils';
import React, { useEffect, useState } from 'react';
import { DistrictDto, IDistrictSemester, DistrictSemesterDto, FFRoles, SchoolDto } from '@edanalytics/ff_be_se';
import { DateTime as DT } from 'luxon';
import { userInfoAtom } from '../../ffApi';
import { DateTimePicker } from '../../components/DateTimePicker';
import { FfFormikButton } from '../../components/Formik/FfFormikButton';
import { FfFormikDateTimePicker } from '../../components/Formik/FfFormikDateTimePicker';
import { FfFormikForm } from '../../components/Formik/FfFormikForm';
import { FfFormikInput } from '../../components/Formik/FfFormikInput';
import { FfButtonTypes } from '../../models/Button';
import { DiffDays, SchoolYearFormat } from '../../components/Utils';

import {
  DistrictService as districtService,
  semesterDialogState,
  SchoolYearSemester,
  DistrictServiceAtoms,
} from '../../services/DistrictAtoms';

export interface IDistrictSemesterForm {
  onReFresh: (data: IDistrictSemester[]) => void;
}

export const DistrictSemesterForm: React.FunctionComponent<IDistrictSemesterForm> = (formProps: IDistrictSemesterForm) => {
  const [dialogState, setDialogState] = useAtom(semesterDialogState);
  const [initVals, setInitVals] = React.useState<SchoolYearSemester>();
  const [districtServiceCalls] = useAtom(districtService);
  const [rulesValid, setRulesValid] = React.useState<boolean>(true);
  const [isEdit, setisEdit] = React.useState<boolean>();
  const userInfo = useAtomValue(userInfoAtom);
  // const hasAccess = (s: SchoolDto) => userInfo?.role === 'Staff' ;

  const resetForm = () => {
    setDialogState(RESET);
  };

  const onSave = (values: SchoolYearSemester) => {
    const districts: any[] = [];

    if (values.springStart && values.fallEnd) {
      const d1 = DiffDays(values.springStart, values.fallEnd);
      console.log('di = ', d1, ' 1 ', values.springStart, ' 2 ', values.fallEnd);
    }

    if (values.summerStart && values.springEnd) {
      const d2 = DiffDays(values.summerStart, values.springEnd);
      console.log('d2 = ', d2);
    }

    const isvalid =
      values.fallStart &&
      values.fallEnd &&
      values.springStart &&
      values.springEnd &&
      values.summerStart &&
      values.summerEnd &&
      values.fallStart.getFullYear() === values.schoolYear &&
      values.fallStart < values.fallEnd &&
      values.springStart < values.springEnd &&
      values.summerStart < values.summerEnd &&
      DiffDays(values.springStart, values.fallEnd) === 1 &&
      DiffDays(values.summerStart, values.springEnd) === 1;
    // spring start occurs day after fall end
    // summer start occures day after spring end

    setRulesValid(isvalid as boolean);

    if (!isvalid) {
      return;
    }

    if (values.fallStart && values.fallEnd) {
      const fallSem = {} as IDistrictSemester;
      fallSem.districtId = dialogState?.district.id;
      fallSem.startSchoolYear = values.schoolYear;
      fallSem.startDate = values.fallStart;
      fallSem.endDate = values.fallEnd;
      fallSem.name = 'Fall';
      districts.push(fallSem);
    }

    if (values.springStart && values.springEnd) {
      const springSem = {} as IDistrictSemester;
      springSem.districtId = dialogState?.district.id;
      springSem.startSchoolYear = values.schoolYear;
      springSem.startDate = values.springStart;
      springSem.endDate = values.springEnd;
      springSem.name = 'Spring';
      districts.push(springSem);
    }

    if (values.summerStart && values.summerEnd) {
      const summerSem = {} as IDistrictSemester;
      summerSem.districtId = dialogState?.district.id;
      summerSem.startSchoolYear = values.schoolYear;
      summerSem.startDate = values.summerStart;
      summerSem.endDate = values.summerEnd;
      summerSem.name = 'Summer';
      districts.push(summerSem);
    }

    if (dialogState?.mode === 'edit') {
      districtServiceCalls.putDistrictSemesters(dialogState?.district.id, districts).then((ff) => {
        console.log(' ff = > ', ff);
        resetForm();
      });
    } else {
      districtServiceCalls.postDistrictSemesters(dialogState?.district.id, districts).then((ff) => {
        console.log(' ff = > ', ff);
        formProps.onReFresh(ff as IDistrictSemester[]);

        resetForm();
      });
    }
  };

  const setInitialModel = (props: any) => {
    const newModel = new SchoolYearSemester();
    newModel.schoolYear = props && props.schoolYear ? props.schoolYear : new Date().getFullYear();
    newModel.schoolYearLabel = props && props.schoolYearLabel ? props.schoolYearLabel : '';
    newModel.fallStart = props && props.fall ? props.fall.startDate : props.fallStart;
    newModel.fallEnd = props && props.fall ? props.fall.endDate : props.fallEnd;
    newModel.springStart = props && props.spring ? props.spring.startDate : props.springStart;
    newModel.springEnd = props && props.spring ? props.spring.endDate : props.springEnd;
    newModel.summerStart = props && props.summer ? props.summer.startDate : props.summerStart;
    newModel.summerEnd = props && props.summer ? props.summer.endDate : props.summerEnd;

    setInitVals(newModel);
  };

  useEffect(() => {
    let groupedBySchoolYr: any = [];

    if (dialogState?.district) {
      const semestersSorted = _.sortBy(dialogState?.district.semesters, (s: IDistrictSemester) => s.startDate);
      const groupedByYear = _.groupBy(semestersSorted, 'startSchoolYear');
      const keys = Object.keys(groupedByYear);
      let yrval: number = 0;

      for (let i = 0; i < keys.length; i += 1) {
        yrval = +keys[i] + 1;

        groupedBySchoolYr.push({
          fall: groupedByYear[keys[i]][0],
          spring: groupedByYear[keys[i]][1],
          summer: groupedByYear[keys[i]][2],
          year: +keys[i],
          schoolYearLabel: `${SchoolYearFormat(keys[i])}`,
        });
      }
      groupedBySchoolYr = groupedBySchoolYr.reverse();
    }

    if (dialogState?.mode === 'edit') {
      setisEdit(true);
      const curyr = _.filter(groupedBySchoolYr, (o) => o.year.toString() === dialogState?.currentSchoolYr).pop();

      curyr.schoolYear = curyr.year;
      curyr.schoolYearLabel = `${SchoolYearFormat(curyr.year)}`;
      setInitialModel(curyr);
    } else {
      const yr = new SchoolYearSemester();

      if (groupedBySchoolYr && groupedBySchoolYr.length > 0) {
        const topyr = _.maxBy(groupedBySchoolYr, (o: any) => o.year);
        const nxtyr = +topyr.year + 1;
        const nxtyr2 = nxtyr + 1;
        yr.schoolYear = +topyr.year + 1;
        yr.schoolYearLabel = `${nxtyr}-${nxtyr2.toString().substring(2)}`;
      } else {
        const curDate = new Date();
        const curYear = curDate.getFullYear();
        yr.schoolYear = curYear;
        yr.schoolYearLabel = `${SchoolYearFormat(curYear)}`;
      }

      const nxtyr = +yr.schoolYear + 1;
      yr.fallStart = new Date(`${yr.schoolYear}-08-01 00:00:00`);
      yr.fallEnd = new Date(`${yr.schoolYear}-12-01 00:00:00`);
      yr.springStart = new Date(`${yr.schoolYear}-12-02 00:00:00`);
      yr.springEnd = new Date(`${nxtyr}-06-01 00:00:00`);
      yr.summerStart = new Date(`${nxtyr}-06-02 00:00:00`);
      yr.summerEnd = new Date(`${nxtyr}-08-01 00:00:00`);

      setInitialModel(yr);
    }

    /* eslint-disable no-lone-blocks */
  }, [dialogState]);

  const onCancel = resetForm;

  return (
    <>
      {initVals && (
        <FfFormikForm
          initialValues={initVals as SchoolYearSemester}
          validationClass={SchoolYearSemester}
          submit={(values) => Promise.resolve(onSave(values))}
        >
          {({ values, isSubmitting, errors }) => {
            const thedistrict = dialogState?.district !== null;

            return (
              <Form>
                <Box color={'ff.goals'} fontSize="xl" fontWeight="bold">
                  {'Select the dates for the selected school year.'}
                </Box>

                <Box marginTop="40px">
                  <FormControl>
                    <FormLabel htmlFor="schoolYearLabel" color={'white'}>
                      School Year
                    </FormLabel>
                    {/* <Input type="text" width="100%" id="schoolYear" placeholder="" bg="white" /> */}
                    <FfFormikInput id="schoolYearLabel" type="text" isDisabled={true}></FfFormikInput>
                  </FormControl>

                  <SimpleGrid columns={3} spacingX="30px" color={'white'}>
                    <Box></Box>
                    <Box>
                      <FormControl>
                        <FormLabel>Start Date</FormLabel>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl>
                        <FormLabel>End Date</FormLabel>
                      </FormControl>
                    </Box>
                    <Box>
                      <FormControl>
                        <FormLabel paddingTop={'3'}>Fall Semester</FormLabel>
                      </FormControl>
                      <FormControl>
                        <FormLabel paddingTop={'3'}>Spring Semester</FormLabel>
                      </FormControl>
                      <FormControl>
                        <FormLabel paddingTop={'3'}>Summer Semester</FormLabel>
                      </FormControl>
                    </Box>
                    <Box color="black">
                      {/* the key is on input box click, make z-index=1 then rmove it after lose focus
                      so we need to set a flag on the Box element above and the specific control being focused
                    */}

                      <FfFormikDateTimePicker
                        id="fallStart"
                        label=""
                        isDisabled={userInfo?.role !== 'Staff'}
                        filterDate={(date: Date) => DT.fromJSDate(date) >= DT.now().startOf('day')}
                        minDate={new Date(`${values.schoolYear}'-01-01 00:00:00`)}
                        selectedDate={values.fallStart}
                        dateFormat={'MM/dd/yyyy'}
                      />

                      <FfFormikDateTimePicker
                        id="springStart"
                        label=""
                        isDisabled={userInfo?.role !== 'Staff'}
                        filterDate={(date: Date) => DT.fromJSDate(date) >= DT.now().startOf('day')}
                        selectedDate={values.springStart}
                        dateFormat={'MM/dd/yyyy'}
                      />

                      <FfFormikDateTimePicker
                        id="summerStart"
                        label=""
                        isDisabled={userInfo?.role !== 'Staff'}
                        filterDate={(date: Date) => DT.fromJSDate(date) >= DT.now().startOf('day')}
                        dateFormat={'MM/dd/yyyy'}
                      />
                    </Box>
                    <Box color="black">
                      <FfFormikDateTimePicker
                        id="fallEnd"
                        label=""
                        isDisabled={userInfo?.role !== 'Staff'}
                        filterDate={(date: Date) => DT.fromJSDate(date) >= DT.now().startOf('day')}
                        dateFormat={'MM/dd/yyyy'}
                      />

                      <FfFormikDateTimePicker
                        id="springEnd"
                        label=""
                        isDisabled={userInfo?.role !== 'Staff'}
                        filterDate={(date: Date) => DT.fromJSDate(date) >= DT.now().startOf('day')}
                        dateFormat={'MM/dd/yyyy'}
                      />

                      <FfFormikDateTimePicker
                        id="summerEnd"
                        label=""
                        isDisabled={userInfo?.role !== 'Staff'}
                        filterDate={(date: Date) => DT.fromJSDate(date) >= DT.now().startOf('day')}
                        dateFormat={'MM/dd/yyyy'}
                      />
                    </Box>
                  </SimpleGrid>
                </Box>
                {!rulesValid && (
                  <Text fontSize="xs" color={'ff.palePink'}>
                    The dates must fall within the school year and each semester should begin the day after the previous semester.
                  </Text>
                )}
                <Flex color={'ff.blue'} mt="1em">
                  <ButtonGroup spacing={'5'} margin={'auto'} zIndex={1}>
                    <FfFormikButton type={FfButtonTypes.Button} variant={'solid'} onClick={onCancel}>
                      {userInfo?.role === 'Staff' ? 'Cancel' : 'Close'}
                    </FfFormikButton>
                    {userInfo?.role === 'Staff' && (
                      <FfFormikButton type={FfButtonTypes.Submit} variant={'cta'}>
                        Save
                      </FfFormikButton>
                    )}
                  </ButtonGroup>
                </Flex>
              </Form>
            );
          }}
        </FfFormikForm>
      )}
    </>
  );
};

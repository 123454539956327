import { Stack } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import { studentAttendanceAtom } from '../State/StudentAttendanceAtom';
import { AttendanceBoxByStudentPopup } from '../AttendanceBox/AttendanceBoxPopup';
import { memoizedDaysInMonth, memoizedIsWeekend } from '../Utility';
import { AttendanceMonthData, AttendanceRecordRowProps } from './AttendanceRecordRowProps';
import { schoolMonthAttendanceAtom } from '../State/AttendanceByStudentAtoms';

const AttendanceRow: React.FunctionComponent<
  AttendanceRecordRowProps & {
    attendanceRecord: { [key: string]: AttendanceMonthData };
  }
> = ({ groupKey, showWeekDays, attendanceRecord }) => {
  const singleRow = attendanceRecord[groupKey];

  // based on startOfMonth, create an array of days in the month
  const daysArray = memoizedDaysInMonth(singleRow.startOfMonth);
  const attendanceArray = daysArray.map(({ date, dayOfMonth }) => {
    const attendance = singleRow.attendance.find((a) => a.date === date);

    return attendance
      ? { ...attendance, date, dayOfMonth, showWeekDays }
      : { date, dayOfMonth, showWeekDays, enrolled: !memoizedIsWeekend(date) };
    // , onClick } : { date, dayOfMonth, onClick };
  });

  return (
    <div>
      <Stack key={groupKey} direction="row" fontSize="12px" alignItems={'center'} position="relative">
        {attendanceArray.map((row, i) => (
          <AttendanceBoxByStudentPopup key={i} {...row} />
        ))}
      </Stack>
    </div>
  );
};

export const AttendanceByStudentRow: React.FunctionComponent<AttendanceRecordRowProps> = ({ groupKey }) => {
  const attendanceRecord = useAtomValue(schoolMonthAttendanceAtom);
  return <AttendanceRow groupKey={groupKey} showWeekDays={false} attendanceRecord={attendanceRecord} />;
};

export const AttendanceByMonthRow: React.FunctionComponent<AttendanceRecordRowProps> = ({ groupKey }) => {
  const attendanceRecord = useAtomValue(studentAttendanceAtom);
  return <AttendanceRow groupKey={groupKey} showWeekDays={true} attendanceRecord={attendanceRecord} />;
};

import { Container } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { useSearchParams } from 'react-router-dom';
import { EditStudentForm } from '../../components/EditStudent/EditStudentForm';
import { FfDivider } from '../../components/FfDivider';
import { StudentBanner } from '../../components/StudentBanner';
import { StudentServiceAtoms } from '../../services/Student';
import { EnrollmentDialog } from '../../components/Enrollment/EditEnrollmentDialog';
import { enrollmentDialogState } from '../../services/EnrollmentAtoms';

export const EditStudentScreen: React.FunctionComponent = () => {
  const [student] = useAtom(StudentServiceAtoms.getCurrentStudent);
  const [searchParams] = useSearchParams();
  const isNewUser = searchParams.get('new') === 'true';
  const [dialogState, setDialogState] = useAtom(enrollmentDialogState);
  const [didOpenDialog, setDidOpenDialog] = useState(false);

  useEffect(() => {
    if (!student || didOpenDialog) return;
    setDialogState({
      mode: 'activate',
      updateIds: [{ studentId: student.id, schoolId: student?.schoolId }],
      isLastEnrollment: true,
    });
    setDidOpenDialog(true);
  }, [isNewUser, student]);

  return (
    <Container maxWidth="100%">
      {student && (
        <>
          <StudentBanner student={student} showEnrollmentLink={true} />
          <FfDivider />
          <EditStudentForm student={student} />
          <EnrollmentDialog />
        </>
      )}
    </Container>
  );
};

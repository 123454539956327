import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Divider, MenuItem, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export interface StaffMenuProps {
  schoolId?: number;
}

export const StaffMenu: React.FunctionComponent<StaffMenuProps> = ({ schoolId }: StaffMenuProps) => {
  const navigate = useNavigate();
  const navClick = (to: string) => () => {
    navigate(to);
  };
  return (
    <>
      {schoolId !== undefined && (
        <>
          <MenuItem color={'ff.blue'} onClick={navClick(`/schools/${schoolId}/dashboard`)}>
            Dashboard
          </MenuItem>
          <MenuItem color={'ff.blue'} onClick={navClick(`/schools/${schoolId}/tutors`)} isDisabled={!schoolId}>
            All Tutors
          </MenuItem>
          <MenuItem color={'ff.blue'} onClick={navClick(`/schools/${schoolId}/tutors/assign`)} isDisabled={!schoolId}>
            Assign Tutors
          </MenuItem>
          <MenuItem color={'ff.blue'} onClick={navClick(`/schools/${schoolId}/students`)} isDisabled={!schoolId}>
            All Students
          </MenuItem>
          <MenuItem color={'ff.blue'} onClick={navClick(`/schools/${schoolId}/students/activate`)} isDisabled={!schoolId}>
            Activate Students
          </MenuItem>
          <MenuItem color={'ff.blue'} onClick={navClick(`/schools/${schoolId}/lesson-plans`)} isDisabled={!schoolId}>
            All Lesson Plans
          </MenuItem>
          <MenuItem color={'ff.blue'} onClick={navClick(`/schools/${schoolId}/observations`)}>
            All Observations
          </MenuItem>
          <MenuItem color={'ff.blue'} onClick={navClick(`/schools/${schoolId}/family-events`)}>
            All Events
          </MenuItem>
          <MenuItem color={'ff.blue'} onClick={navClick(`/schools/${schoolId}/family-interactions`)}>
            All Family Interactions
          </MenuItem>
          <MenuItem color={'ff.blue'} onClick={navClick(`/schools/${schoolId}/attendance`)}>
            Monthly Attendance
          </MenuItem>
        </>
      )}
      {schoolId === undefined && (
        <MenuItem color={'ff.blue'} onClick={navClick('/schools')}>
          <Text as="i" color="blue">
            Select a school
          </Text>
          <Text as="i"> to view tutors, students and lesson plans</Text>
        </MenuItem>
      )}

      <Divider borderColor={'ff.blue'} />
      <MenuItem color={'ff.blue'} onClick={navClick('/users/activate')}>
        Activate Users
      </MenuItem>
      <MenuItem color={'ff.blue'} onClick={navClick('/districts')}>
        School Year/Semesters
      </MenuItem>
      <MenuItem color={'ff.blue'} onClick={navClick('/users')}>
        Staff Directory
      </MenuItem>

      <MenuItem color={'ff.blue'} onClick={navClick('/forms')}>
        Forms
      </MenuItem>
      <Divider borderColor={'ff.blue'} />
      <MenuItem color={'ff.blue'} onClick={navClick('/logout')}>
        <ExternalLinkIcon /> Logout
      </MenuItem>
    </>
  );
};

import { Container } from '@chakra-ui/react';
import React from 'react';
import { EditStudentForm } from '../../components/EditStudent/EditStudentForm';
import { FfDivider } from '../../components/FfDivider';
import { StudentBanner } from '../../components/StudentBanner';

export const CreateStudentScreen: React.FunctionComponent = () => (
  <Container maxWidth="100%">
    <StudentBanner student={null} showEnrollmentLink={true} />
    <FfDivider />
    <EditStudentForm student={null} />
  </Container>
);

import { Box, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, Stack, useDisclosure } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import React from 'react';
import _ from 'lodash';
import { attendanceEditAtom, dayOfMonthEditAtom, updateScheduleAllStudents } from '../State/AttendanceAtoms';
import { AttendanceEdit } from '../AttendanceBox/AttendanceEditBox';
import {
  AttendanceCellData,
  AttendanceInfo,
  getMonthAttendanceStatusIconKeys,
  memoizedDayOfMonth,
  memoizedShortDay,
  memoizedToday,
} from '../Utility';
import '../attendance-box.css';

export interface DisplayBoxProps extends AttendanceCellData {
  date: string;
  onClick?: (day: Partial<AttendanceInfo>) => void;
}

export const AttendanceTableHeaderDaysDisplay: React.FunctionComponent<DisplayBoxProps> = ({ date, onClick }: DisplayBoxProps) => {
  const weekDay = memoizedShortDay(date);
  const dayOfMonth = memoizedDayOfMonth(date);

  const isToday = memoizedToday() === date;

  return (
    <Stack key={dayOfMonth} direction={'column'} onClick={() => (onClick ?? (() => {}))({ date, dayOfMonth })}>
      <Box w="20px" h="20px">
        <div className={isToday ? 'today' : ''}>{weekDay}</div>
      </Box>
      <Box w="20px" h="20px">
        <div className={isToday ? 'today' : ''}>{dayOfMonth}</div>
      </Box>
    </Stack>
  );
};

// this is almost identical to AttendanceBoxPopup
export const AttendanceByStudentHeaderPopup: React.FunctionComponent<AttendanceCellData & { children: React.ReactElement }> = React.memo(
  (props) => {
    const [edit] = useAtom(attendanceEditAtom);
    const [dateEdit, setDateEdit] = useAtom(dayOfMonthEditAtom);
    const updateAttendance = useUpdateAtom(updateScheduleAllStudents);
    const isOpen = dateEdit === props.date;

    const { onOpen, onClose } = useDisclosure();
    const attendanceStatuses = getMonthAttendanceStatusIconKeys(props);
    const editable = props.enrolled && edit && attendanceStatuses.length > 0;

    // open popup
    const onClick = (dProps: Partial<AttendanceInfo>) => {
      if (!props.enrolled) return;
      setDateEdit(dProps.date);
    };

    // close popup without updating attendance
    const onCloseLocal = () => {
      setDateEdit(undefined);
      onClose();
    };

    // update attendance
    const statusChangeLocal = (att: Partial<AttendanceInfo>) => {
      updateAttendance(att);
      onCloseLocal();
    };

    // enlarged-box or normal-box
    const className = '';

    const newProps = { ...props, onClick, className, attendanceStatuses };

    // EDIT MODE
    return editable ? (
      <Popover placement="bottom" isOpen={isOpen} onOpen={onOpen} onClose={onCloseLocal}>
        <PopoverTrigger>
          <Box>
            <AttendanceTableHeaderDaysDisplay {...newProps} />
          </Box>
        </PopoverTrigger>
        <PopoverContent w="200px" color="white" bg="white">
          <PopoverArrow bg="white" border="1px solid" />
          <PopoverBody textAlign="center">
            <AttendanceEdit {...newProps} statusChange={statusChangeLocal}>
              {props.children}
            </AttendanceEdit>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    ) : (
      // DISPLAY MODE
      <div>
        <AttendanceTableHeaderDaysDisplay {...newProps} />
      </div>
    );
  },
  _.isEqual,
);

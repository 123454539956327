/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Box, Flex, Heading, Spacer, Text } from '@chakra-ui/react';
import { FamilyInteractionAttemptDto, FamilyInteractionTypes, PutFamilyInteractionAttemptDto } from '@edanalytics/ff_be_se';
import { useAtom, useAtomValue } from 'jotai';
import { isNumber } from 'lodash';
import { FfFormikTextarea } from '../Formik/FfFormikTextarea';
import { LessonPlanService, LessonPlanServiceAtoms } from '../../services/LessonPlan/LessonPlanService';
import { calculateAttendanceScore } from '../Utils';
import { atomApiWithNavAndUpdate } from '../../utils/async-atom';
import { studentIdAtom } from './GenericInteractionForm';
import { getStudentAttendance, studentAttendanceAtom } from '../AttendanceRecord/State/StudentAttendanceAtom';
import { AttendanceCellData } from '../AttendanceRecord/Utility';

export const studentLessonPlanAtom = atomApiWithNavAndUpdate(
  (get, nav) => {
    const schoolId = nav?.schoolId;
    const studentId = nav?.studentId;
    if (!schoolId || !studentId) return Promise.resolve(undefined);

    return get(LessonPlanService).getAllSavedForStudent(schoolId, studentId);
  },
  (get, set, newValue: number, nav) => {
    if (isNumber(newValue)) {
      set(studentIdAtom, newValue);
      return Promise.resolve(undefined);
    }
    return Promise.resolve(newValue);
  },
);

export const HomeVisitForm: React.FunctionComponent<{
  isLoading?: boolean;
  isSubmitted?: boolean;
  attempt: FamilyInteractionAttemptDto | PutFamilyInteractionAttemptDto;
  index: number;
}> = ({ attempt, index }) => {
  const [, lessonPlans] = useAtomValue(getStudentAttendance);
  const allLessonPlans = lessonPlans.map(({ startDateTime, ...l }) => ({ date: startDateTime, dayOfMonth: 0, ...l } as AttendanceCellData));
  const attnData = lessonPlans ? calculateAttendanceScore(allLessonPlans) : undefined;
  const attnLabel =
    attnData !== undefined
      ? `${attnData?.present} Completed / ${attnData?.total} Scheduled (${attnData?.rate} Attendance Rate)`
      : `0 Completed / 0 Scheduled (N/A Attendance Rate)`;

  return (
    <>
      <Box hidden={attempt.interactionType !== FamilyInteractionTypes.HomeVisit}>
        <Flex mt="50px" borderTop="2px">
          <Box flex={1}>
            <Heading mt="20px" mb="20px">
              Information Shared
            </Heading>

            <Flex pt="15px">
              <Box w="48%">
                <Text fontWeight="bold">Family Feedback and Suggestions</Text>
                <Text mt="10px">
                  Example suggestions: Change in living arrangements, change of school, and/or student's views of Future Forward.
                </Text>

                <FfFormikTextarea
                  id={`attempts.${index}.json.feedback`}
                  noOfLines={10}
                  label=""
                  bgColor="white"
                  borderColor="ff.pink !important"
                  border="2px"
                  h="150px"
                  onChange={(value) => {
                    attempt.json.feedback = value;
                  }}
                />
              </Box>
              <Spacer></Spacer>
              <Box w="48%">
                <Text fontWeight="bold">Student's Strengths</Text>
                <Text mt="10px">Suggestions: Reading levels, areas the student has mastered, improved attendance.</Text>
                <FfFormikTextarea
                  id={`attempts.${index}.json.strengths`}
                  noOfLines={10}
                  label=""
                  bgColor="white"
                  borderColor="ff.pink !important"
                  border="2px"
                  h="150px"
                  onChange={(value) => {
                    attempt.json.strengths = value;
                  }}
                />
              </Box>
            </Flex>
            <Flex pt="15px">
              <Box w="48%">
                <Text fontWeight="bold">Areas for Improvement</Text>
                <Text>Suggestions: Attendance, level of engagement, vision.</Text>
                <FfFormikTextarea
                  id={`attempts.${index}.json.improvements`}
                  noOfLines={10}
                  label=""
                  bgColor="white"
                  borderColor="ff.pink !important"
                  border="2px"
                  h="150px"
                  onChange={(value) => {
                    attempt.json.improvements = value;
                  }}
                />
              </Box>
              <Spacer></Spacer>
              <Box w="48%">
                <Text fontWeight="bold">School to Home Communication</Text>
                <Text>Suggestions: Tutor communication, teacher feedback/communication.</Text>
                <FfFormikTextarea
                  id={`attempts.${index}.json.communication`}
                  noOfLines={10}
                  label=""
                  bgColor="white"
                  borderColor="ff.pink !important"
                  border="2px"
                  h="150px"
                  onChange={(value) => {
                    attempt.json.communication = value;
                  }}
                />
              </Box>
            </Flex>
          </Box>
        </Flex>
        <Box mt="50px">
          <Heading mb="10px">Number of completed tutoring sessions this semester so far</Heading>
          <Text>{attnLabel}</Text>
        </Box>
      </Box>
    </>
  );
};

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Container,
  Flex,
  Heading,
  Button,
  Link,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { RESET } from 'jotai/utils';
import { DistrictDto, IDistrictSemester, DistrictSemesterDto, FFRoles, SchoolDto } from '@edanalytics/ff_be_se';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import { DateTime as DT } from 'luxon';
import _, { forEach, isEmpty } from 'lodash';
import { ColumnDef } from '@tanstack/react-table';
import { useAtom, useAtomValue } from 'jotai';
import { DistrictServiceAtoms, semesterDialogState } from '../../services/DistrictAtoms';
import { FfTable } from '../../components/FfTable';
import { userInfoAtom } from '../../ffApi';
import { DebugCard } from '../../components/DebugCard';
import { FfAlertDialog } from '../../components/FfAlertDialog';
import { DistrictSemesterForm } from './DistrictSemesterForm';
import { SchoolYearFormat, ShortFormatDate } from '../../components/Utils';

const DistrictSemesterPrompt: React.FunctionComponent = () => {
  const [dialogState, setDialogState] = useAtom(semesterDialogState);
  const onClose = () => {
    setDialogState(RESET);
  };

  const onReFresh = (data: IDistrictSemester[]) => {
    console.log('got new data', data);
    // console.log(DT.fromJSDate(new Date(d.startDate)).toFormat('MM/dd/yyyy'));
    // console.log(DT.fromISO(d.startDate.toString());
    // console.log(d.startDate);
    // _.forEach(data,(d)=>{
    //     d.endDate = d.endDate.toISOString().split('T')[0];
    // });
    dialogState.district.semesters = dialogState.district.semesters.concat(data);
    setDialogState(dialogState);
  };
  // useEffect(() => {
  // }, [dialogState]);

  return (
    <Modal isOpen={dialogState.show} onClose={onClose} size={'xl'}>
      <ModalOverlay />
      <ModalContent backgroundColor={'ff.blue'}>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <DistrictSemesterForm onReFresh={onReFresh} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

interface DistrictSemesterAccordionItemProps {
  index: number;
  district: DistrictDto;
}

const DistrictSemesterAccordionItem: React.FunctionComponent<DistrictSemesterAccordionItemProps> = ({ district, index }) => {
  const userInfo = useAtomValue(userInfoAtom);
  // const hasAccess = (s: SchoolDto) => userInfo?.role === 'Staff' ;
  const [dialogState, setDialogState] = useAtom(semesterDialogState);

  const semestersSorted = _.sortBy(district.semesters, (s: IDistrictSemester) => s.startDate);
  const groupedByYear = _.groupBy(semestersSorted, 'startSchoolYear');
  let groupedBySchoolYr: any = [];
  const keys = Object.keys(groupedByYear);

  for (let i = 0; i < keys.length; i += 1) {
    groupedBySchoolYr.push({
      fall: groupedByYear[keys[i]][0],
      spring: groupedByYear[keys[i]][1],
      summer: groupedByYear[keys[i]][2],
      year: keys[i],
    });
  }
  groupedBySchoolYr = groupedBySchoolYr.reverse();

  const AddSchoolYear = () => {
    setDialogState({ show: true, district, mode: 'add' });
  };

  const editSchoolYear = (schoolyear: any) => {
    setDialogState({ show: true, district, currentSchoolYr: schoolyear, mode: 'edit' });
  };

  const columns: ColumnDef<any>[] = [
    {
      id: 'Year',
      accessorFn: (o) => o,
      cell: (info: any) => SchoolYearFormat(info.getValue().year),
      header: () => 'School Year',
      enableSorting: false,
    },
    {
      id: 'FallSemester',
      accessorFn: (o) => o,
      cell: (info: any) => {
        const d = info.getValue().fall as DistrictSemesterDto;
        return `${ShortFormatDate(d.startDate)} - ${ShortFormatDate(d.endDate)}`;
      },
      header: () => 'Fall Semester',
      enableSorting: false,
    },
    {
      id: 'SpringSemester',
      accessorFn: (o) => o,
      cell: (info: any) => {
        const d = info.getValue().spring as DistrictSemesterDto;
        return `${ShortFormatDate(d.startDate)} - ${ShortFormatDate(d.endDate)}`;
      },
      header: () => 'Spring Semester',
      enableSorting: false,
    },
    {
      id: 'SummerSemester',
      accessorFn: (o) => o,
      cell: (info: any) => {
        const d = info.getValue().summer as DistrictSemesterDto;
        return `${ShortFormatDate(d.startDate)} - ${ShortFormatDate(d.endDate)}`;
      },
      header: () => 'Summer Semester',
      enableSorting: false,
    },
    {
      id: 'edit',
      accessorFn: (o) => o,
      cell: (info: any) =>
        userInfo.roleId !== FFRoles.Tutor && (
          <Link onClick={() => editSchoolYear(info.getValue().year)}>{userInfo?.role === 'Staff' ? 'Edit' : 'View'}</Link>
        ),
      header: () => '',
      enableSorting: false,
    },
  ];

  return (
    <AccordionItem key={index}>
      {({ isExpanded }) => (
        <>
          <Heading>
            <AccordionButton>
              <Box flex={0} marginRight="1em">
                {isExpanded ? <MinusIcon fontSize="12px" /> : <AddIcon fontSize="12px" />}
              </Box>
              <Heading flex={1} textAlign="left" marginRight="1em" size="md" fontWeight="500">
                {district.name}
              </Heading>
            </AccordionButton>
          </Heading>
          <AccordionPanel>
            <FfTable columns={columns} data={groupedBySchoolYr} enablePagination={false} />
            <DistrictSemesterPrompt />

            {userInfo?.role === 'Staff' && <Button onClick={AddSchoolYear}>Add School Year </Button>}

            {/* <DebugCard data={[{ name: 'tableData', data: groupedBySchoolYr }]} /> */}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

export const SchoolDistrictsScreen: React.FunctionComponent = () => {
  const userInfo = useAtomValue(userInfoAtom);
  const [districts] = useAtom(DistrictServiceAtoms.getAllDistricts);
  const hasAccess = (district: DistrictDto) => userInfo?.role === 'Staff' || userInfo?.districtIds?.includes(district.id);

  const districtsSorted = _.sortBy(districts.filter(hasAccess), (s: DistrictDto) => s.name.toLowerCase());

  const addDistrictHandler = (indexes: number[]) => {
    // if we need to reset anything at top level
    //     const district = districtsSorted[index];
  };

  return (
    <Container maxWidth="100%">
      <Heading size="lg">School Year/Semesters by District</Heading>
      {/* {districtsSorted && (districtsSorted.length)} */}

      <Accordion allowMultiple={true} defaultIndex={[]} onChange={addDistrictHandler}>
        {districtsSorted.map((district: DistrictDto, index: number) => (
          <DistrictSemesterAccordionItem key={index} district={district} index={index} />
        ))}
      </Accordion>
    </Container>
  );
};

import { Box, Stack } from '@chakra-ui/react';
import { atom, useAtomValue } from 'jotai';
import _ from 'lodash';
import { AttendanceStatusTypes, LessonStatusTypes } from '@edanalytics/ff_be_se';
import { AttendanceCellData, hasScheduledActivity } from './Utility';
import { keyOrder } from './AttendanceTableByStudent';
import { schoolMonthAttendanceAtom } from './State/AttendanceByStudentAtoms';

//  Calculate the summary of attendance for a group
const summarizeAtt = (attendance: AttendanceCellData[]) =>
  attendance.reduce((acc, curr) => {
    if (curr.lessonStatus === LessonStatusTypes.Submitted && curr.attendanceStatus === AttendanceStatusTypes.Attended) {
      acc[0] = acc[0] ? acc[0] + 1 : 1;
    } else if (curr.attendanceStatus && hasScheduledActivity(curr)) {
      acc[curr.attendanceStatus] = acc[curr.attendanceStatus] ? acc[curr.attendanceStatus] + 1 : 1;
    }
    return acc;
  }, {} as { [key: string]: number });

// State for the summary of attendance for a group
const attendanceSummaryCellAtom = atom((get) => _.mapValues(get(schoolMonthAttendanceAtom), (row) => summarizeAtt(row.attendance)));

const attendanceTotalsAtom = atom((get) => {
  const summaryCell = get(attendanceSummaryCellAtom);
  return _.reduce(
    summaryCell,
    (acc, curr) => {
      _.forEach(curr, (value, key) => {
        acc[key] = acc[key] ? acc[key] + value : value;
      });
      return acc;
    },
    {} as { [key: string]: number },
  );
});

// Component to display the summary of attendance for a group
interface AttendanceRecordSummaryCellProps {
  groupKey: string;
}

export const AttendanceRecordSummaryCell: React.FunctionComponent<AttendanceRecordSummaryCellProps> = ({ groupKey }) => {
  const attendanceRecord = useAtomValue(attendanceSummaryCellAtom);
  const attendanceTotals = useAtomValue(attendanceTotalsAtom);
  const attendance = groupKey !== 'totals' ? attendanceRecord[groupKey] : attendanceTotals;

  return (
    <Stack key={groupKey} direction="row" marginBottom="5px" fontSize="12px">
      {keyOrder.map((status, i) => (
        <Box key={i} w="20px" textAlign="center" fontSize="small">
          {attendance[status] || 0}
        </Box>
      ))}
    </Stack>
  );
};

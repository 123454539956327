import React, { useState } from 'react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getFacetedMinMaxValues,
  getFacetedUniqueValues,
  useReactTable,
  SortingState,
  FilterFn,
  ColumnFiltersState,
  getFacetedRowModel,
  Column,
} from '@tanstack/react-table';
import { Table, TableCaption, TableContainer, Tbody, Td, Th, Thead, Tr, Box, Tfoot, HStack, Heading, Select } from '@chakra-ui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';

import { RankingInfo } from '@tanstack/match-sorter-utils';
import { PAGINATION_ROW_OPTIONS } from '../../config/constants';
import { getPaginationControlsFn } from './getPaginationControlsFn';
import {
  fuzzyFilter,
  roleNodeFilter,
  schoolsNodeFilter,
  fullNameFilter,
  studentNodeFilter,
  byUserNodeFilter,
  dateFilter,
  observerFilter,
  fuzzySort,
  roleSort,
  studentSort,
  byUserSort,
  userSort,
  columnIdValueSort,
} from './Filters';
import { Filter } from './Filter';
import { IndeterminateCheckbox } from './IndeterminateCheckbox';

export interface IFfTableProps {
  title?: string;
  caption?: string;
  variant?: string;
  columns: ColumnDef<any>[];
  data?: any;
  enableRowSelection?: boolean;
  enablePagination?: boolean;
  // row selected cb is an object with row index as the key, row original data as the value
  rowSelectedCb?: (arg0: RowSelectedCb) => void;
  // if you're enabling pagination then tracking selectedRowIndexes is required, so that we can clear row selection on your upstream action
  selectedRowIndexes?: number[];

  /// Omit header and footer tags
  showHeaders?: boolean;
  onlyTopHeader?: boolean;
  removeMarginBottomBorder?: boolean;
  extraHeading?: React.ReactNode;
  extraFooter?: React.FunctionComponent<any>;
  showHorizontalGridLines?: boolean;
}

export interface RowSelectedCb {
  data: Record<number, any>;
}

declare module '@tanstack/table-core' {
  interface FilterFns {
    email: FilterFn<unknown>;
    fuzzy: FilterFn<unknown>;
    roleNode: FilterFn<unknown>;
    schoolsNode: FilterFn<unknown>;
    student: FilterFn<unknown>;
    studentNode: FilterFn<unknown>;
    tutor: FilterFn<unknown>;
    tutorNode: FilterFn<unknown>;
    user: FilterFn<unknown>;
    date: FilterFn<unknown>;
    observer: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

export const FfTable: React.FunctionComponent<IFfTableProps> = ({
  title,
  caption,
  variant,
  columns,
  data,
  enableRowSelection,
  enablePagination = true,
  rowSelectedCb,
  selectedRowIndexes,
  showHeaders,
  onlyTopHeader,
  removeMarginBottomBorder = false,
  extraHeading,
  extraFooter,
  showHorizontalGridLines = false,
}) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const showHeader = (showHeaders ?? true) === true || (onlyTopHeader ?? false) === true;
  const showFooter = (showHeaders ?? true) === true && (onlyTopHeader ?? false) === false;

  if (enableRowSelection) {
    columns.unshift({
      id: 'select',
      header: ({ table }) => {
        const selectedCount = table.getSelectedRowModel().rows.length;

        return (
          <Box display="inline-block">
            <HStack align="center">
              <IndeterminateCheckbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                }}
              />
              <Box visibility={selectedCount ? 'visible' : 'hidden'}>({selectedCount})</Box>
            </HStack>
          </Box>
        );
      },
      cell: ({ row }) => (
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      ),
    });
  }

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      email: fuzzyFilter,
      fuzzy: fuzzyFilter,
      roleNode: roleNodeFilter,
      schoolsNode: schoolsNodeFilter,
      student: fullNameFilter,
      studentNode: studentNodeFilter,
      tutor: fullNameFilter,
      tutorNode: byUserNodeFilter,
      user: fullNameFilter,
      date: dateFilter,
      observer: observerFilter,
    },
    sortingFns: {
      fuzzy: fuzzySort,
      role: roleSort,
      student: studentSort,
      tutor: byUserSort,
      user: userSort,
      columnIdValue: columnIdValueSort,
    },
    initialState: {
      pagination: {
        pageSize: PAGINATION_ROW_OPTIONS ? PAGINATION_ROW_OPTIONS[0] : 10,
      },
    },
    state: {
      sorting,
      columnFilters,
      globalFilter,
      rowSelection,
    },
    manualPagination: !enablePagination,
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onSortingChange: setSorting,
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
    autoResetPageIndex: false,
    enableColumnResizing: true,
  });

  const getFilterLabel = (column: Column<any, unknown>) => {
    switch (column.columnDef.filterFn) {
      case 'email':
        return 'Filter By Email';
      case 'roleNode':
        return 'Filter By Role';
      case 'schoolsNode':
        return 'Filter By School';
      case 'student':
      case 'studentNode':
        return 'Filter By Student';
      case 'tutor':
      case 'tutorNode':
        return 'Filter By Tutor';
      case 'user':
        return 'Filter By User';
      case 'date':
        return 'Filter By Date';
      case 'observer':
        return 'Filter By Observer';
      default:
        return '';
    }
  };

  const getPaginationControls = getPaginationControlsFn(table, rowSelection, extraFooter);

  React.useEffect(() => {
    if (enableRowSelection && rowSelectedCb) {
      // build hash of rowIds values
      const hash: Record<number, any> = {};
      table.getSelectedRowModel().flatRows.forEach((r) => {
        hash[r.index] = r.original;
      });
      // Only fire callback if rowSelection (state) and selectedRowIndexes (prop) counts
      // don't match, to prevent infinite loop
      if (Object.keys(hash).length !== selectedRowIndexes?.length) {
        rowSelectedCb({ data: hash });
      }
    }
  }, [rowSelection]);

  React.useEffect(() => {
    if (selectedRowIndexes?.length === 0) {
      // we don't have any selected row indexes, clear row selection
      setRowSelection({});
    }
  }, [selectedRowIndexes]);

  React.useEffect(() => {
    table.setPageIndex(0);
  }, [table.getFilteredRowModel().rows.length]);

  //
  const bottomBorder = showHorizontalGridLines ? 'rowBorder ' : '';
  return (
    <TableContainer
      title={title}
      borderBottomWidth={removeMarginBottomBorder ? `0px` : `2px`}
      borderColor={'ff.blue'}
      mb={removeMarginBottomBorder ? `0em` : `1.5em`}
    >
      <HStack spacing="1em">
        {table.getHeaderGroups().map((headerGroup) =>
          headerGroup.headers.map(
            (header) =>
              header.column.getCanFilter() &&
              header.column.columnDef.filterFn !== 'auto' && (
                <Box>
                  <Heading size="xs" mb="0.3em">
                    {getFilterLabel(header.column)}
                  </Heading>
                  <Filter column={header.column} table={table} />
                </Box>
              ),
          ),
        )}
        {extraHeading}
      </HStack>
      {!showHeader && <HStack borderColor={'ff.blue'} borderX={0} borderWidth={'1px'} />}
      <Table variant={variant} colorScheme="#014363" size="sm" mt="1.5em">
        <TableCaption p={0}>{caption}</TableCaption>
        {showHeader && (
          <Thead borderColor={'ff.blue'} height="2.3em" borderX={0} borderWidth={'2px'}>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id} pt="10px">
                {headerGroup.headers.map((header) => (
                  <Th
                    key={header.id}
                    colSpan={header.colSpan}
                    py="10px"
                    style={{
                      width: header.column.getSize(),
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <Box
                        {...{
                          cursor: header.column.getCanSort() ? 'pointer' : undefined,
                          textDecoration: header.column.getCanSort() ? 'underline' : undefined,
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.getCanSort() &&
                          ({
                            asc: <ChevronUpIcon fontSize="1.5em" />,
                            desc: <ChevronDownIcon fontSize="1.5em" />,
                          }[header.column.getIsSorted() as string] ?? <ChevronDownIcon color="transparent" fontSize="1.5em" />)}
                      </Box>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
        )}
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id} className={bottomBorder + row.original.rowHighlightClass || undefined}>
              {row.getVisibleCells().map((cell) => (
                <Td
                  key={cell.id}
                  borderWidth={0}
                  style={{
                    width: cell.column.getSize(),
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
        {showFooter && (
          <Tfoot borderColor={'ff.blue'} height="2.3em" borderX={0} borderTopWidth={'2px'}>
            {table.getFooterGroups().map((footerGroup) => (
              <Tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <Th key={header.id}>{header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}</Th>
                ))}
              </Tr>
            ))}
          </Tfoot>
        )}
      </Table>
      {!showFooter && <HStack borderColor={'ff.blue'} borderX={0} borderWidth={'1px'} />}
      {enablePagination ? getPaginationControls() : extraFooter && extraFooter({})}
    </TableContainer>
  );
};

/* eslint-disable @typescript-eslint/naming-convention */

import { DistrictDto, DistrictSemesterDto } from '@edanalytics/ff_be_se';
import { RESET, atomWithReset } from 'jotai/utils';
import { atom, Getter } from 'jotai';
import { storedDistrictsAtom } from '../atoms/districtAtom';
import { persistNavAtom } from '../atoms/navAtom';
import { apiClientAtom } from '../ffApi';

const districtServiceFactory = (get: Getter) => ({
  getAllDistricts: async (): Promise<DistrictDto[]> => {
    const response = await get(apiClientAtom).get(`/districts/`);
    return response.data.results as DistrictDto[];
  },
  putDistrictSemesters: async (id: number, terms: DistrictSemesterDto[]): Promise<DistrictSemesterDto[]> => {
    const response = await get(apiClientAtom).put(`/districts/${id}/semesters`, terms);
    return response.data.results as DistrictSemesterDto[];
  },
  postDistrictSemesters: async (id: number, terms: DistrictSemesterDto[]): Promise<DistrictSemesterDto[]> => {
    const response = await get(apiClientAtom).post(`/districts/${id}/semesters`, terms);
    return response.data.results as DistrictSemesterDto[];
  },
});

const DistrictService = atom(districtServiceFactory);
export { DistrictService };

export const DistrictServiceAtoms = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getAllDistricts: storedDistrictsAtom,
  getCurrentDistrict: atom((get) => {
    const nav = get(persistNavAtom);
    if (!nav.districtId) return undefined;
    const currentDistricts = get(storedDistrictsAtom);
    return currentDistricts.find((d) => d.id === nav.districtId);
  }),

  /*
  getDistrictMonths
  get earliest start date
  get latest end date
  get all months between
  sort by date
  returns lookup object with following shape: { <districtId>: [ '<earliest start date>', '09-xx-xxxx', '10-xx-xxxx', '...', '<latest end date>' ]}
  */
};

export class SchoolYearSemester {
  /* eslint-disable @typescript-eslint/lines-between-class-members */

  schoolYear: number;
  fallStart?: Date;
  fallEnd?: Date;
  springStart?: Date;
  springEnd?: Date;
  summerStart?: Date;
  summerEnd?: Date;
  schoolYearLabel?: any;
}

interface ISemesterDialogState {
  show: boolean;
  district: DistrictDto;
  currentSchoolYr?: any;
  mode: 'add' | 'edit';
  // updateIds: ({ studentId: number; enrollmentId?: number; schoolId?: number } | { orStudentId: string; schoolId?: number })[];
  // studentName?: string;
  // entryDate?: Date;
  // exitDate?: Date;
  // isLastEnrollment: boolean;
}

const initialState = {
  show: false,
  // updateIds: [],
  // isLastEnrollment: false,
} as ISemesterDialogState;

export const semesterDialogState = atomWithReset<ISemesterDialogState>(initialState);

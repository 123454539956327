import { EditIcon } from '@chakra-ui/icons';
import { Avatar, Box, Flex, Heading, Stack } from '@chakra-ui/react';
import { StudentDto } from '@edanalytics/ff_be_se';
import { StudentSlug } from './StudentSlug';
import Spaceguy from '../assets/images/Spaceguy.svg';
import { FfRouterLink as Link } from './FfRouterLink';
import { StudentEnrollmentLink, studentStatusColor } from './Utils';

export interface IStudentBannerProps {
  student: StudentDto | null; // if null we're creating a new student
  isEditLinkEnabled?: boolean;
  showEnrollmentLink?: boolean;
}

export const StudentBanner: React.FunctionComponent<IStudentBannerProps> = ({ student, isEditLinkEnabled, showEnrollmentLink }) => (
  <Flex gap={5} align="center" fontSize="12px">
    <Box flex={0} textAlign="center" alignItems="center">
      <Avatar bg={'ff.blue'} size="lg" src={Spaceguy} mb=".2em" />
      <Heading color={'ff.blue'} style={{ fontSize: '.9em' }}>
        STUDENT
      </Heading>
    </Box>
    <Box>
      <Stack direction="row" spacing={5}>
        <Heading mb=".25em" color={student ? studentStatusColor(student) : 'ff.blue'}>
          {student ? student.fullName : 'Add New Student'}
        </Heading>
        {isEditLinkEnabled && student != null && (
          <Link to={`/schools/${student.schoolId}/students/${student.id}/edit`}>
            <EditIcon fontSize={'1.3em'} />
          </Link>
        )}
      </Stack>
      {student && <StudentSlug student={student} />}
    </Box>

    {student && (
      <Box textAlign="right" flex={1}>
        {showEnrollmentLink ? (
          <StudentEnrollmentLink student={student} school={student.school} />
        ) : (
          <Link to={`/schools/${student.schoolId}/students/${student.id}`}>Back to Student Summary</Link>
        )}
      </Box>
    )}
  </Flex>
);

import { Box, Container, Flex, Heading } from '@chakra-ui/react';
import React from 'react';
import { FfDivider } from '../components/FfDivider';
import { AttendanceTableByStudent } from '../components/AttendanceRecord/AttendanceTableByStudent';
import { AttendanceMonthSelect } from '../components/AttendanceRecord/AttendanceMonthSelect/AttendanceMonthSelect';

export const BatchAttendanceScreen: React.FunctionComponent = () => {
  console.log('SitesScreenContent');

  return (
    <Container maxWidth="100%">
      <Flex mb="1em">
        <Box flex={1}>
          <Heading>Monthly Attendance</Heading>
        </Box>
      </Flex>

      <FfDivider />
      <Box maxW="300px">
        <AttendanceMonthSelect />
      </Box>
      <AttendanceTableByStudent />
    </Container>
  );
};

import { Link, Stack } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import _ from 'lodash';
import React, { FunctionComponent } from 'react';
import { SchoolCalendarServiceAtoms } from '../../../services/SchoolCalendar';
import { updateScheduleAllStudents } from '../State/AttendanceAtoms';
import { schoolMonthAttendanceAtom } from '../State/AttendanceByStudentAtoms';
import { AttendanceCellData, hasCalendarLesson, memoizedDaysInMonth } from '../Utility';
import { AttendanceByStudentHeaderPopup } from './AttendanceHeaderPopup';
import { AttendanceRecordRowProps } from './AttendanceRecordRowProps';

const ResetAttendanceEditLink: FunctionComponent<AttendanceCellData> = (props) => {
  const updateSchedule = useUpdateAtom(updateScheduleAllStudents);

  const resetAttendance = () => {
    updateSchedule({ ...props, attendanceStatus: undefined });
  };

  if (!hasCalendarLesson(props)) return <></>;
  return (
    <Link fontSize="medium" onClick={resetAttendance}>
      Reset
    </Link>
  );
};

export const AttendanceByStudentHeader: React.FunctionComponent<AttendanceRecordRowProps> = React.memo(({ groupKey }) => {
  const attendanceRecord = useAtomValue(schoolMonthAttendanceAtom);
  const schoolCalendar = useAtomValue(SchoolCalendarServiceAtoms.schoolCalendar);
  const singleRow = attendanceRecord[groupKey];

  if (!singleRow)
    return (
      <>
        <Stack key="title" direction="row" marginBottom="10px" fontSize="12px">
          <div>Click a date to select all students</div>
        </Stack>
      </>
    );

  const daysArray = memoizedDaysInMonth(singleRow.startOfMonth);

  const attendanceArray = daysArray.map(({ date, dayOfMonth, isWeekend }) => ({
    date,
    dayOfMonth,
    enrolled: !isWeekend,
    attendanceStatus: schoolCalendar[date]?.attendanceStatus,
    calendarId: schoolCalendar[date]?.id,
  }));

  return (
    <>
      <Stack key="title" direction="row" marginBottom="10px" fontSize="12px">
        <div>Click a date to select all students</div>
      </Stack>
      <Stack key={groupKey} direction="row" fontSize="12px" alignItems={'center'} position="relative">
        {attendanceArray.map((row, i) => (
          <AttendanceByStudentHeaderPopup key={i} {...row}>
            <ResetAttendanceEditLink {...row} />
          </AttendanceByStudentHeaderPopup>
        ))}
      </Stack>
    </>
  );
}, _.isEqual);

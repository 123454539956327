import { SchoolDto, SessionLessonPlanDto } from '@edanalytics/ff_be_se';
import _ from 'lodash';
import { inRange } from '../../../utils/TimeHelper';
import { AttendanceMonthData } from '../AttendanceRows/AttendanceRecordRowProps';
import { AttendanceInfo, memoizedDayOfMonth, memoizedDaysInMonth, memoizedIsWeekend } from '../Utility';
import { HashedAttendanceInfo } from './AttendanceAtoms';

///  MAPPING FUNCTIONS
const mapAtt = (lesson: SessionLessonPlanDto, lessonPlanType: number) => {
  const { student, byUser: tutor } = lesson;
  const isWeekend = memoizedIsWeekend(lesson.startDateTime);
  const dayOfMonth = memoizedDayOfMonth(lesson.startDateTime);
  const { attendanceStatus, lessonStatus, id: lessonPlanId, isMakeUp } = lesson;

  return {
    date: lesson.startDateTime.substring(0, 10),
    dayOfMonth,
    attendanceStatus,
    lessonStatus,
    lessonPlanId,
    isMakeUp,
    enrolled: !isWeekend,
    schoolId: student?.schoolId,
    studentId: student?.id,
    lessonPlanType,
    tutorId: tutor?.id,
  } as AttendanceInfo;
};

const pickLessonRecordFromDay = (lessons: HashedAttendanceInfo[]) => {
  const byHasLessonId = _.groupBy(lessons, (l) => !!l.id);
  if (byHasLessonId.true) return byHasLessonId.true[0];
  if (byHasLessonId.false) return byHasLessonId.false[0];
  return undefined;
};

export interface Enrollment {
  schoolId: number;
  entryDate?: string;
  exitDate?: string;
  student: {
    id: number;
    schoolId: number;
    fullName: string;
  };
}

export const mapByStudent = (lessons: HashedAttendanceInfo[], startOfMonth: string, enrollments: Enrollment[]): AttendanceMonthData => {
  const lessonPlanTypesCounts = _.countBy(
    lessons.filter((l) => !!l.lessonPlanType),
    'lessonPlanType',
  );
  const lessonPlanType = +(_.maxBy(Object.keys(lessonPlanTypesCounts), (k) => lessonPlanTypesCounts[k]) ?? '1');

  const firstLesson = lessons[0];
  const { student, byUser: tutor } = firstLesson;
  if (!student || !tutor) throw new Error('Student or tutor not found');
  // const attendance = lessons.map((lesson) => mapAtt(lesson, lessonPlanType));
  const lessonsWithDate = lessons.filter((l) => !!l.startDateTime);

  const attendanceGroupedByDay = _.groupBy(lessonsWithDate, (l) => l.startDateTime.substring(0, 10)) as {
    [key: string]: HashedAttendanceInfo[];
  };
  const attendanceByDay = _.mapValues(attendanceGroupedByDay, (l: HashedAttendanceInfo[]) => {
    const lesson = pickLessonRecordFromDay(l);
    return lesson ? mapAtt(lesson, lessonPlanType) : undefined;
  });

  const daysArray = memoizedDaysInMonth(startOfMonth);
  const school = { id: student?.schoolId } as SchoolDto;
  const attendance = daysArray.map(({ date, dayOfMonth }) => {
    const attendanceRow = attendanceByDay[date];
    if (attendanceRow) return { ...attendanceRow, date, dayOfMonth };

    const isWeekend = memoizedIsWeekend(date);
    return {
      date,
      dayOfMonth,
      enrolled: !isWeekend && enrollments.some((e) => inRange(date, { start: e.entryDate, end: e.exitDate })),
      schoolId: student?.schoolId,
      studentId: student?.id,
      lessonPlanType,
      tutorId: tutor?.id,
    } as AttendanceInfo;
  });

  return {
    student,
    tutor,
    startOfMonth,
    attendance,
    school,
  };
};

import { Box } from '@chakra-ui/react';
import { AttendanceStatusTypes, FFRoles, LessonStatusTypes } from '@edanalytics/ff_be_se';
import { ColumnDef } from '@tanstack/react-table';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense, useEffect } from 'react';
import { FfTable } from '../FfTable';
import { studentSort, tutorSort } from '../FfTable/Filters';
import { StudentLink, TutorLink, UserLink } from '../Utils';
import { attendanceEditAtom } from './State/AttendanceAtoms';
import { AttendanceRecordChartKey } from './AttendanceRecordChartKey';
import { AttendanceByStudentHeader } from './AttendanceRows/AttendanceByStudentRowHeader';
import { AttendanceMonthData } from './AttendanceRows/AttendanceRecordRowProps';
import { AttendanceStudentTotalsRowHeader } from './AttendanceRows/AttendanceStudentTotalsRowHeader';
import { AttendanceByStudentRow } from './AttendanceRows/AttendanceByStudentRow';
import { AttendanceRecordSummaryCell } from './AttendanceTableSummaryCell';
import { schoolMonthAttendanceAtom } from './State/AttendanceByStudentAtoms';
import { userInfoAtom } from '../../ffApi';

export const keyOrder = [
  0,
  AttendanceStatusTypes.Attended,
  AttendanceStatusTypes.StudentAbsence,
  AttendanceStatusTypes.TutorAbsence,
  AttendanceStatusTypes.EventAbsence,
  AttendanceStatusTypes.Holiday,
];

function getColumns(groupKey: string, userRoleId: FFRoles | undefined): ColumnDef<AttendanceMonthData>[] {
  return [
    {
      accessorFn: (row) => row.student,
      id: 'student',
      cell: (info: any) => StudentLink(info.getValue()),
      header: () => 'Student',
      filterFn: 'studentNode',
      sortingFn: studentSort,
    },
    {
      accessorFn: (row) => row,
      id: 'tutor',
      cell: (info: any) => {
        if (info.getValue().tutor.id !== -1) {
          return info.getValue().tutor.roleId === FFRoles.Tutor
            ? TutorLink(info.getValue().school, info.getValue().tutor)
            : UserLink(info.getValue().tutor);
        }
        return `No Tutor Assigned`;
      },
      header: () => 'Tutor',
      filterFn: userRoleId === FFRoles.Tutor ? 'auto' : 'tutor',
      sortingFn: tutorSort,
    },
    {
      accessorFn: (row) => row,
      id: 'attendance',
      cell: (info: any) => <AttendanceByStudentRow groupKey={info.getValue().key} />,
      header: () => <AttendanceByStudentHeader groupKey={groupKey} />,
      footer: () => <Box textAlign="right">SCHOOL MONTHLY TOTALS</Box>,
      enableSorting: false,
    },
    {
      accessorFn: (row) => row,
      id: 'summary',
      cell: (info: any) => <AttendanceRecordSummaryCell groupKey={info.getValue().key} />,
      header: () => <AttendanceStudentTotalsRowHeader groupKey={groupKey} />,
      footer: () => <AttendanceRecordSummaryCell groupKey="totals" />,
      enableSorting: false,
    },
  ];
}

export const EmptyAttendanceTable: React.FunctionComponent<{}> = () => {
  const userInfo = useAtomValue(userInfoAtom);
  const columns = getColumns('', userInfo.roleId);

  useEffect(() => {
    console.log('EmptyAttendanceTable');
    return () => {
      console.log('EmptyAttendanceTable unmount');
    };
  }, []);

  return <FfTable columns={columns} data={[]} extraFooter={AttendanceRecordChartKey} />;
};

export const LoadedAttendanceTable: React.FunctionComponent<{}> = () => {
  const attendanceRecord = useAtomValue(schoolMonthAttendanceAtom);
  const [editAttendance, setEditAttendance] = useAtom(attendanceEditAtom);
  const userInfo = useAtomValue(userInfoAtom);

  useEffect(() => {
    if (!editAttendance) setEditAttendance(true);
    return () => {
      setEditAttendance(false);
    };
  }, []);

  const rows = Object.keys(attendanceRecord).map((key) => ({ key, ...attendanceRecord[key] }));
  const groupKey = rows?.[0]?.key;

  const columns = getColumns(groupKey, userInfo?.roleId);

  return (
    <>
      {rows.length ? (
        <>
          <FfTable columns={columns} data={rows} extraFooter={AttendanceRecordChartKey} />
        </>
      ) : (
        <EmptyAttendanceTable />
      )}
    </>
  );
};

export const AttendanceTableByStudent: React.FunctionComponent<{}> = () => (
  <Suspense fallback={<EmptyAttendanceTable />}>
    <LoadedAttendanceTable />
  </Suspense>
);

// ISO 8601 Monday is first day of week
import { DateTime } from 'luxon';
import { LESSON_PLAN_CURRENT_START_DATE, LESSON_PLAN_DAYS_BEHIND } from '../config/constants';

export const Weekdays: Record<number, string> = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};
// give this function 15 and it will return a date at nearest quarter interval for that hour
export const GetNearestIntervalDate = (minuteInterval: number): Date => {
  const timeStampCurrentOrOldDate = Date.now();
  const timeStampStartOfDay = new Date().setHours(0, 0, 0, 0);
  const timeDiff = timeStampCurrentOrOldDate - timeStampStartOfDay;
  const mod = Math.ceil(timeDiff / (minuteInterval * 60 * 1000));
  return new Date(timeStampStartOfDay + mod * (minuteInterval * 60 * 1000));
};

// provides an array of time series by the defined step eg if step is 30mins it returns all the clock times by 30mins for one day
// return format is like ['12:00 AM', '12:30 AM', ...]
export const GenerateTimeSeries = (step: number): string[] => {
  const dt = new Date(1970, 0, 1);
  const rc = [];
  while (dt.getDate() === 1) {
    rc.push(dt.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }));
    dt.setMinutes(dt.getMinutes() + step);
  }
  return rc;
};

// adds duration to the time string 'HH:MM {AM/PM}' and returns string
export const AddTimeDuration = (time: string, duration: number): string => {
  const timeSeries = GenerateTimeSeries(duration);
  // find the time in the timeseries
  const timeIndex = timeSeries.findIndex((t) => t === time);
  if (timeIndex + 1 < timeSeries.length) {
    return timeSeries[timeIndex + 1];
  }
  return timeSeries[0];
};
export const JStoDateTime = (date: Date, zone: string) => DateTime.fromISO(date.toISOString().slice(0, -1), { zone });
export const AddDays = (date: Date, days: number, timeZone: string, startAtBeginning: boolean = true) => {
  const result = new Date(date.valueOf());

  result.setDate(date.getDate() + days);
  let utcDateTime = JStoDateTime(result, timeZone);
  utcDateTime = utcDateTime.endOf('day');
  if (startAtBeginning) {
    utcDateTime = utcDateTime.startOf('day');
  }
  const updatedDate = new Date(utcDateTime.toString());

  return updatedDate;
};

export const strDateToTimeZone = (data: string, timezone: string) =>
  (timezone ?? '') === '' ? data : DateTime.fromISO(data, { zone: timezone }).toISODate();

export const Desc =
  <T>(key: keyof T) =>
  (a: T, b: T): -1 | 0 | 1 => {
    if (a[key] < b[key]) {
      return 1;
    }
    if (a[key] > b[key]) {
      return -1;
    }
    return 0;
  };

export const Asc =
  <T>(key: keyof T) =>
  (a: T, b: T): -1 | 0 | 1 => {
    if (a[key] > b[key]) {
      return 1;
    }
    if (a[key] < b[key]) {
      return -1;
    }
    return 0;
  };

export const currentStartDate = DateTime.fromFormat(LESSON_PLAN_CURRENT_START_DATE, 'yyyy/MM/dd');

export const sessionStartDate = (timezone: string) =>
  (currentStartDate.isValid
    ? currentStartDate
    : DateTime.now().setZone(timezone).plus({ days: -LESSON_PLAN_DAYS_BEHIND }).startOf('day')
  ).toJSDate();

// eslint-disable-next-line arrow-body-style
export const inRange = <T>(date: T, { start, end }: { start?: T; end?: T }) => {
  return (!start || date >= start) && (!end || date <= end);
};

function min<T>(a: T, b: T): T {
  return a < b ? a : b;
}

function max<T>(a: T, b: T): T {
  return a > b ? a : b;
}

/**
 * Filters an array of ranges to only include ranges that overlap with a given restriction.
 * @param ranges The ranges to filter.
 * @param restriction The restriction to filter by.
 * @param startKey? The key to use for the start of the ranges if it is not 'start'.
 * @param endKey? The key to use for the end of the ranges if it is not 'end'.
 * @returns The filtered ranges.
 * @example
 * // Example usage:
 * filterRange([ { start: 1, end: 10 }, { start: 5, end: 15 } ], { start: 3, end: 12 })
 * // Returns:
 * [ { start: 3, end: 10 }, { start: 5, end: 12 } ]
 */
export function filterRange<T, U extends T, V extends T>(
  ranges: U[],
  restriction: V,
  startKey: keyof T = 'start' as any,
  endKey: keyof T = 'end' as any,
): U[] {
  const newRanges: U[] = [];

  ranges.forEach((range) => {
    // Ignore range if it is outside of the restriction
    if ((range as T)[endKey] < restriction[startKey] || (range as T)[startKey] > restriction[endKey]) {
      return;
    }
    // Trim the range to fit within the restriction
    const newStart = max((range as T)[startKey], restriction[startKey]);
    const newEnd = min((range as T)[endKey], restriction[endKey]);

    newRanges.push({ ...range, [startKey]: newStart, [endKey]: newEnd } as U);
  });

  return newRanges;
}

export function strDateListToTimeZone<T>(data: T[], timezone?: string, key?: keyof T | (keyof T)[]) {
  if (!timezone || !key) return data;
  const response = data.map((d) => {
    if (Array.isArray(key)) {
      return key.reduce((acc, k) => {
        if (d[k] !== undefined)
          return {
            ...acc,
            [k]: strDateToTimeZone(d[k] as unknown as string, timezone),
          };
        return acc;
      }, d);
    }
    if (d[key] !== undefined) {
      return {
        ...d,
        [key]: strDateToTimeZone(d[key] as unknown as string, timezone),
      };
    }

    return d;
  });
  return response as T[];
}

import {
  Box,
  Text,
  Center,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Grid,
  GridItem,
  Heading,
} from '@chakra-ui/react';
import { UserDto } from '@edanalytics/ff_be_se';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import React from 'react';
import { useAtomValue } from 'jotai';
import { uniq } from 'lodash';
import { TutorDrag } from './TutorDrag';
import { GradeSchedules } from './GradeSchedules';
import { UserServiceAtoms } from '../../services/User';
import { StudentServiceAtoms } from '../../services/Student';
import { getGradeLevelTypeLongString } from '../LessonPlanContent/models/GradeLevelEnum';

export interface TutorStudentAssignmentProps {
  schoolId: number;
  tutors?: UserDto[];
}

export interface GradeProps {
  title: string;
  gradeLevel: number;
}

export const Grade: React.FunctionComponent<GradeProps> = ({ title, gradeLevel }) => (
  <AccordionItem>
    <Heading>
      <AccordionButton paddingInlineStart={0}>
        <AccordionIcon />
        <Box flex="1" as="b" textAlign="left">
          {title}
        </Box>
      </AccordionButton>
    </Heading>
    <AccordionPanel pb={4}>
      <GradeSchedules gradeLevel={gradeLevel} />
    </AccordionPanel>
  </AccordionItem>
);

export const TutorStudentAssignment: React.FunctionComponent<TutorStudentAssignmentProps> = ({ schoolId }) => {
  const allStudents = useAtomValue(StudentServiceAtoms.getCurrentStudents);
  const allUsers = useAtomValue(UserServiceAtoms.getSchoolUsers);

  // We only use it for the Grades, but we still filter inactive students
  const students = allStudents?.filter((student) => student.active) ?? [];
  const allExceptStaff = allUsers?.filter((u: UserDto) => u.role?.name !== 'Staff' && u.active) || [];

  const gradeLevels = uniq(students?.map((t) => t.gradeLevel) ?? [])
    .map((gradeLevel) => ({
      title: getGradeLevelTypeLongString(gradeLevel),
      gradeLevel,
    }))
    .sort((a, b) => a.gradeLevel - b.gradeLevel);

  if (!allExceptStaff || allExceptStaff.length === 0) {
    return (
      <Center>
        <Text as="i">There are no tutors to assign!</Text>
      </Center>
    );
  }
  return (
    <DndProvider backend={HTML5Backend}>
      <Grid templateColumns="20% 80%">
        <GridItem>
          <Box as="b">TUTORS</Box>
          <Box id="tutors-col">
            {allExceptStaff?.map((t: UserDto) => (
              <TutorDrag schoolId={schoolId} name={t.fullName} id={t.id} key={t.id} />
            ))}
          </Box>
        </GridItem>
        <GridItem>
          <Box as="b">STUDENTS</Box>
          <Box id="grades-col" flex="1" maxH="80vh" overflowY="auto">
            <Accordion allowMultiple={true} defaultIndex={[]}>
              {gradeLevels.map((g) => (
                <Grade key={g.gradeLevel} {...g} />
              ))}
            </Accordion>
          </Box>
        </GridItem>
      </Grid>
    </DndProvider>
  );
};

import { Accordion, AccordionButton, AccordionItem, AccordionPanel, Box, Container, Flex, Heading, List, ListItem } from '@chakra-ui/react';
import React, { useState } from 'react';
import { DistrictDto, FFRoles, SchoolDto } from '@edanalytics/ff_be_se';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import _, { isEmpty } from 'lodash';
import { useAtom, useAtomValue } from 'jotai';
import { DebugCard } from '../../components/DebugCard';
import { DistrictServiceAtoms } from '../../services/DistrictAtoms';
import { SchoolService } from '../../services/School';
import { FrequentlyUsedList } from '../../components/FrequentlyUsedList';
import { frequentlyUsedListExample, frequentlyUsedListExampleStaff } from '../../utils/samples';
import { Empty } from '../../components/Empty';
import { FfDivider } from '../../components/FfDivider';
import { SchoolLink } from '../../components/Utils';
import { userInfoAtom } from '../../ffApi';

type SchoolDict = { [key in number]: SchoolDto[] };

interface DistrictAccordionItemProps {
  schools: SchoolDto[];
  index: number;
  district: DistrictDto;
}

const DistrictAccordionItem: React.FunctionComponent<DistrictAccordionItemProps> = ({ district, schools, index }) => {
  const userInfo = useAtomValue(userInfoAtom);

  const hasAccess = (s: SchoolDto) => userInfo?.role === 'Staff' || userInfo?.schoolsIds?.includes(s.id);

  return (
    <AccordionItem key={index}>
      {({ isExpanded }) => (
        <>
          <Heading>
            <AccordionButton>
              <Box flex={0} marginRight="1em">
                {isExpanded ? <MinusIcon fontSize="12px" /> : <AddIcon fontSize="12px" />}
              </Box>
              <Heading flex={1} textAlign="left" marginRight="1em" size="md" fontWeight="500">
                {district.name}
              </Heading>
            </AccordionButton>
          </Heading>
          <AccordionPanel>
            <List style={{ marginLeft: '1.75em' }}>
              {schools
                ?.filter((s) => s.districtId === district.id && hasAccess(s))
                ?.map((s: SchoolDto, sIndex: number) => (
                  <ListItem key={sIndex}>
                    <SchoolLink school={s}></SchoolLink>
                  </ListItem>
                ))}
            </List>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

export const SitesScreenContent: React.FunctionComponent = () => {
  const userInfo = useAtomValue(userInfoAtom);
  const [districts] = useAtom(DistrictServiceAtoms.getAllDistricts);
  const schoolService = useAtomValue(SchoolService);
  const [schoolsByDistrict, setSchoolsByDistrict] = useState<SchoolDict>({});

  const hasAccess = (district: DistrictDto) => userInfo?.role === 'Staff' || userInfo?.districtIds?.includes(district.id);

  const districtsSorted = _.sortBy(districts.filter(hasAccess), (s: DistrictDto) => s.name.toLowerCase());

  const addDistrictHandler = (indexes: number[]) => {
    // We get a single promise to ensure a single dispatch call
    Promise.all(
      indexes.map(async (index) => {
        const district = districtsSorted[index];
        return schoolsByDistrict[district.id]
          ? [district.id, schoolsByDistrict[district.id]]
          : [district.id, await schoolService.getSchools(district.id)];
      }),
    ).then((entries) => setSchoolsByDistrict(Object.fromEntries(entries)));
  };

  return (
    <Container maxWidth="100%">
      <Flex mb="1em">
        <Box flex={1}>
          <Heading>Future Forward Sites</Heading>
        </Box>
        <Box textAlign="right" flex={0}>
          <FrequentlyUsedList items={userInfo.roleId === FFRoles.Staff ? frequentlyUsedListExampleStaff : frequentlyUsedListExample} />
        </Box>
      </Flex>
      <FfDivider />
      <Accordion allowMultiple={true} defaultIndex={[]} onChange={addDistrictHandler}>
        {districtsSorted.map((district: DistrictDto, index: number) => (
          <DistrictAccordionItem key={index} schools={schoolsByDistrict[district.id] ?? []} district={district} index={index} />
        ))}
      </Accordion>
      {isEmpty(districts) && <Empty description="No sites." />}
      <DebugCard data={[{ name: 'districts', data: districts }]} />
    </Container>
  );
};

export const SitesScreen: React.FunctionComponent = () => <SitesScreenContent />;

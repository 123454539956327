/* eslint-disable no-nested-ternary */
import { Box, Flex, Heading, Stack } from '@chakra-ui/react';
import { AttendanceStatusTypes } from '@edanalytics/ff_be_se';
import { ColumnDef } from '@tanstack/react-table';
import { useAtomValue } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import { FunctionComponent as FC, Suspense } from 'react';
import { FfTable } from '../FfTable';
import { Loader } from '../Loader';
import { calculateAttendanceScore } from '../Utils';
import { attendanceEditAtom } from './State/AttendanceAtoms';
import { studentAttendanceAtom } from './State/StudentAttendanceAtom';
import { AttendanceRecordChartKey } from './AttendanceRecordChartKey';
import { AttendanceByMonthRow } from './AttendanceRows/AttendanceByStudentRow';
import { AttendanceMonthData } from './AttendanceRows/AttendanceRecordRowProps';
import { AttendanceChartEdit } from './Legacy/AttendanceChartEdit';
import { AttendanceCellData, memoizedMonthLabel } from './Utility';
import { Desc } from '../../utils/TimeHelper';
import './attendance-box.css';

const AttendanceLeftCol: FC<{ groupKey: string; attendance: AttendanceCellData[] }> = ({ groupKey, attendance }) => {
  const monthLabel = memoizedMonthLabel(groupKey);

  const { rate: attendanceScore } = calculateAttendanceScore(
    attendance
      .filter(({ lessonStatus, attendanceStatus }) => lessonStatus && attendanceStatus)
      .map((a) => a as AttendanceCellData & { lessonStatus: number; attendanceStatus: number }),
  );

  return (
    <Stack direction="row" marginBottom="5px" fontSize="12px">
      <Box textAlign="center" w="50px" className={'monthLabel'}>
        {monthLabel}
      </Box>
      <Box textAlign="right" w="100px" className={'attnRateLabel'}>
        {attendanceScore}
      </Box>
    </Stack>
  );
};

export const keyOrder = [
  AttendanceStatusTypes.Attended,
  AttendanceStatusTypes.EventAbsence,
  AttendanceStatusTypes.Holiday,
  AttendanceStatusTypes.Scheduled,
  AttendanceStatusTypes.StudentAbsence,
  AttendanceStatusTypes.TutorAbsence,
];

function getColumns(groupKey: string): ColumnDef<AttendanceMonthData>[] {
  return [
    {
      accessorFn: (row) => row,
      id: 'month',
      cell: (info: any) => <AttendanceLeftCol groupKey={info.getValue().key} attendance={info.getValue().attendance} />,
      header: () => 'Month Attendance Rate',
      enableSorting: false,
    },
    {
      accessorFn: (row) => row,
      id: 'attendance',
      cell: (info: any) => <AttendanceByMonthRow groupKey={info.getValue().key} />,
      header: () => 'Lesson Plans and Scheduled Sessions',
      enableSorting: false,
    },
  ];
}

export const AttendanceTableByMonth: React.FunctionComponent<{}> = () => {
  const attendanceRecord = useAtomValue(studentAttendanceAtom);
  const setEditing = useUpdateAtom(attendanceEditAtom);
  const rows = Object.keys(attendanceRecord)
    .map((key) => ({ key, ...attendanceRecord[key] }))
    .sort(Desc('key'));
  const groupKey = rows?.[0]?.key;

  const columns = getColumns(groupKey);

  return (
    <Stack mb="3em">
      <Flex>
        <Box>
          <Heading color={'ff.blue'}>
            Attendance Record <AttendanceChartEdit editToggleCb={() => setEditing((prev) => !prev)} />
          </Heading>
        </Box>
      </Flex>
      <Suspense fallback={<Loader />}>
        <FfTable
          columns={columns}
          data={rows}
          extraFooter={AttendanceRecordChartKey}
          enablePagination={false}
          removeMarginBottomBorder={true}
          onlyTopHeader={true}
          showHorizontalGridLines={true}
        />
      </Suspense>
    </Stack>
  );
};

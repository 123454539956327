import React from 'react';
import { HStack } from '@chakra-ui/react';
import {
  Legend,
  AttendanceAttendedSubmittedIcon,
  AttendanceAttendedIcon,
  AttendanceStudentAbsenceIcon,
  AttendanceTutorAbsenceIcon,
  AttendanceEventAbsenceIcon,
  AttendanceHolidayIcon,
  AttendanceMakeUpIcon,
  NoAttendanceRecord,
  LessonPlanCreated,
  ScheduledSession,
} from './AttendanceChartKey';

export const AttendanceRecordChartKey: React.FC = () => (
  <HStack fontSize={12} justify="left" margin="1em">
    <Legend name="Complete">
      <AttendanceAttendedSubmittedIcon />
    </Legend>
    <Legend name="Attended">
      <AttendanceAttendedIcon />
    </Legend>
    <Legend name="Student Absence">
      <AttendanceStudentAbsenceIcon />
    </Legend>
    <Legend name="Tutor Absence">
      <AttendanceTutorAbsenceIcon />
    </Legend>
    <Legend name="Event Absence">
      <AttendanceEventAbsenceIcon />
    </Legend>
    <Legend name="Holiday">
      <AttendanceHolidayIcon />
    </Legend>
    <Legend name="Lesson Plan Created">
      <LessonPlanCreated />
    </Legend>
    <Legend name="Scheduled Session">
      <ScheduledSession />
    </Legend>
    <Legend name="Make-up">
      <AttendanceMakeUpIcon />
    </Legend>
    <Legend bg="magenta" name="No Attendance Record">
      <NoAttendanceRecord />
    </Legend>
  </HStack>
);

import { AxiosResponse } from 'axios';
import { SessionObservationDto } from '@edanalytics/ff_be_se';
import { atom, Getter } from 'jotai';
import { apiClientAtom } from '../ffApi';
import { persistNavPropsAtom } from '../atoms/navAtom';
import { GetRangeParams } from './LessonPlan/dto/GetAllSavedForSchoolInput';
import { AddDays, sessionStartDate } from '../utils/TimeHelper';
import { SchoolService } from './School';
import { MetricDto } from '../components/MetricCard';

const tutorServiceFactory = (get: Getter) => ({
  getObservations: async (schoolId: number, tutorId: number, params: GetRangeParams): Promise<SessionObservationDto[]> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/tutors/${tutorId}/session-observations`, { params });
    return response.data.results as SessionObservationDto[];
  },
});
const tutorMetricsServiceFactory = (get: Getter) => ({
  getTutorMetrics: async (schoolId: number | undefined, tutorId: number | undefined): Promise<any> => {
    const response = await Promise.all([
      get(apiClientAtom).get(`/schools/${schoolId}/tutors/${tutorId}/metrics/attendance/`) as Promise<AxiosResponse<MetricDto[]>>,
      get(apiClientAtom).get(`/schools/${schoolId}/tutors/${tutorId}/metrics/student-count-and-levels-advanced/`) as Promise<
        AxiosResponse<MetricDto[]>
      >,
    ]);
    return response.flatMap((res) => res.data);
  },
});

const TutorService = atom(tutorServiceFactory);
export { TutorService };

export const TutorServiceAtoms = {
  getCurrentTutorObservations: persistNavPropsAtom(async (get, nav) => {
    if (!nav.schoolId || !nav.tutorId) return undefined;
    const schoolData = await get(SchoolService).getSchool(nav.districtId || 1, nav.schoolId);
    return tutorServiceFactory(get).getObservations(nav.schoolId, nav.tutorId, {
      start: sessionStartDate(schoolData.timezone),
      end: AddDays(new Date(), 0, schoolData.timezone, false),
    });
  }),
  getTutorMetrics: persistNavPropsAtom(async (get, nav) => {
    if (!nav.schoolId || !nav.tutorId) return [];
    return tutorMetricsServiceFactory(get).getTutorMetrics(nav.schoolId, nav.tutorId);
  }),
};

import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Text, Button, Divider, Image, Menu, MenuButton, MenuItem, MenuList, Box, HStack } from '@chakra-ui/react';
import { FFRoles } from '@edanalytics/ff_be_se';
import { useAtom, useAtomValue } from 'jotai';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Toolbox from '../../assets/images/Toolbox.svg';
import { whoAmIatom } from '../../services/Identity';
import { SchoolServiceAtoms } from '../../services/School';
import { FecMenu } from './FecMenu';
import { IcMenu } from './IcMenu';
import { StaffMenu } from './StaffMenu';
import { persistNavAtom } from '../../atoms/navAtom';

export const ToolBoxMenu: React.FunctionComponent = () => {
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const [nav] = useAtom(persistNavAtom);
  const user = useAtomValue(whoAmIatom);
  const navigate = useNavigate();
  const navClick = (to: string) => () => {
    navigate(to);
  };
  const schoolId = nav.schoolId ?? ((user.schools?.length ?? 0) === 1 ? user.schools?.[0]?.id : undefined);
  const isTutor = user?.roleId === FFRoles.Tutor;
  const isIC = user?.roleId === FFRoles.IC;
  const isFEC = user?.roleId === FFRoles.FEC;
  const isStaff = user?.roleId === FFRoles.Staff;
  const schoolSelected = school !== undefined;

  return (
    <Menu>
      <MenuButton
        color={'ff.blue'}
        position="absolute"
        top="0px"
        backgroundColor={'ff.lightPink'}
        _hover={{ bg: 'ff.lightPink' }}
        _active={{ bg: 'ff.lightPink' }}
        as={Button}
        width="271px"
        border="0px"
        borderRadius={'0px 0px 5px 5px'}
      >
        <HStack>
          <Box w="0px" overflow="visible">
            <Image maxW="none" zIndex="15" width="40px" src={Toolbox} />
          </Box>
          <Text flexGrow={1} textAlign="center">
            My Toolbox
          </Text>
        </HStack>
      </MenuButton>
      <MenuList
        borderRadius={'0px 0px 5px 5px'}
        borderColor={'ff.lightPink'}
        backgroundColor={'ff.lightPink'}
        color={'ff.blue'}
        width="271px"
        marginLeft={'1.5px'}
      >
        {isTutor && (
          <>
            <MenuItem
              color={'ff.blue'}
              onClick={navClick(schoolSelected ? `/schools/${schoolId}/tutors/${user?.id}/dashboard` : '/schools')}
            >
              Dashboard
            </MenuItem>
            <MenuItem color={'ff.blue'} isDisabled={!schoolId} onClick={navClick(`/schools/${schoolId}/students`)}>
              All Students
            </MenuItem>
            <MenuItem color={'ff.blue'} onClick={navClick(`/schools/${schoolId}/lesson-plans`)}>
              All Lesson Plans
            </MenuItem>
            <MenuItem color={'ff.blue'} onClick={navClick(`/schools/${schoolId}/attendance`)}>
              Monthly Attendance
            </MenuItem>
            <MenuItem color={'ff.blue'} onClick={navClick('/forms')}>
              Forms
            </MenuItem>
            <Divider borderColor={'ff.blue'} />
            <MenuItem color={'ff.blue'} onClick={navClick('/logout')}>
              <ExternalLinkIcon /> Logout
            </MenuItem>
          </>
        )}
        {isIC && <IcMenu schoolId={schoolId} />}
        {isFEC && <FecMenu schoolId={schoolId} />}
        {isStaff && <StaffMenu schoolId={schoolId} />}
      </MenuList>
    </Menu>
  );
};

/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import { UserDto } from '@edanalytics/ff_be_se';
import { Provider, useAtom, useAtomValue } from 'jotai';
import { isEqual, isNaN, isNull, isUndefined, mapValues, toNumber } from 'lodash';
import { Key, ReactNode, Suspense, useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { storedDistrictsAtom } from '../../atoms/districtAtom';
import { IParams, navAtom } from '../../atoms/navAtom';
import { globalProviderScope } from '../../atoms/symbols';
import { whoAmIatom } from '../../services/Identity';
import { Loader } from '../Loader';
import { PreloadDistricts } from './PreloadDistricts';

export interface PreloadNavProps {
  providerKey?: Key;
  children: ReactNode;
  consoleOn?: boolean;
}

const toNum = (text: string | undefined | null) => (isUndefined(text) || isNull(text) ? undefined : isNaN(+text) ? text : toNumber(text));

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => Object.fromEntries(new URLSearchParams(search)), [search]);
};

export const PreloadNavInternal: React.FunctionComponent<PreloadNavProps> = (props: PreloadNavProps) => {
  const navigate = useNavigate();

  if (window.location.href.indexOf('/callback?code') > 0) navigate('/'); // GO HOME!!!

  const [nav, setNav] = useAtom(navAtom);
  const storedDistricts = useAtomValue(storedDistrictsAtom);
  const user = useAtomValue(whoAmIatom) as UserDto;
  const params = useParams();
  const query = useQuery();
  const parameters = mapValues({ ...params, ...query }, toNum) as IParams;
  parameters.oneSemester = window.location.href.indexOf('/family-interaction') > 0;
  parameters.userId = user.id;
  parameters.tutorId ??= user.role?.name === 'Tutor' ? user.id : undefined;
  parameters.districtId ??=
    parameters.schoolId &&
    (user.role?.name === 'Staff'
      ? storedDistricts.find((d) => d.schoolIds?.includes(parameters.schoolId ?? -1))?.id
      : user.schools?.find((s) => s.id === parameters.schoolId)?.districtId);

  parameters.timezone =
    user.schools?.find((s) => s.id === parameters.schoolId)?.timezone ??
    storedDistricts.find((d) => d.schoolIds?.includes(parameters.schoolId ?? -1))?.timezone;

  useEffect(() => {
    if (!isEqual(parameters, nav)) {
      setNav(parameters);
      if (props.consoleOn) console.info(`Screen parameters change: ${JSON.stringify(parameters)}`);
    }
  }, []);

  useEffect(() => {
    if (!isEqual(parameters, nav)) {
      setNav(parameters);
    }
  }, [setNav, parameters, nav]);

  if (!isEqual(parameters, nav)) return null;

  return <>{props.children}</>;
};

export const PreloadNav: React.FunctionComponent<PreloadNavProps> = (props) => {
  const { children, ...rest } = props;

  return (
    <Provider key={props.providerKey} scope={globalProviderScope}>
      <Suspense fallback={<Loader />}>
        <PreloadDistricts>
          <PreloadNavInternal {...rest}>{children}</PreloadNavInternal>
        </PreloadDistricts>
      </Suspense>
    </Provider>
  );
};

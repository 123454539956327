import { atom, useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { TutorAppointment } from '../../models/TutorAppointment';
import { SchoolServiceAtoms } from '../../services/School';

type ScheduleFormArrayProps = {
  studentId: number;
};
export type AppointmentsType = { [k in number | string]: any[] };

const appointmentsAtomInternal = atom<AppointmentsType>({});

type ScheduleFormArrayReturn = {
  appts: TutorAppointment[];
  addAppointment: () => void;
  updateAppointment: (index: number, dayOfWeek?: number, startTime?: string, endTime?: string) => void;
  updateTutor: (index: number, tutorId?: number, tutorName?: string) => void;
  removeAppointment: (index: number) => void;
  hasError: boolean;
  setHasError: (key: string, value: boolean) => void;
  clearError: () => void;
};

export const TutorAssignmentIsSavedAtom = atom<boolean | undefined>(true);
export const TutorAssignmentHasErrorAtom = atom<{ [key in string]: boolean }>({});
export const SemesterIdForScheduleFilter = atom<number>(0);

export const AnyTutorAssignmentHasErrorAtom = atom((get) => {
  const hasError = get(TutorAssignmentHasErrorAtom);
  return Object.values(hasError).some((e) => e);
});

export const appointmentsAtom = atom(
  (get) => {
    const local = get(appointmentsAtomInternal);
    const semesterIdFilter = get(SemesterIdForScheduleFilter);
    if (Object.keys(local).length > 0) return local;
    const remote = get(SchoolServiceAtoms.getCurrentSchoolTutorAssignments) ?? []; // GetStudentTutorAssignmentDto[]
    const result = remote?.reduce((acc, assignment) => {
      acc[assignment.studentId] = assignment.schedule
        .filter((s) => s.districtSemesterId === +semesterIdFilter)
        .map(
          (s) =>
            ({
              id: s.id,
              dayOfWeek: s.dayOfWeek,
              startTime: s.startTime,
              endTime: s.endTime,
              tutorId: s.userId,
              tutorName: s.user?.fullName ?? '',
            } as TutorAppointment),
        );
      return acc;
    }, {} as AppointmentsType);
    return result;
  },
  (get, set, update: AppointmentsType | typeof RESET) => {
    if (update === RESET) {
      if (get(TutorAssignmentIsSavedAtom) ?? true) return;
      set(appointmentsAtomInternal, {});
      set(SchoolServiceAtoms.getCurrentSchoolTutorAssignments, RESET);
      set(TutorAssignmentIsSavedAtom, true);
      set(TutorAssignmentHasErrorAtom, {});
    } else {
      const hasError = get(TutorAssignmentHasErrorAtom);
      if (Object.values(hasError).some((x) => x)) return;
      set(appointmentsAtomInternal, update);
      set(TutorAssignmentIsSavedAtom, false);
    }
  },
);

export const useScheduleFormArray = ({ studentId }: ScheduleFormArrayProps): ScheduleFormArrayReturn => {
  const [allAppts, setAllAppts] = useAtom(appointmentsAtom);
  const [isSaved, setIsSaved] = useAtom(TutorAssignmentIsSavedAtom);
  const [hasError, setHasErrorNumber] = useAtom(TutorAssignmentHasErrorAtom);
  const appts = allAppts[studentId] ?? [];
  const setAppts = (apps: TutorAppointment[]) => {
    setAllAppts({ ...allAppts, [studentId]: apps ?? [] } as AppointmentsType);
  };

  const setHasError = (key: string, value: boolean) => {
    setHasErrorNumber({ ...hasError, [key]: value });
  };

  const oneError = Object.values(hasError).some((e) => e);

  const clearError = () => {
    setHasErrorNumber({});
  };

  const addAppointment = () => {
    const a = appts;
    a.push({
      dayOfWeek: undefined,
      startTime: '12 PM',
      endTime: undefined,
      tutorId: undefined,
      tutorName: undefined,
    } as TutorAppointment);
    setIsSaved(false);
    setAppts([...a]);
  };

  const updateAppointment = (index: number, dayOfWeek?: number, startTime?: string, endTime?: string) => {
    console.log('updateAppointment', index, dayOfWeek, startTime, endTime);
    const newAppts = appts.map((a, i) => {
      if (i === index) {
        return {
          id: a.id,
          dayOfWeek: dayOfWeek !== undefined ? dayOfWeek : a.dayOfWeek,
          startTime: startTime !== undefined ? startTime : a.startTime,
          endTime: endTime !== undefined ? endTime : a.endTime,
          tutorId: a.tutorId,
          tutorName: a.tutorName,
        } as TutorAppointment;
      }
      return a;

      // ADD MORE COPMPARE CODE
    });

    // console.log('hello', isSaved);
    setIsSaved(false);
    setAppts([...newAppts]);
  };

  const updateTutor = (index: number, tutorId?: number, tutorName?: string) => {
    const newAppts = appts.map((a: any, i) => {
      if (i === index) {
        return {
          id: a.id,
          dayOfWeek: a.dayOfWeek,
          startTime: a.startTime,
          endTime: a.endTime,
          tutorId,
          tutorName,
        } as TutorAppointment;
      }
      return a;
    });
    setIsSaved(false);
    setAppts([...newAppts]);
  };

  const removeAppointment = (index: number) => {
    const newAppts = appts.filter((a, i) => i !== index);
    setIsSaved(false);
    setAppts([...newAppts]);
  };

  return { appts, addAppointment, updateAppointment, updateTutor, removeAppointment, hasError: oneError, setHasError, clearError };
};

/* eslint-disable @typescript-eslint/lines-between-class-members */
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  Box,
  Button,
  CloseButton,
  Flex,
  Select,
  VStack,
} from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import { DateTime as DT } from 'luxon';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Background from '../../assets/images/make-up-modal-background.svg';
import { FfButtonTypes } from '../../models/Button';
import { DateTimePicker } from '../DateTimePicker';
import { TimePicker } from '../TimePicker';
import { scheduledSessionsAtom, useLessonPlanCopyDialog } from './LessonPlanResheduleAtoms';
import { DialogOperation, DialogStages } from './LessonPlanTypes';
import { CompactFormatDateString, LessonPlanStartEndTime, SlugFormatTime, getDateExclusions } from '../Utils';
import { studentEnrollmentsAtom } from '../FamilyInteraction/GenericInteractionForm';

const useLog = (obj: any, label?: string) => {
  React.useEffect(() => {
    console.log(label ? { [label]: obj } : obj);
  }, [obj]);
};

type TimeParts = { startTimePart?: string; endTimePart?: string; datePart?: Date };
export const LessonPlanRescheduleDialog: React.FunctionComponent = () => {
  const enrollments = useAtomValue(studentEnrollmentsAtom);
  const exclusions = getDateExclusions(enrollments);
  const scheduledSessions = useAtomValue(scheduledSessionsAtom) ?? [];
  const nav = useNavigate();
  useLog(scheduledSessions, 'scheduledSessions');
  const [
    { state: dialogState, dialogOperation },
    dialogStage,
    { closeDialog, setStage: setDialogStage, setTimeFrame, setPending, setToEntry },
  ] = useLessonPlanCopyDialog();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const [hasError, setHasError] = useState(false);

  //  MAPPING DATE AND START/END TIME TO START/END DATE
  const [timeParts, setDateTimeParts] = useState<TimeParts>({
    startTimePart: DT.now().startOf('hour').toFormat('HH:mm'),
    endTimePart: DT.now().startOf('hour').plus({ minutes: 30 }).toFormat('HH:mm'),
    datePart: DT.now().startOf('day').toJSDate(),
  });
  // useLog(timeParts, 'timeParts');
  const { startTimePart, endTimePart, datePart } = timeParts;

  const updateTimeParts = (parts: TimeParts) => {
    setDateTimeParts({ ...timeParts, ...parts });
  };
  const updateDialogDates = () => {
    if (!datePart || !startTimePart || !endTimePart) return;
    const startDateOnly = DT.fromJSDate(datePart);
    const startTime = DT.fromFormat(startTimePart, 'HH:mm');
    const endTime = DT.fromFormat(endTimePart, 'HH:mm');

    const startDateTime = startTime.set({ year: startDateOnly.year, month: startDateOnly.month, day: startDateOnly.day });
    const endDateTime = endTime.set({ year: startDateOnly.year, month: startDateOnly.month, day: startDateOnly.day });

    setTimeFrame(DialogStages.CustomSession, { startDate: startDateTime.toJSDate(), endDate: endDateTime.toJSDate() });
  };
  useEffect(() => {
    if (dialogState?.stage !== DialogStages.CustomSession) return;
    updateDialogDates();
  }, [startTimePart, endTimePart, datePart, dialogState?.stage]);
  // END MAPPING DATE AND START/END TIME TO START/END DATE

  useEffect(() => {
    if (dialogState?.stage !== DialogStages.Done) return;
    nav(dialogState.redirect);
    closeDialog();
  }, [dialogState?.stage, nav]);

  const title = dialogOperation === DialogOperation.MakeUp ? 'Schedule Make Up Lesson Plan' : 'Duplicate Lesson Plan';

  const buttonName = dialogOperation === DialogOperation.MakeUp ? 'Schedule Make Up' : 'Duplicate';

  return (
    <AlertDialog isOpen={dialogStage !== DialogStages.Closed} leastDestructiveRef={cancelRef} onClose={() => closeDialog()} size="none">
      <AlertDialogOverlay>
        <AlertDialogContent bgColor={'ff.blue'} width="auto">
          <AlertDialogBody p="1">
            <Flex justifyContent="right" color="ff.white">
              <CloseButton onClick={() => closeDialog()} textAlign="right" />
            </Flex>
            {dialogStage === DialogStages.Start && (
              <Box p="2em" pt="0">
                <VStack
                  px="6em"
                  color="ff.white"
                  gap="2em"
                  backgroundImage={Background}
                  backgroundRepeat="no-repeat"
                  backgroundPosition="center"
                  backgroundSize="contain"
                >
                  <Box>{title}</Box>
                  <Box color="ff.white" fontSize="2.5em" textAlign="center" w="10em">
                    Would you like to use a scheduled session slot or pick a custom date?
                  </Box>
                  <Flex textAlign="center" mt="1em" w="100%" justifyContent={'space-between'}>
                    <Button variant={'cta'} disabled={false} onClick={() => setDialogStage(DialogStages.ScheduledSession)}>
                      Use a Scheduled Session
                    </Button>
                    <Button variant={'cta'} disabled={false} onClick={() => setDialogStage(DialogStages.CustomSession)} ml={3}>
                      Pick a Custom Date/Time
                    </Button>
                  </Flex>
                </VStack>
              </Box>
            )}
            {dialogState.stage === DialogStages.ScheduledSession && (
              <Box p="2em" pt="0">
                <VStack
                  px="6em"
                  color="ff.white"
                  gap="2em"
                  backgroundImage={Background}
                  backgroundRepeat="no-repeat"
                  backgroundPosition="center"
                  backgroundSize="contain"
                >
                  <Box>{title}</Box>
                  <Box color="ff.white" fontSize="2.5em" textAlign="center" w="10em">
                    Select an upcoming session from the student's schedule.
                  </Box>
                  <Box>
                    <Select
                      value={dialogState.scheduledSession?.startDateTime}
                      size="sm"
                      placeholder="Scheduled Sessions"
                      color={'ff.blue'}
                      onChange={(event) => {
                        console.log(event.target.selectedIndex);
                        setTimeFrame(DialogStages.ScheduledSession, {
                          scheduledSession: scheduledSessions[event.target.selectedIndex - 1],
                        });
                      }}
                    >
                      {scheduledSessions.map((session, k) => (
                        <option key={k} id={session.startDateTime} value={session.startDateTime}>
                          {LessonPlanStartEndTime(session)}
                        </option>
                      ))}
                    </Select>
                  </Box>
                  <Flex textAlign="center" mt="1em" w="100%" justifyContent={'space-between'}>
                    <Button variant={'outlineWater'} onClick={() => setDialogStage(DialogStages.Start)}>
                      Take Me Back
                    </Button>
                    <Button
                      type={FfButtonTypes.Submit}
                      variant={'cta'}
                      isDisabled={!dialogState.scheduledSession?.startDateTime}
                      onClick={() => setPending(dialogState.stage)}
                    >
                      {buttonName}
                    </Button>
                  </Flex>
                </VStack>
              </Box>
            )}
            {dialogState.stage === DialogStages.CustomSession && (
              <Box p="2em" pt="0">
                <VStack
                  px="6em"
                  backgroundImage={Background}
                  backgroundRepeat="no-repeat"
                  backgroundPosition="center"
                  backgroundSize="contain"
                >
                  <Box color="ff.white">{title}</Box>
                  <Box color="ff.white" fontSize="2.5em" textAlign="center" w="10em">
                    Choose a date/time for this {dialogOperation === DialogOperation.MakeUp ? ' make up ' : ' new '} lesson plan
                  </Box>
                  <Flex textAlign="center" mt="1em" w="100%" justifyContent={'space-between'}>
                    <Box mb={'1em'}>
                      <span style={{ position: 'relative', zIndex: '100' }}>
                        <DateTimePicker
                          selectedDate={datePart}
                          onChange={(newDatePart) => updateTimeParts({ datePart: newDatePart })}
                          showTimeSelect={false}
                          dateFormat="MM/dd/yyyy"
                          excludeDateIntervals={exclusions}
                        />
                      </span>
                    </Box>
                    <Box>
                      <TimePicker
                        startTime={startTimePart}
                        endTime={endTimePart}
                        format="HH:mm"
                        onChange={(d) => {
                          updateTimeParts({ startTimePart: d.startTime, endTimePart: d.endTime });
                        }}
                        // eslint-disable-next-line no-template-curly-in-string
                        onError={(e) => setHasError((e ?? '') !== '')}
                        rangeMode
                      />
                    </Box>
                  </Flex>
                  <Flex textAlign="center" mt="1em" w="100%" justifyContent={'space-between'}>
                    <Button variant={'outlineWater'} disabled={false} onClick={() => setDialogStage(DialogStages.Start)}>
                      Take Me Back
                    </Button>
                    <Button variant={'cta'} disabled={hasError} onClick={() => setPending(dialogState.stage)} ml={3}>
                      {buttonName}
                    </Button>
                  </Flex>
                </VStack>
              </Box>
            )}
            {dialogStage === DialogStages.Pending && (
              <Box p="2em" pt="0">
                <VStack
                  px="6em"
                  color="ff.white"
                  gap="2em"
                  backgroundImage={Background}
                  backgroundRepeat="no-repeat"
                  backgroundPosition="center"
                  backgroundSize="contain"
                >
                  <Box>{title}</Box>
                </VStack>
              </Box>
            )}
            {dialogState.stage === DialogStages.Conflict && (
              <Box p="2em" pt="0">
                <VStack
                  px="1em"
                  color="ff.white"
                  backgroundImage={Background}
                  backgroundRepeat="no-repeat"
                  backgroundPosition="center"
                  backgroundSize="contain"
                >
                  <Box>{title}</Box>
                  <Box color="ff.white" fontSize="2.5em" textAlign="center" w="14em">
                    The date/time you've selected conflicts with the following session:
                  </Box>
                  {dialogState.error && (
                    <>
                      <Flex w="100%" direction={'column'} textAlign={'center'} color="ff.blue" bg="ff.white" borderRadius={'5px'} p={'1em'}>
                        <Box fontSize="1em" textAlign="center">
                          Tutor: {dialogState.error.tutorName}
                        </Box>
                        <Box fontSize="1em" textAlign="center">
                          Student: {dialogState.error.studentName}
                        </Box>
                        <Box fontSize="1em" textAlign="center">
                          Date/Time: {CompactFormatDateString(dialogState.error.startDateTime)} @{' '}
                          {` ${SlugFormatTime(dialogState.error.startDateTime)} - ${SlugFormatTime(dialogState.error.endDateTime)}`}
                        </Box>
                      </Flex>
                    </>
                  )}

                  <Box fontSize="xs" color={'ff.palePink'} textAlign="center">
                    Please go back and select an upcoming session or choose another date/time.
                  </Box>
                  <Flex>
                    <Button variant={'outlineWater'} disabled={false} onClick={() => setToEntry(dialogState.stage)}>
                      Take Me Back
                    </Button>
                  </Flex>
                </VStack>
              </Box>
            )}
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

/* eslint-disable no-alert */
import { Box, Button, ButtonGroup, Container, Flex, Heading, Select, Spacer, useToast } from '@chakra-ui/react';
/* eslint-disable @typescript-eslint/no-shadow */
import { IDistrictSemester, PutStudentTutorAssignmentDto, PutTutorAssignmentDto, UserDto } from '@edanalytics/ff_be_se';
import { validateSync } from 'class-validator';
import { useAtom, useAtomValue } from 'jotai';
import { RESET, useUpdateAtom } from 'jotai/utils';
import _ from 'lodash';
import React, { Suspense, useEffect } from 'react';
import { REFRESH, navAtom } from '../../atoms/navAtom';
import { DebugCard } from '../../components/DebugCard';
import { FfDivider } from '../../components/FfDivider';
import { Loader } from '../../components/Loader';
import { TutorStudentAssignment } from '../../components/TutorStudentAssignment/TutorStudentAssignment';
import {
  AnyTutorAssignmentHasErrorAtom,
  SemesterIdForScheduleFilter,
  TutorAssignmentIsSavedAtom,
  appointmentsAtom,
} from '../../components/TutorStudentAssignment/useScheduleFormArray';
import { ShortFormatDate } from '../../components/Utils';
import { DistrictServiceAtoms } from '../../services/DistrictAtoms';
import { SchoolServiceAtoms } from '../../services/School';
import { StudentService } from '../../services/Student';
import { UserServiceAtoms } from '../../services/User';
import { delayMs } from '../../utils/utils';

export const TutorAssignmentScreen: React.FunctionComponent = () => {
  const [appointments, setUpdates] = useAtom(appointmentsAtom);

  const [isSaved, setIsSaved] = useAtom(TutorAssignmentIsSavedAtom);
  const hasError = useAtomValue(AnyTutorAssignmentHasErrorAtom);

  const [isPending, setIsPending] = React.useState(false);
  const [showDiscardMsg, setshowDiscardMsg] = React.useState(false);
  const [service] = useAtom(StudentService);
  const setNav = useUpdateAtom(navAtom);

  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const toast = useToast();
  const [schoolDistrict] = useAtom(DistrictServiceAtoms.getCurrentDistrict);
  const allUsers = useAtomValue(UserServiceAtoms.getSchoolUsers);
  const allExceptStaff = allUsers?.filter((u: UserDto) => u.role?.name !== 'Staff' && u.active) || [];
  const showSemesterSection = allExceptStaff && allExceptStaff.length > 0;

  const doValidateOnUpdates = (sta: PutStudentTutorAssignmentDto[]) => {
    const validatePutTutorAssignment = (pta: PutTutorAssignmentDto) => {
      const ptaErrors = validateSync(Object.assign(new PutTutorAssignmentDto(), pta));
      if (ptaErrors && ptaErrors.length) {
        alert(`validation failed! check your input: ${JSON.stringify(ptaErrors, null, 2)}`);
        throw new Error('validation failed');
      }
    };
    for (let index = 0; index < sta.length; index += 1) {
      const l = sta[index];
      // validate the outer object
      const pstaErrors = validateSync(Object.assign(new PutStudentTutorAssignmentDto(), l));
      if (pstaErrors && pstaErrors.length) {
        alert(`validation failed! check your input: ${JSON.stringify(pstaErrors, null, 2)}`);
        throw new Error('validation failed');
      }
      // validate all inner put tutor assignments
      l.tutorAssignments.forEach((pta) => validatePutTutorAssignment(pta));
    }
  };

  const doSaveUpdates = async (studentTutorAssignments: PutStudentTutorAssignmentDto[]) => {
    setIsPending(true);
    await Promise.all([
      service.putTutorAssignments(school?.id as number, studentTutorAssignments),
      delayMs(1000), // ensure spinner visibility
    ]);
    setUpdates(RESET);
    toast({ description: 'Tutor assignment saved', status: 'success' });
  };

  const Cancel = () => {
    setUpdates(RESET);
  };

  const initDistrictSemesters = () => {
    let groupedBySchoolYr: any = [];

    if (schoolDistrict?.semesters) {
      const semestersSorted = _.sortBy(schoolDistrict?.semesters, (s: IDistrictSemester) => s.startDate);
      const groupedByYear = _.groupBy(semestersSorted, 'startSchoolYear');
      const keys = Object.keys(groupedByYear);
      let yrval: number = 0;

      for (let i = 0; i < keys.length; i += 1) {
        yrval = +keys[i] + 1;

        groupedBySchoolYr.push({
          fall: groupedByYear[keys[i]][0],
          spring: groupedByYear[keys[i]][1],
          summer: groupedByYear[keys[i]][2],
          year: +keys[i],
          schoolYearLabel: `${keys[i]}-${yrval.toString().substring(2)}`,
        });
      }
      groupedBySchoolYr = groupedBySchoolYr.reverse();
    }

    return groupedBySchoolYr;
  };

  const initCurrentSemester = () => {
    const currentSemester: IDistrictSemester = _.filter(schoolDistrict?.semesters, (s: IDistrictSemester) => {
      const afterStart = new Date(s.startDate).toJSON().split('T')[0] <= new Date().toJSON().split('T')[0];
      const beforeEnd = new Date().toJSON().split('T')[0] <= new Date(s.endDate).toJSON().split('T')[0];
      return afterStart && beforeEnd;
    }).pop() as IDistrictSemester;

    return currentSemester;
  };

  const initSelectedSemesterId = () => {
    const d = initCurrentSemester();
    return d ? d.id : 0;
  };

  const [districtSemesters] = React.useState(initDistrictSemesters);
  const initSelectedSchoolYear = () => {
    const s = initCurrentSemester();
    const d = s && districtSemesters.find((d: any) => d.year === s.startSchoolYear);
    return d;
  };
  const [selectedSchoolYear, setselectedSchoolYear] = React.useState<any>(initSelectedSchoolYear);
  const [selectedSemesterId, setselectedSemesterId] = React.useState<any>(initSelectedSemesterId);
  const [semesterIdFilter, setsemesterIdFilter] = useAtom(SemesterIdForScheduleFilter);

  const onChangeSchoolYear = (evt: any) => {
    if (!isSaved) {
      toast({ description: 'Please Save or Cancel your changes', status: 'error' });
    } else {
      const sy = districtSemesters.find((d: any) => d.year.toString() === evt.target.value);
      setselectedSchoolYear(sy);
      setsemesterIdFilter(sy.fall.id);
      // console.log('onChangeSchoolYear ', evt.target.value);
      // console.log('isSaved ', isSaved);
    }
  };

  const onChangeSemesters = (evt: any) => {
    if (!isSaved) {
      toast({ description: 'Please Save or Cancel your changes', status: 'error' });
    } else {
      setselectedSemesterId(evt.target.value);
      setsemesterIdFilter(evt.target.value);
      // console.log('onChangeSemesters ', evt.target.value);
      // console.log('isSaved ', isSaved);
    }
  };

  const saveUserUpdates = async () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const _ = await Promise.resolve(1);
    if (hasError) {
      // we won't reject here
      return;
    }

    try {
      const studentTutorAssignments: PutStudentTutorAssignmentDto[] = [];
      Object.keys(appointments).forEach((studentId) => {
        const psta = new PutStudentTutorAssignmentDto();
        psta.studentId = Number(studentId);
        psta.tutorAssignments = appointments[studentId].map((a) => {
          const pta = new PutTutorAssignmentDto();
          pta.tutorId = a.tutorId as number;
          pta.districtSemesterId = +semesterIdFilter;
          pta.dayOfWeek = a.dayOfWeek as number;
          pta.startTime = a.startTime as string;
          pta.endTime = a.endTime as string;
          return pta;
        });
        studentTutorAssignments.push(psta);
      });
      doValidateOnUpdates(studentTutorAssignments);
      await doSaveUpdates(studentTutorAssignments);
      setIsSaved(true);
      setNav(REFRESH);
    } catch (error) {
      // likely a validation error
      // eslint-disable-next-line no-console
      console.error(error);
    }
    setIsPending(false);
  };

  useEffect(() => {
    // console.log('curSemester ',curSemester.id);

    setsemesterIdFilter(selectedSemesterId);
  }, []);

  return (
    <Container maxWidth="100%">
      <Flex minWidth="max-content" alignItems="center" gap="2">
        <Box p="0">
          <Heading color={'ff.blue'}>Assign Tutors</Heading>
          <Box>Drag and drop tutors into student schedules</Box>
        </Box>
        <Spacer />
        <ButtonGroup gap="1">
          <Button onClick={Cancel}>Cancel</Button>
          <Button disabled={isSaved} isLoading={isPending} onClick={saveUserUpdates} variant={'cta'}>
            Save
          </Button>
        </ButtonGroup>
      </Flex>
      {showSemesterSection && (
        <Flex>
          <Box p="0" marginTop="30px" marginBottom="30px" width={'100%'}>
            <Box as="b">SEMESTER</Box>
            <Flex marginTop="30px" marginBottom="30px">
              <Box width={'15%'}>
                <Box as="b">School Year</Box>
                {districtSemesters && (
                  <Select marginTop="10px" value={selectedSchoolYear && selectedSchoolYear.year} onChange={onChangeSchoolYear}>
                    {districtSemesters.map((o: any, i: any) => (
                      <option value={o.year} key={i}>
                        {o.schoolYearLabel}
                      </option>
                    ))}
                  </Select>
                )}
              </Box>
              <Box width={'5%'}></Box>
              <Box>
                <Box as="b">Select Semester to Schedule</Box>

                <Select marginTop="10px" value={selectedSemesterId} onChange={onChangeSemesters}>
                  {selectedSchoolYear &&
                    Object.keys(selectedSchoolYear).map(
                      (k: any, i: any) =>
                        (k === 'fall' || k === 'spring' || k === 'summer') && (
                          <option value={selectedSchoolYear[k].id} key={i}>
                            {selectedSchoolYear[k].name} ({ShortFormatDate(selectedSchoolYear[k].startDate)} -{' '}
                            {ShortFormatDate(selectedSchoolYear[k].endDate)})
                          </option>
                        ),
                    )}
                </Select>
              </Box>
            </Flex>
          </Box>
        </Flex>
      )}
      <FfDivider />
      <Suspense fallback={<Loader />}>
        <TutorStudentAssignment schoolId={school?.id as number} />
        <DebugCard data={[{ name: 'school', data: school }]} />
      </Suspense>
    </Container>
  );
};

import { atom, Getter } from 'jotai';
import { atomWithStorage, createJSONStorage } from 'jotai/utils';
import _ from 'lodash';

class NavParams {
  // if not set, derive from schoolId
  districtId: number = 0;

  schoolId: number = 0;

  tutorId: number = 0;

  staffId: number = 0;

  lessonPlanId: number = 0;

  userId: number = 0;

  studentId: number = 0;

  date: string = '';

  weekOrdinal: number = 0;

  refreshCount: number = 0;

  familyEventId: number = 0;

  familyInteractionId: number = 0;

  timezone: string;

  oneSemester: boolean;
}

export const navAtomInternal = atom<IParams>({});

export const REFRESH: unique symbol = Symbol('REFRESH');

export const persistNavAtom = atomWithStorage<IParams>(
  'url',
  {},
  createJSONStorage(() => sessionStorage),
);

/**
 *  Calling an update with REFRESH will cause all atoms using nav to be reloaded the next time they are used.
 * */
export const navAtom = atom(
  (get) => get(navAtomInternal),
  (get, set, update: IParams | typeof REFRESH) => {
    if (update !== REFRESH) {
      set(persistNavAtom, { ..._.pick(get(persistNavAtom), ['schoolId', 'districtId', 'timezone']), ...update });
      set(navAtomInternal, update);
      return;
    }
    const nav = get(navAtomInternal);
    nav.refreshCount = (nav.refreshCount ?? 0) + 1;
    set(navAtomInternal, nav);
  },
);
navAtom.debugLabel = 'navAtom';

export type IParams = Partial<NavParams>;
export function persistNavPropsAtom<Value>(read: (get: Getter, nav: IParams) => Promise<Value>) {
  const asyncAtom = atom(async (get) => {
    const nav = get(persistNavAtom);
    return read(get, nav);
  });
  return asyncAtom;
}

import {
  AttendanceStatusTypes,
  LessonStatusTypes,
  PatchLessonPlanDto,
  PostSchoolCalendarDto,
  SessionLessonPlanDto,
} from '@edanalytics/ff_be_se';
import { atom } from 'jotai';
import { RESET } from 'jotai/utils';
import _ from 'lodash';
import { DateTime as DT } from 'luxon';
import { LessonPlanService, LessonPlanServiceAtoms } from '../../../services/LessonPlan/LessonPlanService';
import { SchoolCalendarService, SchoolCalendarServiceAtoms } from '../../../services/SchoolCalendar';
import { StudentServiceAtoms } from '../../../services/Student';
import { atomApiWithNavAndUpdate, atomApiWithRead } from '../../../utils/async-atom';
import { AttendanceMonthData } from '../AttendanceRows/AttendanceRecordRowProps';
import {
  AttendanceInfo,
  ScheduledAbsenceInfo,
  UpdateAttendanceInfo,
  isDeleteAttendanceInfo,
  isScheduledAbsenceInfo,
  isUpdateAttendanceInfo,
  toDeleteAttendanceInfo,
} from '../Utility';
import { Enrollment } from './AttendanceMapping';
import { getSchoolAttendancePerMonth } from './AttendanceByStudentAtoms';
import { SchoolServiceAtoms } from '../../../services/School';

export const attendanceEditAtom = atom<boolean>(false);

export const dayOfMonthEditAtom = atom<string | undefined>(undefined);

export interface HashedAttendanceInfo extends SessionLessonPlanDto {
  // hash: string;
}
export const groupedAttendanceAtom = atom<{ [key: string]: AttendanceMonthData }>({});

export const patchSessionAtom = atomApiWithNavAndUpdate(
  () => Promise.resolve(),
  async (get, set, { id, attendanceStatus, studentId }: UpdateAttendanceInfo, nav) => {
    if (!nav.schoolId) return Promise.resolve();
    await get(LessonPlanService).patchLessonPlan(nav.schoolId, studentId, id, {
      id,
      attendanceStatus,
    } as PatchLessonPlanDto);
    return Promise.resolve();
  },
);

export const postScheduledAbsence = atomApiWithNavAndUpdate<ScheduledAbsenceInfo | void>(
  (get) => Promise.resolve(undefined),
  async (get, set, update, nav) => {
    const school = get(SchoolServiceAtoms.getCurrentSchool);
    if (!update) throw new Error('update is required');
    if (!nav.schoolId || !school) throw new Error('schoolId is required');
    if (!update.tutorId) throw new Error('tutorId is required');

    const startDateTime = DT.fromISO(update.date, { zone: school.timezone }).startOf('day').plus({ hours: 6 }).toISO();
    const endDateTime = DT.fromISO(update.date, { zone: school.timezone }).endOf('day').minus({ hours: 4 }).toISO();
    const weekDay = DT.fromISO(update.date).toISOWeekDate();
    const weekOrdinal = undefined;
    const lessonStatus = LessonStatusTypes.ScheduledAbsence;
    const byUserId = update.tutorId;

    const { studentId, attendanceStatus, lessonPlanType } = update;

    const result = await get(LessonPlanService).postLessonPlan(nav.schoolId, studentId, {
      studentId,
      startDateTime,
      endDateTime,
      weekDay,
      weekOrdinal,
      lessonStatus,
      byUserId,
      attendanceStatus,
      lessonPlanType,
    });

    return result as Omit<ScheduledAbsenceInfo, 'date' | 'tutorId'> as any;
  },
);

export const updateScheduleAtom = atomApiWithNavAndUpdate<Partial<AttendanceInfo> | string>(
  (get) => Promise.resolve(''),
  async (get, set, update, nav) => {
    let result = 'Ok';
    // attendanceEditAtom is used to prevent multiple updates
    try {
      if (typeof update !== 'string' && 'lessonPlanId' in update) (update as any).id = update.lessonPlanId;

      if (isUpdateAttendanceInfo(update)) {
        set(attendanceEditAtom, false);
        await set(patchSessionAtom, update);
      } else if (isScheduledAbsenceInfo(update)) {
        set(attendanceEditAtom, false);
        await set(postScheduledAbsence, update);
      } else if (isDeleteAttendanceInfo(update)) {
        const deleted = toDeleteAttendanceInfo(update);
        if (deleted && nav.schoolId) {
          set(attendanceEditAtom, false);
          await get(LessonPlanService).deleteLessonPlan(nav.schoolId, deleted.studentId, deleted.id);
        }
      } else {
        result = 'Missing data when updating schedule';
      }
    } catch (error) {
      console.error(error);
    } finally {
      result = 'Error updating schedule';
      if (!nav.studentId) {
        set(SchoolCalendarServiceAtoms.schoolCalendar, RESET);
        set(getSchoolAttendancePerMonth, RESET);
      } else {
        set(LessonPlanServiceAtoms.getStudentAttendance, RESET);
      }
      set(attendanceEditAtom, true);
    }

    return result;
  },
);

export const updateScheduleAllStudents = atomApiWithNavAndUpdate<Partial<AttendanceInfo> | string>(
  (get) => Promise.resolve(''),
  async (get, set, update, nav) => {
    if (!nav.schoolId) return Promise.resolve('Missing schoolId');
    if (typeof update === 'string') return Promise.resolve(update);
    const result = 'Ok';

    if (!update.date) return Promise.resolve('Missing date');

    const postEventData = {
      eventDate: new Date(update.date),
      attendanceStatus: update.attendanceStatus,
      schoolId: nav.schoolId,
      id: update.calendarId,
    } as {
      eventDate: Date;
      attendanceStatus?: AttendanceStatusTypes;
      schoolId: number;
      id?: number;
    };

    try {
      set(attendanceEditAtom, false);
      if (postEventData.id && postEventData.attendanceStatus) {
        await get(SchoolCalendarService).putSchoolCalendarEvent(nav.schoolId, postEventData.id, postEventData);
      } else if (postEventData.attendanceStatus) {
        await get(SchoolCalendarService).postSchoolCalendarEvent(nav.schoolId, postEventData as PostSchoolCalendarDto);
      } else if (postEventData.id) {
        await get(SchoolCalendarService).deleteSchoolCalendarEvent(nav.schoolId, postEventData.id);
      }
    } catch (error) {
      console.error(error);
    } finally {
      set(SchoolCalendarServiceAtoms.schoolCalendar, RESET);
      set(getSchoolAttendancePerMonth, RESET);
      set(attendanceEditAtom, true);
    }

    return result;
  },
);

export const schoolEnrollmentsByStudentAtom = atomApiWithRead<{
  [key: string]: Enrollment[];
}>(async (get) => {
  const studentList = get(StudentServiceAtoms.getCurrentStudents); // WIP all enrollments not just current kids

  const studentEnrollments = _.mapValues(
    _.groupBy(studentList?.filter((s) => s.enrollments.length > 0) ?? [], (l) => l.id),
    (l) =>
      l.flatMap(({ enrollments, ...s }) => {
        const student = {
          ...s,
        };
        return enrollments.map(({ entryDate, exitDate, ...rest }) => ({
          ...rest,
          schoolId: s.schoolId,
          student,
          entryDate:
            typeof entryDate === 'string' ? (entryDate as string).substring(0, 10) : entryDate?.toISOString().substring(0, 10) ?? '',
          exitDate: typeof exitDate === 'string' ? (exitDate as string).substring(0, 10) : exitDate?.toISOString().substring(0, 10) ?? '',
        }));
      }),
  );

  return Promise.resolve(studentEnrollments);
});

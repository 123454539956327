import { Select, FormControl, FormLabel } from '@chakra-ui/react';
import { useAtom, atom, useAtomValue } from 'jotai';
import { DateTime } from 'luxon';
import { districtMonthsAtom } from './districtMonthsAtom';

export const selectedMonthAtom = atom(DateTime.local().startOf('month').toISODate().substring(0, 7));
export const AttendanceMonthSelect: React.FunctionComponent = () => {
  const options = useAtomValue(districtMonthsAtom);
  const [selectedMonth, setSelectedMonth] = useAtom(selectedMonthAtom);

  return (
    <FormControl id="month">
      <FormLabel>Month</FormLabel>
      <Select
        value={selectedMonth}
        placeholder={'Select...'}
        onChange={(e: any) => {
          setSelectedMonth(e.target.value);
        }}
      >
        {options.map((option) => (
          <option value={option.val} key={option.val}>
            {option.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

import { Box, Button, Container, Flex, Heading, Link, Select, useToast } from '@chakra-ui/react';
import { AttendanceStatusTypes, LessonStatusTypes, PutSessionLessonPlanDto, SessionLessonPlanDto } from '@edanalytics/ff_be_se';
import { atom, useAtom } from 'jotai';
import { RESET, useUpdateAtom } from 'jotai/utils';
import _, { omit } from 'lodash';
import React, { RefObject, useEffect } from 'react';

import { SetAtom } from 'jotai/core/atom';
import { useNavigate } from 'react-router-dom';
import { persistNavAtom } from '../../atoms/navAtom';
import { ConfirmAlert } from '../../components/ConfirmationDialog';
import { DebugCard } from '../../components/DebugCard';
import { Empty } from '../../components/Empty';
import { FfAlertDialog } from '../../components/FfAlertDialog';
import { FfDivider } from '../../components/FfDivider';
import { FrequentlyUsedList } from '../../components/FrequentlyUsedList';
import { LessonPlanContent } from '../../components/LessonPlan/LessonPlanContent';
import { LessonPlanForm } from '../../components/LessonPlan/LessonPlanForm';
import { LessonPlanRescheduleDialog } from '../../components/LessonPlan/LessonPlanRescheduleDialog';
import { lessonPlanCopyDialogAtom as copyDialogAtom } from '../../components/LessonPlan/LessonPlanResheduleAtoms';
import { DialogStages } from '../../components/LessonPlan/LessonPlanTypes';
import { lessonPlanTypeOptions } from '../../components/LessonPlanContent/LessonPlanUtils';
import { constructHighlightsJson } from '../../components/LessonPlanContent/models/Highlights';
import { getLessonPlanTypeString, LessonPlanTypes } from '../../components/LessonPlanContent/models/LessonPlanTypeEnum';
import { getModuleSet } from '../../components/LessonPlanContent/models/Modules';
import { isAbsenceLessonPlan, isEligibleForMakeUp, MakeUpLessonPlanLink } from '../../components/Utils';
import { userInfoAtom } from '../../ffApi';
import { LessonPlanService, LessonPlanServiceAtoms } from '../../services/LessonPlan/LessonPlanService';
import { SchoolServiceAtoms } from '../../services/School';
import { StudentServiceAtoms } from '../../services/Student';
import { UserServiceAtoms } from '../../services/User';
import { atomApiWithNavAndRead } from '../../utils/async-atom';
import { delayMs, isEmpty } from '../../utils/utils';

interface LessonPlanTypeChangeProps {
  type: LessonPlanTypes;
  onChange: (newType: LessonPlanTypes) => void;
}

const LessonPlanTypeChange = (props: LessonPlanTypeChangeProps) => {
  const [newType, setNewType] = React.useState<LessonPlanTypes>(props.type || LessonPlanTypes.EarlyReader);
  const allTypeOptions = lessonPlanTypeOptions;
  const [displayAlert, setDisplayAlert] = React.useState(false);
  const toggleAlert = () => {
    setDisplayAlert(!displayAlert);
  };
  const onChange = () => {
    toggleAlert();
    props.onChange(newType);
  };
  return (
    <>
      <FfAlertDialog
        open={displayAlert}
        onClose={() => toggleAlert()}
        header="Change Lesson Plan Type"
        body={
          <>
            <Box mb="0.5em">Choose from the options below:</Box>
            <Box>
              <Select
                paddingRight="5px"
                name=""
                value={newType}
                onChange={(e) => {
                  const option = allTypeOptions[e.target.selectedIndex];
                  setNewType(option.value);
                }}
              >
                {lessonPlanTypeOptions.map((t, i) => (
                  <option key={i} value={t.value}>
                    {t.key}
                  </option>
                ))}
              </Select>
            </Box>
            <Box marginTop="1em" fontSize="sm" color="red">
              WARNING: All previous lesson plan content will be lost.
            </Box>
          </>
        }
        footer={
          <>
            <Button onClick={toggleAlert}>Cancel</Button>
            <Button colorScheme="red" onClick={() => onChange()} ml={3}>
              Save
            </Button>
          </>
        }
      />
      <Link onClick={() => toggleAlert()}>Change Lesson Plan Type</Link>
    </>
  );
};

const deleteLessonPlanAtom = atom(null, async (get, set, newValue: { schoolId: number; studentId: number; lessonPlanId: number }) => {
  await get(LessonPlanService).deleteLessonPlan(newValue.schoolId, newValue.studentId, newValue.lessonPlanId);
  set(LessonPlanServiceAtoms.getScheduledSchoolLessonPlans, RESET);
  set(LessonPlanServiceAtoms.getTutorLessonPlans, RESET);
});

export const LessonPlanDetailScreen: React.FunctionComponent = () => {
  const [copyDialogState, setCopyDialogState] = useAtom(copyDialogAtom);
  const [navAtomProps] = useAtom(persistNavAtom);
  const [userInfo] = useAtom(userInfoAtom);
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const [lessonPlan, setLessonPlan]: [SessionLessonPlanDto | undefined, SetAtom<PutSessionLessonPlanDto | typeof RESET, Promise<void>>] =
    useAtom(LessonPlanServiceAtoms.getUpdateLessonPlan);
  const [students] = useAtom(StudentServiceAtoms.getCurrentStudents);
  const [users] = useAtom(UserServiceAtoms.getSchoolUsers);
  const [isPending, setIsPending] = React.useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [isMakeupModalOpen, setIsMakeupModalOpen] = React.useState(false);
  const [isAbsentSelected, setIsAbsentSelected] = React.useState(false);
  const [isAbsentConfirmModalOpen, setIsAbsentConfirmModalOpen] = React.useState(false);
  const [putSessionLessonPlanDto, setPutSessionLessonPlanDto] = React.useState(new PutSessionLessonPlanDto());
  const [formRefs, setFormRefs] = React.useState<RefObject<any>[]>([]);
  const [headerFormRef, setHeaderFormRef] = React.useState<RefObject<any> | undefined>(undefined);
  const nav = useNavigate();
  const toast = useToast();
  // INITIALIZE modules definition,  forms and RESET atom
  const moduleCount = lessonPlan?.lessonPlanModules?.length || 0;

  useEffect(() => {
    if (lessonPlan && !lessonPlan?.isMakeUp && isEligibleForMakeUp(lessonPlan)) {
      setIsMakeupModalOpen(true);
    }
  }, []);

  useEffect(() => {
    // Add or remove refs which will point to the Formik forms for each module
    if (moduleCount === 0) return;
    setFormRefs((refs) =>
      Array(moduleCount)
        .fill(null)
        .map((_val, i) => refs[i] || React.createRef()),
    );
    setHeaderFormRef(headerFormRef ?? React.createRef());
  }, [moduleCount]);

  useEffect(() => {
    setLessonPlan(RESET);
    return () => {
      setLessonPlan(RESET);
    };
  }, []);

  const sortedStudents = _.sortBy(students, [(s) => s.lastName], (s) => s.firstName);
  const sortedUsers = _.sortBy(users, [(u) => u.lastName], (u) => u.firstName);

  // MODULES update operations
  const updateModule = (values: any) =>
    _.sortBy(lessonPlan?.lessonPlanModules, (m) => m.lessonContentCode)?.map((module) =>
      module.json.moduleKey === values.moduleKey ? { ...module, json: values } : module,
    );

  const updateAllModules = () =>
    _.sortBy(lessonPlan?.lessonPlanModules, (m) => m.lessonContentCode)?.map((module, i) =>
      module.json.moduleKey === formRefs[i]?.current?.values.moduleKey ? { ...module, json: formRefs[i]?.current?.values } : module,
    );

  // LESSON update operations
  const updateLessonPlanOnly = (newType: number) => {
    if (lessonPlan?.lessonPlanType === newType) return undefined;

    return {
      ...lessonPlan,
      lessonPlanType: newType,
      lessonPlanModules: getModuleSet(LessonPlanTypes[newType]),
    } as PutSessionLessonPlanDto;
  };

  const updateSingleFrom = (values: any) => {
    if (!lessonPlan || !values) return undefined;
    const lessonPlanModules = updateModule(values);

    return {
      ...lessonPlan,
      studentId: lessonPlan?.student?.id,
      lessonPlanModules,
      highlights: lessonPlanModules ? constructHighlightsJson(lessonPlanModules) : null,
    } as PutSessionLessonPlanDto;
  };

  const updateFullFormTopButton = (values: any) => {
    const lessonPlanModules = updateAllModules();
    return {
      ...lessonPlan,
      ...values,
      lessonPlanModules,
      highlights: lessonPlanModules ? constructHighlightsJson(lessonPlanModules) : null,
    } as PutSessionLessonPlanDto;
  };

  const handleIsAbesentSelected = (selection: any) => {
    if (
      selection.value === AttendanceStatusTypes.EventAbsence ||
      selection.value === AttendanceStatusTypes.StudentAbsence ||
      selection.value === AttendanceStatusTypes.TutorAbsence ||
      selection.value === AttendanceStatusTypes.Holiday
    ) {
      setIsAbsentSelected(true);
    } else setIsAbsentSelected(false);
  };

  const updateFullFormButton = () => updateFullFormTopButton(omit(headerFormRef?.current.values, 'lessonPlanModules'));

  const updateFinalSubmission = () => {
    if (!headerFormRef?.current.values) {
      toast({ description: 'Error reading form' });
      return undefined;
    }
    if (headerFormRef?.current.values?.attendanceStatus === AttendanceStatusTypes.Scheduled) {
      toast({ description: 'Student must attend to submit Lesson Plan' });
      return undefined;
    }

    return {
      ...updateFullFormButton(),
      submittedUserId: userInfo?.id,
    } as PutSessionLessonPlanDto;
  };

  // EVENT HANDLERS
  const onSave = async (update: PutSessionLessonPlanDto | undefined) => {
    if (!update) return; // Possible error messaging;
    if (isAbsenceLessonPlan(update)) {
      setPutSessionLessonPlanDto(update);
      setIsAbsentConfirmModalOpen(true);
      return;
    }

    setIsPending(true);
    await setLessonPlan(update);
    await delayMs(1000); // for loading visibility
    setIsPending(false);

    toast({ description: 'Lesson plan updated', status: 'success' });
  };

  const onSaveAbsentStatus = async () => {
    setIsPending(true);
    await setLessonPlan(putSessionLessonPlanDto);
    await delayMs(1000); // for loading visibility
    setIsPending(false);
    setIsAbsentConfirmModalOpen(false);
    toast({ description: 'Lesson plan updated', status: 'success' });
  };

  const setDeleteModalState = (state: boolean) => {
    setIsDeleteModalOpen(state);
  };

  const deleteLessonPlan = useUpdateAtom(deleteLessonPlanAtom);

  const performDelete = async () => {
    await deleteLessonPlan({
      schoolId: school?.id as number,
      studentId: lessonPlan?.studentId as number,
      lessonPlanId: lessonPlan?.id as number,
    });
    toast({ description: 'Lesson plan deleted', status: 'success' });
    setIsDeleteModalOpen(false);
    nav(`/schools/${school?.id}/dashboard`);
  };

  useEffect(() => {
    setIsPending(false);
  }, [lessonPlan]);

  return (
    <Container maxWidth="100%" fontSize="12px">
      <Flex>
        <Box flex={1}>
          <Heading color={'ff.blue'} display="inline" paddingRight="5px">
            Lesson Plan
            {lessonPlan?.lessonPlanType ? ` - ${getLessonPlanTypeString(lessonPlan?.lessonPlanType)}` : ''}
            {lessonPlan?.isMakeUp && (
              <Box display={'inline'} ml={'1em'}>
                ({MakeUpLessonPlanLink(lessonPlan)})
              </Box>
            )}
          </Heading>

          {lessonPlan?.lessonStatus !== LessonStatusTypes.Submitted && (
            <LessonPlanTypeChange
              type={lessonPlan?.lessonPlanType as number}
              onChange={(newType) => onSave(updateLessonPlanOnly(newType))}
            />
          )}
        </Box>

        <Box textAlign="right" flex={0}>
          <FrequentlyUsedList
            items={[
              {
                path: `/schools/${school?.id}/lesson-plans/`,
                name: 'See All Past Lesson Plans',
              },
            ]}
          />
          <ConfirmAlert
            active={isDeleteModalOpen}
            headerText="Delete Lesson Plan"
            bodyText="Are you sure? You can't undo this action afterwards."
            okHandle={performDelete}
            cancelHandle={() => setIsDeleteModalOpen(false)}
            continueText="Delete"
          />
          <ConfirmAlert
            active={isAbsentConfirmModalOpen}
            headerText="This Lesson Plan will be marked as Absent"
            bodyText="<p>You will not be able to make further changes. You will be able to create a Make-up Lesson Plan.</p><p>Are you sure?</p>"
            okHandle={onSaveAbsentStatus}
            cancelHandle={() => setIsAbsentConfirmModalOpen(false)}
            continueText="Confirm"
          />
        </Box>
      </Flex>

      <FfDivider />
      {lessonPlan && students ? (
        <Box>
          <Box mb="3em">
            <LessonPlanForm
              lessonPlan={lessonPlan}
              students={sortedStudents}
              users={sortedUsers}
              isLoading={isPending}
              isSaveEnabled={true}
              formRef={headerFormRef}
              onSave={(values) => onSave(updateFullFormTopButton(omit(values, 'lessonPlanModules')))}
              onAttendanceSelect={handleIsAbesentSelected}
              isAbsentSelected={isAbsentSelected}
              isAttendanceSelectorShown={true}
              openMakeUpLessonDialog={() => setIsMakeupModalOpen(true)}
            />
          </Box>
          {!isEmpty(lessonPlan.lessonPlanModules) ? (
            <LessonPlanContent
              lessonPlan={lessonPlan}
              isSubmitEnabled={true}
              isSaving={isPending}
              isSubmitting={isPending}
              onSave={(form) => onSave(updateSingleFrom(form))}
              onSubmit={() => onSave(updateFinalSubmission())}
              onSaveAll={() => onSave(updateFullFormButton())}
              onChange={() => {}}
              formRefs={formRefs}
              isAbsentSelected={isAbsentSelected}
              openDeleteModal={setDeleteModalState}
            />
          ) : (
            <Empty description="No Lesson Plan content. Please use Change link above." />
          )}
        </Box>
      ) : (
        <Empty description="Lesson Plan not found." />
      )}
      <DebugCard
        data={[
          { name: 'userInfo', data: userInfo },
          { name: 'lessonPlan', data: lessonPlan },
          { name: 'navAtomProps', data: navAtomProps },
          { name: 'users', data: users },
        ]}
      />
      <LessonPlanRescheduleDialog />
    </Container>
  );
};

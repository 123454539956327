import { PostSchoolCalendarDto, PutSchoolCalendarDto, SchoolCalendarDto } from '@edanalytics/ff_be_se';
import { Getter, atom } from 'jotai';
import _ from 'lodash';
import { apiClientAtom } from '../ffApi';
import { atomApiWithNavAndRead } from '../utils/async-atom';

const schoolCalendarServiceFactory = (get: Getter) => ({
  getSchoolCalendar: async (schoolId: number, calendarId: number): Promise<SchoolCalendarDto[]> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/calendar/${calendarId}`);
    return response.data.results as SchoolCalendarDto[];
  },
  getSchoolCalendarEvents: async (schoolId: number): Promise<SchoolCalendarDto[]> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/calendar`);
    return response.data.results as SchoolCalendarDto[];
  },
  postSchoolCalendarEvent: async (schoolId: number, createDto: PostSchoolCalendarDto): Promise<SchoolCalendarDto> => {
    const response = await get(apiClientAtom).post(`/schools/${schoolId}/calendar`, createDto);
    return response.data as SchoolCalendarDto;
  },
  putSchoolCalendarEvent: async (schoolId: number, eventId: number, updateDto: PutSchoolCalendarDto): Promise<SchoolCalendarDto> => {
    const response = await get(apiClientAtom).put(`/schools/${schoolId}/calendar/${eventId}`, updateDto);
    return response.data as SchoolCalendarDto;
  },
  deleteSchoolCalendarEvent: async (schoolId: number, eventId: number): Promise<void> => {
    await get(apiClientAtom).delete(`/schools/${schoolId}/calendar/${eventId}`);
  },
});

const SchoolCalendarService = atom(schoolCalendarServiceFactory);
export { SchoolCalendarService };

export const SchoolCalendarServiceAtoms = {
  schoolCalendar: atomApiWithNavAndRead(async (get, nav) => {
    if (!nav.schoolId) throw new Error('schoolId is required');

    const events = await schoolCalendarServiceFactory(get).getSchoolCalendarEvents(nav.schoolId);
    const eventsByDay = _.mapValues(
      _.groupBy(events, (e) => e.eventDate),
      (v) => v[0],
    );
    return eventsByDay;
  }),
};

/* eslint-disable no-alert */
import { Box, Container, Flex, Heading, useToast, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { PostSessionLessonPlanDto, SessionLessonPlanDto, UserDto } from '@edanalytics/ff_be_se';
import { RESET } from 'jotai/utils';
import { DateTime as DT } from 'luxon';
import { DebugCard } from '../../components/DebugCard';
import { LessonPlanService } from '../../services/LessonPlan/LessonPlanService';
import { FfDivider } from '../../components/FfDivider';
import { LessonPlanForm } from '../../components/LessonPlan/LessonPlanForm';
import { Empty } from '../../components/Empty';
import { StudentServiceAtoms } from '../../services/Student';
import { UserServiceAtoms } from '../../services/User';
import { persistNavAtom } from '../../atoms/navAtom';
import { LessonPlanTypes } from '../../components/LessonPlanContent/models/LessonPlanTypeEnum';
import { SchoolService, SchoolServiceAtoms } from '../../services/School';
import { FrequentlyUsedList } from '../../components/FrequentlyUsedList';
import { atomApiWithNavAndUpdate } from '../../utils/async-atom';
import { getModuleSet } from '../../components/LessonPlanContent/models/Modules';
import { JStoDateTime } from '../../utils/TimeHelper';
import { ObserveTutorFormHeader } from '../IC/ObserveTutorFormScreen';

interface SaveValues {
  studentId: number;
  lessonPlanType: number;
  attendanceStatus: number;
  byUserId: number;
  startDateTime: string;
}

const createSessionAtom = atomApiWithNavAndUpdate(
  // Read scheduled session
  async (get, nav) => {
    if (!nav?.date || !nav?.weekOrdinal || !nav?.schoolId || !nav?.studentId) return Promise.resolve(undefined);

    const schoolData = await get(SchoolService).getSchool(nav.districtId || 1, nav.schoolId);
    const utcDateTime = JStoDateTime(new Date(nav.date), schoolData.timezone);
    const start = utcDateTime.startOf('day').toJSDate();
    const end = utcDateTime.endOf('day').toJSDate();
    return get(LessonPlanService)
      .getAllForStudent(nav.schoolId, nav.studentId, { start, end })
      .then((rows) => rows.find((r) => r.weekOrdinal === nav.weekOrdinal));
  },

  // Post to create session
  (get, set, saveValues: SaveValues, nav) =>
    get(LessonPlanService).postLessonPlan(nav?.schoolId!, saveValues.studentId, {
      ...saveValues,
      lessonPlanModules: saveValues.lessonPlanType ? getModuleSet(LessonPlanTypes[saveValues.lessonPlanType]) : [],
    } as PostSessionLessonPlanDto),
);

const GenericStartForm: React.FunctionComponent<{ forwardToObservations: boolean }> = ({ forwardToObservations }) => {
  const nav = useNavigate();
  const [lessonPlan, postLessonPlan] = useAtom(createSessionAtom);
  const users = useAtomValue(UserServiceAtoms.getSchoolUsers);
  const students = useAtomValue(StudentServiceAtoms.getCurrentStudents);

  const [navAtomProps] = useAtom(persistNavAtom);
  // const activeStudents = students?.filter((s) => s.active === true);
  const sortedStudents = _.sortBy(students, [(s) => s.lastName], (s) => s.firstName);
  const sortedUsers = _.sortBy(users, [(u) => u.lastName], (u) => u.firstName);
  const toast = useToast();
  // Once we have an id we redirect

  useEffect(() => {
    if (lessonPlan?.id) {
      const link = forwardToObservations
        ? `/schools/${navAtomProps.schoolId}/tutors/${lessonPlan.byUserId}/observe/${lessonPlan.id}`
        : `/schools/${navAtomProps.schoolId}/students/${lessonPlan.studentId}/lesson-plans/${lessonPlan.id}`;
      nav(link, { replace: true });
      postLessonPlan(RESET);
      toast({ description: 'Lesson plan created', status: 'success' });
    }
  }, [lessonPlan]);
  return (
    <>
      {students && users && lessonPlan ? (
        <LessonPlanForm
          lessonPlan={lessonPlan}
          students={sortedStudents}
          users={sortedUsers}
          isLoading={false}
          isSaveEnabled={true}
          onSave={postLessonPlan}
          isAttendanceSelectorShown={false}
        />
      ) : (
        <Empty description="No lesson plan." />
      )}
      <DebugCard
        data={[
          { name: 'lessonPlan', data: lessonPlan },
          { name: 'users', data: users },
          { name: 'students', data: students },
        ]}
      />
    </>
  );
};

export const ObservationCreateScreen: React.FunctionComponent = () => {
  const lessonPlan = useAtomValue(createSessionAtom);

  return (
    <Container maxWidth="100%">
      <ObserveTutorFormHeader tutor={lessonPlan?.byUser} message={'Before observing, please start a lesson plan for this Tutor.'} />
      <GenericStartForm forwardToObservations={true}></GenericStartForm>
    </Container>
  );
};

export const LessonPlanCreateScreen: React.FunctionComponent = () => {
  const nav = useNavigate();
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const [navAtomProps] = useAtom(persistNavAtom);
  const [lessonPlan, postLessonPlan] = useAtom(createSessionAtom);
  const [students] = useAtom(StudentServiceAtoms.getCurrentStudents);
  const [users] = useAtom(UserServiceAtoms.getSchoolUsers);
  // const activeStudents = students?.filter((s) => s.active === true);
  const sortedStudents = _.sortBy(students, [(s) => s.lastName], (s) => s.firstName);
  const sortedUsers = _.sortBy(users, [(u) => u.lastName], (u) => u.firstName);
  const toast = useToast();
  // Once we have an id we redirect
  useEffect(() => {
    if (lessonPlan?.id) {
      nav(`/schools/${navAtomProps.schoolId}/students/${lessonPlan.studentId}/lesson-plans/${lessonPlan.id}`, { replace: true });
      postLessonPlan(RESET);
      toast({ description: 'Lesson plan created', status: 'success' });
    }
  }, [lessonPlan]);

  return (
    <Container maxWidth="100%" fontSize="12px">
      <Flex>
        <Box flex={1}>
          <Heading color={'ff.blue'}>Create Lesson Plan</Heading>
        </Box>
        <Box textAlign="right" flex={0}>
          <FrequentlyUsedList
            items={[
              {
                path: `/schools/${school?.id}/lesson-plans/`,
                name: 'See All Past Lesson Plans',
              },
            ]}
          />
        </Box>
      </Flex>
      <FfDivider />
      {students && users && lessonPlan ? (
        <LessonPlanForm
          lessonPlan={lessonPlan}
          students={sortedStudents}
          users={sortedUsers}
          isLoading={false}
          isSaveEnabled={true}
          onSave={postLessonPlan}
          isAttendanceSelectorShown={false}
        />
      ) : (
        <Empty description="No lesson plan." />
      )}
      <DebugCard
        data={[
          { name: 'lessonPlan', data: lessonPlan },
          { name: 'users', data: users },
          { name: 'students', data: students },
        ]}
      />
    </Container>
  );
};

/* eslint-disable spaced-comment */
/* eslint-disable no-nested-ternary */
import { Box, Center, Flex } from '@chakra-ui/react';
import { AttendanceStatusTypes, LessonStatusTypes } from '@edanalytics/ff_be_se';
import React from 'react';
import { AttendanceAttendedSubmittedIcon } from '../AttendanceChartKey';
import { AttendanceInfo, AttendanceStatusIconMap, memoizedDayOfMonth, memoizedShortDay, memoizedToday, tipAttendanceMap } from '../Utility';
import '../attendance-box.css';

type AttendanceDisplayBoxProps = Partial<AttendanceInfo> & {
  className?: string;
  dayOfMonth: number;
  showWeekDays?: boolean;
};

export const AttendanceDisplayBox = (props: AttendanceDisplayBoxProps) => {
  const { date, attendanceStatus, lessonPlanId, lessonStatus: ls, isMakeUp, enrolled, className, showWeekDays: pShowWeekDays } = props;
  const showWeekDays = pShowWeekDays ?? false;
  const lessonStatus = typeof ls === 'string' ? +ls : ls;
  // inProgress no attendance record
  const CompleteLesson = lessonStatus === LessonStatusTypes.Submitted && attendanceStatus === AttendanceStatusTypes.Attended;
  const ActualLesson = lessonStatus !== LessonStatusTypes.Scheduled && lessonPlanId && !CompleteLesson; // AttendanceStatusTypes.Scheduled
  const ScheduledLesson = lessonStatus === LessonStatusTypes.Scheduled && !lessonPlanId;
  const ScheduledAbsence = lessonStatus === LessonStatusTypes.ScheduledAbsence;
  // const NoLessonNorSchedule = lessonStatus === undefined && !lessonPlanId && enrolled;
  const NotEnrolled = !enrolled;

  const Empty = () => <></>;
  const Icon = CompleteLesson
    ? AttendanceAttendedSubmittedIcon
    : props.attendanceStatus && !ScheduledLesson
    ? AttendanceStatusIconMap[props.attendanceStatus]
    : Empty;

  let tip = attendanceStatus ? tipAttendanceMap[attendanceStatus] : 'Nothing';
  tip += isMakeUp ? ' (Makeup)' : '';
  tip += lessonStatus === LessonStatusTypes.Scheduled ? ' (Scheduled)' : lessonStatus === LessonStatusTypes.Submitted ? ' (Completed)' : '';
  tip += !lessonPlanId ? ' (No Lesson)' : '';
  tip += !enrolled ? ' (Not enrolled)' : '';

  let color = 'white';
  if (NotEnrolled) color = 'transparent';

  const border = ScheduledLesson || ScheduledAbsence ? '2px dotted' : CompleteLesson || ActualLesson ? '2px solid' : '';
  const borderColor = isMakeUp ? 'ff.teal' : border ? 'ff.blue' : 'transparent';

  const boxContentClass =
    attendanceStatus === AttendanceStatusTypes.Scheduled ? 'attendance-box_content-scheduled' : 'attendance-box_content';
  const weekDay = date ? memoizedShortDay(date) : '';
  const dayOfMonth = date ? memoizedDayOfMonth(date) : '';
  const isToday = memoizedToday() === date;
  return showWeekDays ? (
    <>
      <Center className={isToday ? 'today attendance-box_weekday' : 'attendance-box_weekday'}>{weekDay}</Center>
      <Center className={isToday ? 'today attendance-box_weekday' : 'attendance-box_weekday'}>{dayOfMonth}</Center>
      <Flex
        className={className}
        bg={color}
        alignItems="center"
        justifyContent="center"
        position="relative"
        border={border}
        borderColor={borderColor}
        title={tip}
        visibility={NotEnrolled ? 'hidden' : 'visible'}
      >
        <Box>
          <div className={boxContentClass}>
            <Icon />
          </div>
        </Box>
      </Flex>
    </>
  ) : (
    <Flex
      className={className}
      bg={color}
      alignItems="center"
      justifyContent="center"
      position="relative"
      border={border}
      borderColor={borderColor}
      title={tip}
      visibility={NotEnrolled ? 'hidden' : 'visible'}
    >
      <Box>
        <div className={boxContentClass}>
          <Icon />
        </div>
      </Box>
    </Flex>
  );
};

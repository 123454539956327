import { AttendanceStatusTypes, LessonStatusTypes } from '@edanalytics/ff_be_se';
import { DateTime as DT } from 'luxon';
import _ from 'lodash';
import {
  AttendanceAttendedIcon,
  AttendanceEventAbsenceIcon,
  AttendanceHolidayIcon,
  AttendanceStudentAbsenceIcon,
  AttendanceTutorAbsenceIcon,
  NoAttendanceRecord,
} from './AttendanceChartKey';

/* Attendance Schedule and Lesson Plan Info

This lesson plan info will be used to show the data in the attendance chart boxes.
And will be used for update. In case only the status is updated, the update sent only needs
the lessonPlanId and the attendanceStatus.
If used to enter an absence, the most fields will be used. Following the annotations in the interface below.
*/
export interface LessonPlanInfo {
  lessonPlanId: number; // for Absence this will be undefined
  // start and end of date in AttendanceInfo for Absence (endDateTime is also set)
  // this is calculated prior to sending the update
  // startDateTime: string;
  // endDateTime: string;
  attendanceStatus: AttendanceStatusTypes;
  studentId: number;
  lessonPlanType: number; // for Absence this will have the most common value for the student or "emergent"
  weekDay: string; // toISOWeekDate
  weekOrdinal: number; // based on schedule. Absence is -1 or undefined
  lessonStatus: LessonStatusTypes; // for Absence this will be ScheduledAbsence
  tutorId: number;
  isMakeUp: boolean; // not needed for Absence
}

export interface ScheduledAbsenceInfo {
  date: string;
  studentId: number;
  tutorId: number;
  attendanceStatus: AttendanceStatusTypes;
  lessonPlanType: number;
}

export interface DeleteAttendanceInfo {
  id: number;
  studentId: number;
}

export const isScheduledAbsenceInfo = (obj: any): obj is ScheduledAbsenceInfo =>
  obj &&
  'date' in obj &&
  'studentId' in obj &&
  'tutorId' in obj &&
  'attendanceStatus' in obj &&
  'lessonPlanType' in obj &&
  obj.date !== undefined &&
  obj.studentId !== undefined &&
  obj.tutorId !== undefined &&
  obj.attendanceStatus !== undefined &&
  obj.lessonPlanType !== undefined;

export const toScheduledAbsenceInfo = (obj: any): ScheduledAbsenceInfo | undefined => {
  if (!isScheduledAbsenceInfo(obj)) return undefined;
  const { date, studentId, tutorId, attendanceStatus, lessonPlanType } = obj;
  return { date, studentId, tutorId, attendanceStatus, lessonPlanType };
};

export interface UpdateAttendanceInfo {
  id: number;
  studentId: number;
  attendanceStatus: AttendanceStatusTypes;
  lessonStatus?: LessonStatusTypes;
}

export const isUpdateAttendanceInfo = (obj: any): obj is UpdateAttendanceInfo =>
  obj &&
  'id' in obj &&
  'attendanceStatus' in obj &&
  'studentId' in obj &&
  obj.id !== undefined &&
  obj.attendanceStatus !== undefined &&
  obj.studentId !== undefined;

export const toUpdateAttendanceInfo = (obj: any): UpdateAttendanceInfo | undefined => {
  const mappedId = { ...obj, id: obj.lessonPlanId };
  if (!isUpdateAttendanceInfo(mappedId)) return undefined;
  const { id, attendanceStatus, lessonStatus, studentId } = mappedId;
  return { id, attendanceStatus, lessonStatus, studentId };
};

export const isDeleteAttendanceInfo = (obj: any): obj is DeleteAttendanceInfo =>
  obj && 'id' in obj && 'studentId' in obj && obj.id !== undefined && obj.studentId !== undefined;

export const toDeleteAttendanceInfo = (obj: any): DeleteAttendanceInfo | undefined => {
  const mappedId = { ...obj, id: obj.lessonPlanId };
  if (!isDeleteAttendanceInfo(mappedId)) return undefined;
  const { id, studentId } = mappedId;
  return { id, studentId };
};

// this can be derived for each day of the month
export interface AttendanceInfo extends Partial<LessonPlanInfo> {
  date: string;
  dayOfMonth: number; // control for display
  enrolled: boolean; // omit display if false
  showWeekDays: boolean; // omit display weekdays if false
  schoolId?: number; // used in link to lesson plan
  calendarId?: number; // used in link to calendar
}

export const sameDay = (date1: Date | undefined, date2: Date) =>
  date1?.getDate() === date2.getDate() && date1?.getMonth() === date2.getMonth() && date1?.getFullYear() === date2.getFullYear();

export const isWeekend = (dt: DT | Date) => (dt instanceof Date ? [0, 6].includes(dt.getDay()) : [6, 7].includes(dt.weekday));

export const getEndOfMonth = (date: Date) => new Date(date.getFullYear(), date.getMonth() + 1, 0);

export const AttendanceStatusIconMap = {
  [AttendanceStatusTypes.Attended]: AttendanceAttendedIcon,
  [AttendanceStatusTypes.StudentAbsence]: AttendanceStudentAbsenceIcon,
  [AttendanceStatusTypes.TutorAbsence]: AttendanceTutorAbsenceIcon,
  [AttendanceStatusTypes.EventAbsence]: AttendanceEventAbsenceIcon,
  [AttendanceStatusTypes.Holiday]: AttendanceHolidayIcon,
  [AttendanceStatusTypes.Scheduled]: NoAttendanceRecord,
};
export const tipAttendanceMap = {
  [AttendanceStatusTypes.Attended]: 'Attended',
  [AttendanceStatusTypes.StudentAbsence]: 'Student Absence',
  [AttendanceStatusTypes.TutorAbsence]: 'Tutor Absence',
  [AttendanceStatusTypes.EventAbsence]: 'Event Absence',
  [AttendanceStatusTypes.Holiday]: 'Holiday',
  [AttendanceStatusTypes.Scheduled]: 'No Attendance Record',
};
export const AttendanceStatusTipMap = {
  [AttendanceStatusTypes.Attended]: 'Attended',
  [AttendanceStatusTypes.StudentAbsence]: 'Student Absence',
  [AttendanceStatusTypes.TutorAbsence]: 'Tutor Absence',
  [AttendanceStatusTypes.EventAbsence]: 'Event Absence',
  [AttendanceStatusTypes.Holiday]: 'Holiday',
  [AttendanceStatusTypes.Scheduled]: 'Scheduled',
};

export const AttendanceStatusIconKeys = Object.keys(AttendanceStatusIconMap)
  .map((key) => parseInt(key, 10) as keyof typeof AttendanceStatusIconMap | undefined)
  .concat(undefined);

export const memoizedShortDay = _.memoize((dateString: string) => {
  const shortDayMap = ['M', 'T', 'W', 'Th', 'F', 'Sa', 'Su'];
  const date = DT.fromISO(dateString);
  const shortDay = shortDayMap[date.weekday - 1];
  return shortDay;
});

export const memoizedIsWeekend = _.memoize((dateString: string) => {
  const date = DT.fromISO(dateString);
  return date.weekday === 6 || date.weekday === 7;
});

export const memoizedDayOfMonth = _.memoize((dateString: string) => {
  const date = DT.fromISO(dateString);
  return date.day;
});

export const memoizedMonthLabel = _.memoize((dateString: string) => {
  const date = DT.fromISO(dateString);
  return date.toFormat('MMM !yy').replace('!', "'");
});

export const memoizedDaysInMonth = _.memoize((date: string) => {
  const dt = DT.fromISO(date);
  return Array.from({ length: dt.daysInMonth }).map((__, i) => ({
    date: dt.set({ day: i + 1 }).toISODate(),
    dayOfMonth: i + 1,
    isWeekend: memoizedIsWeekend(dt.set({ day: i + 1 }).toISODate()),
  }));
});

export const memoizedToday = _.memoize(() => DT.local().toISODate());

export interface AttendanceCellData extends Partial<AttendanceInfo> {
  calendarId?: number;
  date: string;
  dayOfMonth: number;
  className?: string;
  onClick?: (info: Partial<AttendanceInfo & { day: number }>) => void;
}

export interface AttendanceCellHookData extends AttendanceCellData {
  statusChange: (info: Partial<AttendanceInfo>) => void;
  children: React.ReactNode;
}

export const isCompleteLesson = (attendance: Partial<AttendanceInfo>) =>
  attendance.lessonStatus === LessonStatusTypes.Submitted && attendance.attendanceStatus === AttendanceStatusTypes.Attended;

export const hasActualLesson = (attendance: { lessonPlanId?: number; lessonStatus?: LessonStatusTypes }) =>
  !!attendance.lessonPlanId && attendance.lessonStatus !== LessonStatusTypes.ScheduledAbsence; //  && !isCompleteLesson(attendance);

export const hasScheduledLesson = (attendance: Partial<AttendanceInfo>) =>
  !attendance.lessonPlanId && attendance.lessonStatus === LessonStatusTypes.Scheduled;

export const hasScheduledAbsence = (attendance: Partial<AttendanceInfo>) => attendance.lessonStatus === LessonStatusTypes.ScheduledAbsence;

export const hasScheduledActivity = (attendance: Partial<AttendanceInfo>) =>
  hasScheduledLesson(attendance) || hasScheduledAbsence(attendance) || hasActualLesson(attendance);

export const hasCalendarLesson = (attendance: { calendarId?: number }) => !!attendance.calendarId;

export const notEnrolled = (attendance: Partial<AttendanceInfo>) => !attendance.enrolled;

export const absenceStatus = (status?: AttendanceStatusTypes) =>
  status !== AttendanceStatusTypes.Attended && status !== AttendanceStatusTypes.Scheduled;

export const validStatusUpdate = (attendance: Partial<AttendanceInfo>, status?: AttendanceStatusTypes) => {
  if (hasScheduledAbsence(attendance) && !absenceStatus(status)) return false;

  if (hasActualLesson(attendance) && status === undefined) return false;

  if (hasScheduledLesson(attendance) && (!absenceStatus(status) || status === undefined)) return false;

  if (!hasScheduledActivity(attendance)) return false;
  return true;
};

export const validMonthStatusUpdate = (attendance: Partial<AttendanceInfo>, status?: AttendanceStatusTypes) => {
  if (attendance.date === undefined) return false;

  if (status === AttendanceStatusTypes.Scheduled) return false;

  if (notEnrolled(attendance) && status === AttendanceStatusTypes.Attended) return false;

  return true;
};

export const getValidAttendanceStatusKeys = (current: Partial<AttendanceInfo>) => {
  const keys = AttendanceStatusIconKeys.filter((key) => validStatusUpdate(current, key));
  return keys;
};

export const getMonthAttendanceStatusIconKeys = (current: Partial<AttendanceInfo>) => {
  const keys = AttendanceStatusIconKeys.filter((key) => validMonthStatusUpdate(current, key));
  return keys;
};

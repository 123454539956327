import { Box, Text } from '@chakra-ui/react';
import { FFRoles, getFFRolesString, DistrictDto, IDistrictSemester, DistrictSemesterDto } from '@edanalytics/ff_be_se';
import _ from 'lodash';
import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import { DateTime as DT } from 'luxon';
import { userInfoAtom } from '../ffApi';
import { DistrictServiceAtoms } from '../services/DistrictAtoms';
import { SchoolServiceAtoms } from '../services/School';
import { DebugCard } from './DebugCard';
import { FfRouterLink as Link } from './FfRouterLink';

export const SchoolSelect: React.FunctionComponent = () => {
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const [district] = useAtom(DistrictServiceAtoms.getCurrentDistrict);
  const [userInfo] = useAtom(userInfoAtom);
  const [curSemester, setCurSemester] = React.useState<any>();

  const schoolSelected = school !== undefined;

  const showChangeSchool = (userInfo.role === getFFRolesString(FFRoles.Staff) || (userInfo?.schoolsIds?.length ?? 0)) && schoolSelected;

  useEffect(() => {
    const currentSemester: any = _.filter(district?.semesters, (s: IDistrictSemester) => {
      const afterStart = new Date(s.startDate).toJSON().split('T')[0] <= new Date().toJSON().split('T')[0];
      const beforeEnd = new Date().toJSON().split('T')[0] <= new Date(s.endDate).toJSON().split('T')[0];
      return afterStart && beforeEnd;
    }).pop();

    setCurSemester(currentSemester);
  });

  return (
    <Box>
      <Text color="ff.white" fontWeight="bold">
        {district?.name}
      </Text>
      <Text color="ff.white">{school?.name}</Text>

      {curSemester && (
        <Text color="ff.white">
          {curSemester?.name} {curSemester && curSemester?.endDate.split('-')[0]}
        </Text>
      )}

      {showChangeSchool === true && (
        <Link textDecoration="underline !important" color="ff.white" to="/schools">
          Change
        </Link>
      )}
      <DebugCard data={[{ name: 'role', data: userInfo?.role }]} />
    </Box>
  );
};

import { atom } from 'jotai';
import _ from 'lodash';
import { DateTime as DT } from 'luxon';
import { LessonPlanService } from '../../../services/LessonPlan/LessonPlanService';
import { atomApiWithNavAndRead, atomApiWithRead } from '../../../utils/async-atom';
import { selectedMonthAtom } from '../AttendanceMonthSelect/AttendanceMonthSelect';
import { AttendanceMonthData } from '../AttendanceRows/AttendanceRecordRowProps';
import { schoolEnrollmentsByStudentAtom } from './AttendanceAtoms';
import { mapByStudent } from './AttendanceMapping';
import { SchoolCalendarServiceAtoms } from '../../../services/SchoolCalendar';
import { navAtom } from '../../../atoms/navAtom';

export const batchAttendanceMonthAtom = atom<string | undefined>(undefined);

export const getSchoolAttendancePerMonth = atomApiWithNavAndRead(async (get, nav) => {
  if (!nav.schoolId) return [];
  const date = get(selectedMonthAtom);
  const start = DT.fromISO(date).startOf('month').toJSDate();
  const end = DT.fromISO(date).endOf('month').toJSDate();
  const result = !nav.tutorId
    ? get(LessonPlanService).getAllForSchool(nav.schoolId, { start, end }, nav.timezone)
    : get(LessonPlanService).getAllForTutor(nav.schoolId, nav.tutorId, { start, end }, nav.timezone);
  return result;
});

///  Grouped by month
export const schoolMonthAttendanceAtom = atomApiWithRead<{ [key: string]: AttendanceMonthData }>(async (get) => {
  // first filter by month to avoid unnecessary work
  // ensure a record by student even if there are no lessons
  const { tutorId } = get(navAtom);
  const schoolCalendar = get(SchoolCalendarServiceAtoms.schoolCalendar);
  const attendanceRecord = get(getSchoolAttendancePerMonth);
  const studentEnrollments = get(schoolEnrollmentsByStudentAtom);

  if (!attendanceRecord || !schoolCalendar || attendanceRecord.length === 0) return Promise.resolve({});

  const singleRow = attendanceRecord[0];
  const startOfMonth = singleRow.startDateTime.substring(0, 7);
  const byStudent = _.groupBy(attendanceRecord, (l) => l.student?.id);

  const byStudentJoin = Object.keys(studentEnrollments).reduce((obj, id) => {
    const studentFromEnrollment = studentEnrollments[id][0].student;
    const lessons = byStudent[id] ?? [
      {
        student: {
          id: +id,
          schoolId: studentFromEnrollment.schoolId,
          fullName: studentFromEnrollment.fullName,
        },
        byUser: {
          id: -1,
          fullName: 'No tutor',
        },
      },
    ];
    const row = mapByStudent(lessons, startOfMonth, studentEnrollments[id]);
    // if not enrolled on that month, skip
    if (!row.attendance.some((a) => a.enrolled)) return obj;
    if (tutorId && row.tutor.id !== tutorId) return obj;

    row.attendance.forEach((a) => {
      const shoolLevel = schoolCalendar[a.date]?.attendanceStatus;
      if (shoolLevel) {
        a.attendanceStatus = schoolCalendar[a.date]?.attendanceStatus;
      }
      a.showWeekDays = true;
    });
    obj[id] = row;
    return obj;
  }, {} as { [key: string]: AttendanceMonthData });
  console.log('byStudentMapped', byStudentJoin);

  return Promise.resolve(byStudentJoin as { [key: string]: AttendanceMonthData });
});

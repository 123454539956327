import { Box, Divider, Text } from '@chakra-ui/react';
import { useAtomValue } from 'jotai';
import React from 'react';
import { DistrictServiceAtoms } from '../../services/DistrictAtoms';
import { SchoolServiceAtoms } from '../../services/School';
import { StudentServiceAtoms } from '../../services/Student';
import { filterRange } from '../../utils/TimeHelper';
import { atomApiWithRead } from '../../utils/async-atom';
import { StudentTutorSchedule } from './StudentTutorSchedule';
import { SemesterIdForScheduleFilter } from './useScheduleFormArray';

export interface GradeSchedulesProps {
  gradeLevel: number;
}

const inRangeEnrollmentsAtom = atomApiWithRead((get) => {
  const students = get(StudentServiceAtoms.getCurrentStudents); // ensure all students are included
  const semesterId = get(SemesterIdForScheduleFilter);
  const district = get(DistrictServiceAtoms.getCurrentDistrict);

  const semester = district?.semesters?.find((s) => s.id === +semesterId);
  if (!semester) return Promise.resolve([]);

  const enrollment = students?.flatMap((s) => s.enrollments) ?? [];
  const inRangeEnrollments = filterRange(
    enrollment.map(({ entryDate, exitDate, ...rest }) => ({ startDate: entryDate, endDate: exitDate, ...rest })),
    semester,
    'startDate',
    'endDate',
  );

  console.log('inRangeEnrollments', inRangeEnrollments);

  return Promise.resolve(inRangeEnrollments);
});

export const GradeSchedules: React.FunctionComponent<GradeSchedulesProps> = ({ gradeLevel }) => {
  const inRangeEnrollments = useAtomValue(inRangeEnrollmentsAtom);
  const sta = useAtomValue(SchoolServiceAtoms.getCurrentSchoolTutorAssignments);
  const school = useAtomValue(SchoolServiceAtoms.getCurrentSchool);

  const forGradeAndActive =
    sta?.filter((s) => s.gradeLevel === gradeLevel && s.firstName && inRangeEnrollments.some((e) => e.studentId === s.studentId)) ?? [];

  if (!sta || sta.length === 0) {
    return (
      <Box>
        <Text as="i">No Students available</Text>
      </Box>
    );
  }

  return (
    <>
      {forGradeAndActive.map((s, i) => (
        <Box padding="20px 0px" key={i}>
          <StudentTutorSchedule schoolId={school?.id as number} studentName={s.fullName} studentId={s.studentId} />
          <Divider borderColor="ff.pink" />
        </Box>
      ))}
    </>
  );
};

import { Box, Flex, Grid, GridItem, Heading, useToast } from '@chakra-ui/react';
import {
  PostSessionLessonPlanDto,
  PutSessionLessonPlanDto,
  SessionLessonPlanDto,
  StudentDto,
  UserDto,
  LessonStatusTypes,
} from '@edanalytics/ff_be_se';
import { Form, FormikProps } from 'formik';
import React from 'react';
import { DateTime as DT } from 'luxon';
import { useAtomValue } from 'jotai';
import { FfButtonTypes } from '../../models/Button';
import { FfFormikButton } from '../Formik/FfFormikButton';
import { FfFormikForm } from '../Formik/FfFormikForm';
import { FfFormikOption } from '../Formik/FfFormikOption';
import { FfFormikSelect } from '../Formik/FfFormikSelect';
import { GradeLevelLabel, ReadingLevelLabel, isAbsenceLessonPlan, getDateExclusions } from '../Utils';
import { FfDivider } from '../FfDivider';
import { attendanceOptions, lessonPlanTypeDefault, lessonPlanTypeOptions } from '../LessonPlanContent/LessonPlanUtils';
import { TimePicker } from '../TimePickerFFormik';
import { FfFormikDateTimePicker } from '../Formik/FfFormikDateTimePicker';
import {
  LESSON_PLAN_CURRENT_START_DATE,
  LESSON_PLAN_DAYS_AHEAD,
  LESSON_PLAN_DAYS_BEHIND,
  LESSON_PLAN_INTERVAL_MINS,
} from '../../config/constants';
import { StudentServiceAtoms } from '../../services/Student';
import { FfRouterLink as Link } from '../../components/FfRouterLink';
import { useLessonPlanCopyDialog } from './LessonPlanResheduleAtoms';

interface LessonPlanFormProps {
  lessonPlan: SessionLessonPlanDto;
  students: StudentDto[];
  users: UserDto[];
  isLoading: boolean;
  isSaveEnabled: boolean;
  formRef?: any;
  isAttendanceSelectorShown: boolean;
  onSave: (formData: any) => Promise<void>;
  onAttendanceSelect?: (selection: any) => any;
  isAbsentSelected?: boolean;
  openMakeUpLessonDialog?: () => void;
}

export const ReadingLevel = () => {
  const selectedStudent = useAtomValue(StudentServiceAtoms.getCurrentStudent);
  return <Box mr="1em">RL: {ReadingLevelLabel(selectedStudent?.readingLevel)}</Box>;
};

export const LessonPlanForm: React.FunctionComponent<LessonPlanFormProps> = ({
  lessonPlan,
  students,
  users,
  isLoading,
  isSaveEnabled,
  formRef,
  isAttendanceSelectorShown,
  onSave,
  onAttendanceSelect,
  isAbsentSelected,
}) => {
  const selectedStudent = useAtomValue(StudentServiceAtoms.getCurrentStudent);
  const [lessonPlanDate, setDateTime] = React.useState(DT.fromISO(lessonPlan.startDateTime).toJSDate());
  const toast = useToast();

  const [, , { startMakeup }] = useLessonPlanCopyDialog();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const setSelectedStudent = (s: any) => {
    toast({ description: 'select student is disabled', status: 'error' });
  };

  const studentOptions = students.map((s) => ({ value: s.id, key: `${s.fullName}` }));
  const userOptions = users.map((u) => ({ value: u.id, key: `${u.fullName}` }));
  const initialValues = lessonPlan;
  initialValues.studentId = lessonPlan.student?.id!;

  if (!lessonPlan.id) {
    initialValues.byUserId = lessonPlan.byUser?.id ?? 0;
    const defaultLessonPlanType = lessonPlanTypeDefault(selectedStudent?.readingLevel);
    if (defaultLessonPlanType) {
      initialValues.lessonPlanType = defaultLessonPlanType;
    }
  }

  const submittedUser = initialValues.submittedUserId ? users.find((u) => u.id === initialValues.submittedUserId) : undefined;
  const isSubmitted = !!lessonPlan?.submittedUserId || isAbsenceLessonPlan(lessonPlan);

  const enrollments = useAtomValue(StudentServiceAtoms.getCurrentStudentEnrollment);
  const exclusions = getDateExclusions(enrollments);

  const lessonPlanRange = (date: Date) =>
    DT.fromJSDate(date) >= DT.fromISO(LESSON_PLAN_CURRENT_START_DATE).startOf('day') &&
    DT.fromJSDate(date) >= DT.now().plus({ days: -LESSON_PLAN_DAYS_BEHIND }).startOf('day') &&
    DT.fromJSDate(date) <= DT.now().plus({ days: LESSON_PLAN_DAYS_AHEAD }).startOf('day');

  const updateEndDate = (formik: FormikProps<SessionLessonPlanDto>, start: Date) => {
    const datePortion = DT.fromJSDate(start).toFormat('yyyy-MM-dd');
    const startTime = DT.fromISO(formik.values.startDateTime).toFormat('HH:mm');
    const endTime = DT.fromISO(formik.values.endDateTime).toFormat('HH:mm');
    const newStartDate = DT.fromFormat(`${datePortion} ${startTime}`, 'yyyy-MM-dd HH:mm').toJSDate();
    const newEndDate = DT.fromFormat(`${datePortion} ${endTime}`, 'yyyy-MM-dd HH:mm').toJSDate();
    formik.setFieldValue('startDateTime', newStartDate.toISOString());
    formik.setFieldValue('endDateTime', newEndDate.toISOString());
  };

  return (
    <FfFormikForm
      initialValues={initialValues}
      formRef={formRef}
      validationClass={lessonPlan?.id ? PutSessionLessonPlanDto : PostSessionLessonPlanDto}
      submit={onSave}
    >
      {(formik) => (
        <Form>
          <Grid templateColumns="repeat(2, 1fr)">
            <GridItem pr="2em">
              <FfFormikSelect
                id="studentId"
                label={`Student Name`}
                options={studentOptions}
                isDisabled={lessonPlan !== undefined}
                onChange={(option: FfFormikOption) => setSelectedStudent(students.find((s) => s.id === option.value))}
              />
            </GridItem>
            <GridItem pl="2em">
              {selectedStudent && (
                <Flex fontSize="16px" h="100%" pt="1em" alignItems="center" opacity={isSubmitted ? 0.8 : 1}>
                  <ReadingLevel />
                  <Box mr="1em">GL: {GradeLevelLabel(selectedStudent?.gradeLevel)}</Box>
                  <Box>Teacher: {selectedStudent.teacher || 'Unknown'}</Box>
                </Flex>
              )}
            </GridItem>
            <GridItem pr="2em">
              {lessonPlanDate && (
                <FfFormikDateTimePicker
                  id="startDateOnly"
                  label="Lesson Date/Time"
                  isDisabled={true}
                  filterDate={lessonPlanRange}
                  selectedDate={lessonPlanDate}
                  onChange={(date) => {
                    setDateTime(date);
                    updateEndDate(formik, date);
                  }}
                  dateFormat={'MM/dd/yyyy'}
                  timeIntervals={LESSON_PLAN_INTERVAL_MINS}
                  excludeDateIntervals={exclusions}
                />
              )}
              <TimePicker
                isDisabled={true}
                id={`startDateTime`}
                endId={`endDateTime`}
                label={'Lesson Start/End Time'}
                format={'ISO'}
                rangeMode
              />
            </GridItem>
            <GridItem pl="2em">
              <FfFormikSelect id="byUserId" label="Lesson Delivered By" options={userOptions} isDisabled={isSubmitted} />
            </GridItem>
            <GridItem pr="2em">
              {isAttendanceSelectorShown && (
                <FfFormikSelect
                  id="attendanceStatus"
                  label="Attendance"
                  options={attendanceOptions}
                  isDisabled={isSubmitted}
                  onChange={onAttendanceSelect}
                />
              )}
              {isAbsenceLessonPlan(lessonPlan) && (
                <Link cursor="pointer" margin={'1'} textDecoration="underline !important" to={''} onClick={() => startMakeup(lessonPlan)}>
                  Schedule a Make-up Lesson Plan
                </Link>
              )}
            </GridItem>

            <GridItem pl="2em">
              {!lessonPlan?.id && <FfFormikSelect id="lessonPlanType" label="Lesson Plan Type" options={lessonPlanTypeOptions} />}
              {lessonPlan && (
                <Box fontSize="16px" pt="0.25em" opacity={isSubmitted ? 0.8 : 1}>
                  <Heading size="sm" mb="0.5em">
                    Submitted By
                  </Heading>
                  {submittedUser ? submittedUser.fullName : 'Not submitted'}
                </Box>
              )}
            </GridItem>
          </Grid>
          <Flex textAlign="right" mt="1em">
            <FfFormikButton
              type={FfButtonTypes.Submit}
              variant={lessonPlan?.id ? 'outline' : 'cta'}
              isDisabled={isLoading || isSubmitted || !isSaveEnabled}
              isLoading={isLoading}
            >
              {lessonPlan?.id ? 'Save Lesson Plan' : 'Continue'}
            </FfFormikButton>
          </Flex>
          <FfDivider />
        </Form>
      )}
    </FfFormikForm>
  );
};

import React from 'react';
import { useAtom } from 'jotai';
import { Box, ButtonGroup, FormControl, FormLabel, SimpleGrid, InputLeftElement, InputGroup, Divider, useToast } from '@chakra-ui/react';
import { PhoneIcon } from '@chakra-ui/icons';
import { StudentDto } from '@edanalytics/ff_be_se';

import { Form } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { RESET, useUpdateAtom } from 'jotai/utils';
import { FfButtonTypes } from '../../models/Button';
import { FfFormikInput as Input } from '../Formik/FfFormikInput';
import { FfFormikForm } from '../Formik/FfFormikForm';
import { StudentService, StudentServiceAtoms } from '../../services/Student';
import { FfFormikButton } from '../Formik/FfFormikButton';
import { FfFormikCheckbox } from '../Formik/FfFormikCheckbox';
import { PutStudentContactDto, PutStudentDto } from './PutStudentDto';
import { FfFormikSelect } from '../Formik/FfFormikSelect';
import { gradeLevelOptions } from './StudentUtils';

export interface IEditStudentFormProps {
  student: StudentDto | null; // if null we're creating a new student
}

export const EditStudentForm: React.FunctionComponent<IEditStudentFormProps> = (props) => {
  const [studentService] = useAtom(StudentService);
  const updateCurrentStudent = useUpdateAtom(StudentServiceAtoms.getCurrentStudent);
  const [initVals, setInitVals] = React.useState<PutStudentDto>();
  const nav = useNavigate();
  const params = useParams();
  const toast = useToast();
  const setInitialModel = () => {
    const newModel = Object.assign(new PutStudentDto(), props.student);
    newModel.readingLevel ??= 0;
    newModel.primaryContact = props.student?.studentContacts?.find((c) => c.primary === true) ?? new PutStudentContactDto();
    newModel.alternateContact = props.student?.studentContacts?.find((c) => c.primary === false) ?? new PutStudentContactDto();
    delete newModel.studentContacts;
    if (props.student === null) {
      newModel.schoolId = parseInt(params.schoolId ?? '0', 10);
    }
    setInitVals(newModel);
  };

  React.useEffect(() => {
    setInitialModel();
  }, []);

  return (
    <>
      {initVals && (
        <FfFormikForm
          initialValues={initVals}
          validationClass={PutStudentDto}
          validateOnBlur={true}
          validateOnChange={true}
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          reset={(v) =>
            new Promise((resolve) => {
              if (props.student !== null) {
                nav(`/schools/${props.student.schoolId}/students/${props.student.id}/`);
              } else {
                nav(`/schools/${params.schoolId}/students/add-new`);
              }
              resolve();
            })
          }
          submit={async (studentEntry) => {
            if (!studentEntry.alternateContact?.firstName) studentEntry.alternateContact = undefined;
            if (!studentEntry.primaryContact?.firstName) studentEntry.primaryContact = undefined;

            if (props.student !== null) {
              await studentService.putStudent(props.student.schoolId, props.student.id, { ...studentEntry });
              updateCurrentStudent(RESET);
              toast({ description: 'Student details saved', status: 'success' });
              nav(`/schools/${props.student.schoolId}/students/${props.student.id}/`);
            } else {
              const student = await studentService.postStudent(studentEntry.schoolId, { ...studentEntry });
              nav(`/schools/${studentEntry.schoolId}/students/${student.id}/edit?new=true`);
            }
          }}
        >
          <Form>
            <SimpleGrid columns={2} spacingX="40px" spacingY="20px">
              <Box>
                <FormControl isInvalid={false}>
                  <FormLabel htmlFor="firstName">First Name</FormLabel>
                  <Input type="text" width="100%" id="firstName" placeholder="" bg="white" />
                </FormControl>
                <FormControl isInvalid={false}>
                  <FormLabel htmlFor="photoConsent">Photo Consent?</FormLabel>
                  <FfFormikCheckbox id="photoConsent" label="Yes" isDisabled={false} />
                </FormControl>
                <FormControl isInvalid={false}>
                  <FormLabel htmlFor="programConsent">Program Consent?</FormLabel>
                  <FfFormikCheckbox id="programConsent" label="Yes" />
                </FormControl>
                <FfFormikSelect id="gradeLevel" label="Grade" options={gradeLevelOptions} borderColor={'ff.magenta'} />
              </Box>
              <Box>
                <FormControl isInvalid={false}>
                  <FormLabel htmlFor="lastName">Last Name</FormLabel>
                  <Input type="text" width="100%" id="lastName" placeholder="" bg="white" />
                </FormControl>
                <FormControl isInvalid={false}>
                  <FormLabel htmlFor="allergies">Allergies</FormLabel>
                  <Input type="text" width="100%" id="allergies" placeholder="" bg="white" />
                </FormControl>
                <FormControl isInvalid={false}>
                  <FormLabel htmlFor="Language(s)">Language(s)</FormLabel>
                  <Input type="text" width="100%" id="languages" placeholder="" bg="white" />
                </FormControl>
                <FormControl isInvalid={false}>
                  <FormLabel htmlFor="Language(s)">Teacher</FormLabel>
                  <Input type="text" width="100%" id="teacher" placeholder="" bg="white" />
                </FormControl>
              </Box>
            </SimpleGrid>
            <Divider m={'1em'} borderColor={'ff.magenta'} />
            <SimpleGrid columns={2} spacingX="40px" spacingY="20px">
              <Box>
                <FormControl isInvalid={false}>
                  <FormLabel htmlFor="firstName">Primary Contact First Name</FormLabel>
                  <Input type="text" width="100%" id="primaryContact.firstName" placeholder="" bg="white" />
                </FormControl>
                <FormControl isInvalid={false}>
                  <FormLabel htmlFor="firstName">Primary Contact Last Name</FormLabel>
                  <Input type="text" width="100%" id="primaryContact.lastName" placeholder="" bg="white" />
                </FormControl>
                <Box display="flex" flex-direction="row">
                  <FormControl isInvalid={false}>
                    <FormLabel htmlFor="firstName">Primary Contact Main Phone</FormLabel>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none" children={<PhoneIcon color="gray.300" />} />
                      <Input type="tel" id="primaryContact.phone" placeholder="" bg="white" />
                    </InputGroup>
                  </FormControl>
                  <FormControl isInvalid={false}>
                    <FormLabel htmlFor="firstName">Primary Contact Alt Phone</FormLabel>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none" children={<PhoneIcon color="gray.300" />} />
                      <Input type="tel" id="primaryContact.altPhone" placeholder="" bg="white" />
                    </InputGroup>
                  </FormControl>
                </Box>
                <FormControl isInvalid={false}>
                  <FormLabel htmlFor="firstName">Primary Address</FormLabel>
                  <Input type="text" width="100%" id="primaryContact.address" placeholder="" bg="white" />
                </FormControl>
              </Box>
              <Box>
                <FormControl isInvalid={false}>
                  <FormLabel htmlFor="Language(s)">Primary Contact Relationship</FormLabel>
                  <Input type="text" width="100%" id="primaryContact.relationship" placeholder="" bg="white" />
                </FormControl>
                <FormControl isInvalid={false}>
                  <FormLabel htmlFor="Language(s)">Primary Contact Email</FormLabel>
                  <Input width="100%" type="email" id="primaryContact.email" placeholder="" bg="white" />
                </FormControl>
              </Box>
            </SimpleGrid>
            <Divider m={'1em'} borderColor={'ff.magenta'} />
            <SimpleGrid columns={2} spacingX="40px" spacingY="20px">
              <Box>
                <FormControl isInvalid={false}>
                  <FormLabel htmlFor="firstName">Secondary Contact First Name</FormLabel>
                  <Input type="text" width="100%" id="alternateContact.firstName" placeholder="" bg="white" />
                </FormControl>
                <FormControl isInvalid={false}>
                  <FormLabel htmlFor="firstName">Secondary Contact Last Name</FormLabel>
                  <Input type="text" width="100%" id="alternateContact.lastName" placeholder="" bg="white" />
                </FormControl>
                <Box display="flex" flex-direction="row">
                  <FormControl isInvalid={false}>
                    <FormLabel htmlFor="firstName">Secondary Contact Main Phone</FormLabel>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none" children={<PhoneIcon color="gray.300" />} />
                      <Input type="tel" id="alternateContact.phone" placeholder="" bg="white" />
                    </InputGroup>
                  </FormControl>
                  <FormControl isInvalid={false}>
                    <FormLabel htmlFor="firstName">Secondary Contact Alt Phone</FormLabel>
                    <InputGroup>
                      <InputLeftElement pointerEvents="none" children={<PhoneIcon color="gray.300" />} />
                      <Input type="tel" id="alternateContact.altPhone" placeholder="" bg="white" />
                    </InputGroup>
                  </FormControl>
                </Box>
                <FormControl isInvalid={false}>
                  <FormLabel htmlFor="firstName">Secondary Address</FormLabel>
                  <Input type="text" width="100%" id="alternateContact.address" placeholder="" bg="white" />
                </FormControl>
              </Box>
              <Box>
                <FormControl isInvalid={false}>
                  <FormLabel htmlFor="Language(s)">Secondary Contact Relationship</FormLabel>
                  <Input type="text" width="100%" id="alternateContact.relationship" placeholder="" bg="white" />
                </FormControl>
                <FormControl isInvalid={false}>
                  <FormLabel htmlFor="Language(s)">Secondary Contact Email</FormLabel>
                  <Input width="100%" type="email" id="alternateContact.email" placeholder="" bg="white" />
                </FormControl>
              </Box>
            </SimpleGrid>
            <ButtonGroup>
              <FfFormikButton type={FfButtonTypes.reset}>Cancel</FfFormikButton>
              <FfFormikButton type={FfButtonTypes.Submit}>Save</FfFormikButton>
            </ButtonGroup>
          </Form>
        </FfFormikForm>
      )}
    </>
  );
};

import React from 'react';
import { Table as ReactTable } from '@tanstack/react-table';
import { Box, IconButton, HStack, VStack, Button, Select } from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { PAGINATION_ROW_OPTIONS } from '../../config/constants';

type PaginationControlProps = {
  ExtraFooter?: React.FunctionComponent<any>;
};

export const getPaginationControlsFn = (table: ReactTable<any>, rowSelection: {}, ExtraFooter?: React.FunctionComponent<any>) => () =>
  (
    <VStack alignItems="left">
      <Box>{ExtraFooter && <ExtraFooter />}</Box>
      <HStack mb="1em" ml="1em" mr="2em" spacing="2em" textAlign="left">
        <Box>
          <IconButton
            icon={<ArrowLeftIcon boxSize=".7em" />}
            size="sm"
            mr="0.5em"
            onClick={() => table.setPageIndex(0)}
            aria-label=""
            disabled={!table.getCanPreviousPage()}
          />
          <IconButton
            icon={<ChevronLeftIcon boxSize="1.3em" />}
            size="sm"
            mr="0.5em"
            onClick={() => table.previousPage()}
            aria-label=""
            disabled={!table.getCanPreviousPage()}
          />
          <IconButton
            icon={<ChevronRightIcon boxSize="1.3em" />}
            size="sm"
            mr="0.5em"
            onClick={() => table.nextPage()}
            aria-label=""
            disabled={!table.getCanNextPage()}
          />
          <IconButton
            icon={<ArrowRightIcon boxSize=".7em" />}
            size="sm"
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            aria-label=""
            disabled={!table.getCanNextPage()}
          />
        </Box>
        {table.getPageCount() && (
          <Box>
            <Select
              size="sm"
              value={table.getState().pagination.pageIndex}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) : 0;
                table.setPageIndex(page);
              }}
            >
              {Array.from(Array(table.getPageCount()).keys()).map((page) => (
                <option key={page} value={page}>
                  Page {page + 1} of {table.getPageCount()}
                </option>
              ))}
            </Select>
          </Box>
        )}
        <Box>
          <Select
            size="sm"
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {PAGINATION_ROW_OPTIONS?.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </Box>

        <Box className="debug-hide">
          <Button
            size="sm"
            // eslint-disable-next-line no-console
            onClick={() => console.info('rowSelection', rowSelection)}
          >
            Log `rowSelection` state
          </Button>
        </Box>
      </HStack>
    </VStack>
  );

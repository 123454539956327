import { Box, Stack } from '@chakra-ui/react';
import { AttendanceStudentTotalsRowHeaderProps } from './AttendanceStudentTotalsProps';
import {
  AttendanceAttendedSubmittedIcon,
  AttendanceAttendedIcon,
  AttendanceStudentAbsenceIcon,
  AttendanceTutorAbsenceIcon,
  AttendanceEventAbsenceIcon,
  AttendanceHolidayIcon,
} from '../AttendanceChartKey';

export const AttendanceStudentTotalsRowHeader: React.FunctionComponent<AttendanceStudentTotalsRowHeaderProps> = ({ groupKey }) => (
  <Stack direction="column" alignContent="center">
    <Stack key="title" direction="row" fontSize="12px">
      <div>Student Totals</div>
    </Stack>
    <Stack direction="row" fontSize={12} marginBottom="5px" marginInline="auto">
      <Box w="20px" textAlign="center" fontSize="small">
        <AttendanceAttendedSubmittedIcon />
      </Box>
      <Box w="20px" textAlign="center" fontSize="small">
        <AttendanceAttendedIcon />
      </Box>
      <Box w="20px" textAlign="center" fontSize="small">
        <AttendanceStudentAbsenceIcon />
      </Box>
      <Box w="20px" textAlign="center" fontSize="small">
        <AttendanceTutorAbsenceIcon />
      </Box>
      <Box w="20px" textAlign="center" fontSize="small">
        <AttendanceEventAbsenceIcon />
      </Box>
      <Box w="20px" textAlign="center" fontSize="small">
        <AttendanceHolidayIcon />
      </Box>
    </Stack>
  </Stack>
);

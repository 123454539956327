import {
  AttendanceStatusTypes,
  PatchLessonPlanDto,
  PostRescheduleLessonDto,
  PostSessionLessonPlanDto,
  PutSessionLessonPlanDto,
  SessionLessonPlanDto,
} from '@edanalytics/ff_be_se';
import { atom, Getter } from 'jotai';
import _ from 'lodash';
import { DateTime as DT } from 'luxon';
import { persistNavAtom, persistNavPropsAtom } from '../../atoms/navAtom';
import { apiClientAtom } from '../../ffApi';
import { atomApiWithNavAndRead, atomApiWithUpdate } from '../../utils/async-atom';
import { AddDays, sessionStartDate, strDateListToTimeZone } from '../../utils/TimeHelper';
import { GetRangeWithRowsParams, GetAllSavedForSchoolInput } from './dto/GetAllSavedForSchoolInput';
import { SchoolService } from '../School';
import { LESSON_PLAN_DAYS_AHEAD } from '../../config/constants';
import { StudentService } from '../Student';
import { callApi } from '../../utils/dateTransformer';
import { RescheduleErrorData } from '../../components/LessonPlan/LessonPlanTypes';

export const mapLessonsDates = (toUiString: (data: string | Date) => string | Date) => (r: SessionLessonPlanDto) =>
  ({
    ...r,
    startDateTime: toUiString(r.startDateTime),
    endDateTime: toUiString(r.endDateTime),
  } as SessionLessonPlanDto);

const lessonServiceFactory = (get: Getter) => ({
  get: async (schoolId: number, studentId: number, id: number): Promise<SessionLessonPlanDto> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/students/${studentId}/lesson-plans/${id}`);

    return response.data as SessionLessonPlanDto;
  },

  getAllForStudent: async (schoolId: number, studentId: number, params?: GetRangeWithRowsParams, timezone?: string) => {
    // Gets all scheduled and existing sessions for a student w/ date range
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/students/${studentId}/session-lesson-plan-schedule`, { params });

    return strDateListToTimeZone(response.data.results, timezone, ['startDateTime', 'endDateTime']) as SessionLessonPlanDto[];
  },
  getAllForTutor: async (schoolId: number, tutorId: number, params?: GetRangeWithRowsParams, timezone?: string) => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/tutors/${tutorId}/session-lesson-plan-schedule`, { params });

    return strDateListToTimeZone(response.data.results, timezone, ['startDateTime', 'endDateTime']) as SessionLessonPlanDto[];
  },
  getAllForSchool: async (schoolId: number, params?: GetRangeWithRowsParams, timezone?: string): Promise<SessionLessonPlanDto[]> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/session-lesson-plan-schedule`, { params });
    return strDateListToTimeZone(response.data.results, timezone, ['startDateTime', 'endDateTime']) as SessionLessonPlanDto[];
  },

  getAllSavedForStudent: async (schoolId: number, studentId: number, params?: GetRangeWithRowsParams): Promise<SessionLessonPlanDto[]> => {
    // All existing lesson plans for a student
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/students/${studentId}/lesson-plans`, { params });

    return response.data.results as SessionLessonPlanDto[];
  },

  getAllSavedForTutor: async (schoolId: number, tutorId: number, params?: GetRangeWithRowsParams): Promise<SessionLessonPlanDto[]> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/tutors/${tutorId}/lesson-plans`, { params });

    return response.data.results as SessionLessonPlanDto[];
  },
  getAllSavedForSchool: async (schoolId: number, params?: GetAllSavedForSchoolInput): Promise<SessionLessonPlanDto[]> => {
    const response = await get(apiClientAtom).get(`/schools/${schoolId}/lesson-plans`, { params });

    return response.data.results as SessionLessonPlanDto[];
  },

  postLessonPlan: async (schoolId: number, studentId: number, post: PostSessionLessonPlanDto): Promise<SessionLessonPlanDto> => {
    // Follow
    post.startDateTime = DT.fromISO(post.startDateTime).toUTC().toISO();
    post.endDateTime = DT.fromISO(post.endDateTime).toUTC().toISO();
    const response = await get(apiClientAtom).post(`/schools/${schoolId}/students/${studentId}/lesson-plans/`, post);

    return response.data as SessionLessonPlanDto;
  },

  postRescheduleLessonPlan: async (schoolId: number, studentId: number, payload: PostRescheduleLessonDto) => {
    payload.startDate = DT.fromISO(payload.startDate).toUTC().toISO();
    payload.endDate = DT.fromISO(payload.endDate).toUTC().toISO();
    const response = await callApi(
      get(apiClientAtom),
      'post',
      `/schools/${schoolId}/students/${studentId}/session-lesson-plan-schedule/reschedule`,
      SessionLessonPlanDto,
      payload,
      RescheduleErrorData,
    );

    return response;
  },

  putLessonPlan: async (
    // Follow
    schoolId: number,
    studentId: number,
    lessonPlanId: number,
    put: PutSessionLessonPlanDto,
  ): Promise<SessionLessonPlanDto> => {
    put.startDateTime = DT.fromISO(put.startDateTime).toUTC().toISO();
    put.endDateTime = DT.fromISO(put.endDateTime).toUTC().toISO();
    const response = await get(apiClientAtom).put(`/schools/${schoolId}/students/${studentId}/lesson-plans/${lessonPlanId}`, put);
    return response.data;
  },

  // this is intended for a Tutor role or above call
  patchLessonPlan: async (schoolId: number, studentId: number, lessonPlanId: number, patch: PatchLessonPlanDto): Promise<void> => {
    await get(apiClientAtom).patch(`/schools/${schoolId}/students/${studentId}/lesson-plans/${lessonPlanId}/`, patch);
  },

  // this is intended for a IC role or above call // used for deletes
  patchLessonPlans: async (schoolId: number, patches: PatchLessonPlanDto[]): Promise<void> => {
    await get(apiClientAtom).patch(`/schools/${schoolId}/lesson-plans/`, patches);
  },

  makeupLessonPlan: async (schoolId: number, studentId: number, lessonPlanId: number): Promise<SessionLessonPlanDto> => {
    const response = await get(apiClientAtom).post(`/schools/${schoolId}/students/${studentId}/lesson-plans/${lessonPlanId}/make-up`);
    return response.data as SessionLessonPlanDto;
  },

  makeupLessonPlanNew: async (
    schoolId: number,
    studentId: number,
    lessonPlanId: number,
    post: PostSessionLessonPlanDto,
  ): Promise<SessionLessonPlanDto> => {
    const response = await get(apiClientAtom).post(
      `/schools/${schoolId}/students/${studentId}/lesson-plans/${lessonPlanId}/make-up-new`,
      post,
    );
    return response.data as SessionLessonPlanDto;
  },

  deleteLessonPlan: async (schoolId: number, studentId: number, lessonPlanId: number): Promise<SessionLessonPlanDto> => {
    // Follow
    const response = await get(apiClientAtom).delete(`/schools/${schoolId}/students/${studentId}/lesson-plans/${lessonPlanId}`);
    return response.data as SessionLessonPlanDto;
  },
});

const LessonPlanService = atom(lessonServiceFactory);

export { LessonPlanService };

export const LessonPlanServiceAtoms = {
  getCurrentLessonPlan: persistNavPropsAtom(async (get, nav) => {
    if (!nav.schoolId || !nav.studentId || !nav.lessonPlanId) return undefined;
    return lessonServiceFactory(get).get(nav.schoolId, nav.studentId, nav.lessonPlanId);
  }),
  postLessonPlan: (post: PostSessionLessonPlanDto) =>
    persistNavPropsAtom(async (get, nav) => lessonServiceFactory(get).postLessonPlan(nav.schoolId!, nav.studentId!, post)),
  postRescheduledLessonPlan: (post: PostRescheduleLessonDto) =>
    persistNavPropsAtom(async (get, nav) => lessonServiceFactory(get).postRescheduleLessonPlan(nav.schoolId!, nav.studentId!, post)),
  // TODO: Is put.byUserId below a typo? Should be put.id?
  putLessonPlan: (put: PutSessionLessonPlanDto) =>
    persistNavPropsAtom(async (get, nav) => lessonServiceFactory(get).putLessonPlan(nav.schoolId!, nav.studentId!, put.byUserId, put)),
  patchLessonPlan: (patch: PatchLessonPlanDto) =>
    persistNavPropsAtom(async (get, nav) => lessonServiceFactory(get).patchLessonPlan(nav.schoolId!, nav.studentId!, patch.id, patch)),
  patchLessonPlans: (patches: PatchLessonPlanDto[]) =>
    persistNavPropsAtom(async (get, nav) => lessonServiceFactory(get).patchLessonPlans(nav.schoolId!, patches)),
  deleteLessonPlan: (del: SessionLessonPlanDto) =>
    persistNavPropsAtom(async (get, nav) => lessonServiceFactory(get).deleteLessonPlan(nav.schoolId!, nav.studentId!, del.id)),
  getUpdateLessonPlan: atomApiWithUpdate(
    async (get) => {
      const { schoolId, studentId, lessonPlanId } = get(persistNavAtom);
      if (!schoolId || !studentId || !lessonPlanId) return Promise.resolve(undefined);

      return get(LessonPlanService).get(schoolId, studentId, lessonPlanId);
    },
    (get, set, newLessonPlan: PutSessionLessonPlanDto) => {
      const { schoolId, studentId, lessonPlanId } = get(persistNavAtom);
      if (!schoolId || !studentId || !lessonPlanId) return Promise.resolve(undefined);

      return get(LessonPlanService).putLessonPlan(schoolId, studentId, lessonPlanId, newLessonPlan);
    },
  ),

  // USED IN STUDENT PAGE ATTENDANCE RECORD
  getStudentAttendance: atomApiWithNavAndRead(async (get, nav) => {
    if (!nav.schoolId || !nav.studentId) return [];
    const schoolData = await get(SchoolService).getSchool(nav.districtId || 1, nav.schoolId);
    return lessonServiceFactory(get).getAllForStudent(nav.schoolId, nav.studentId, {
      // start: sessionStartDate(schoolData.timezone), // WIP-schedule start date
      oneSemester: nav.oneSemester,
      end: AddDays(new Date(), LESSON_PLAN_DAYS_AHEAD, schoolData.timezone, true), // WIP  END OF MONTH 28 DAYS FROM NOW
    });
  }),

  // ALL HISTORY RECORDS
  getStudentLessonPlans: atomApiWithNavAndRead(async (get, nav) => {
    if (!nav.schoolId || !nav.studentId) return [];
    return lessonServiceFactory(get).getAllSavedForStudent(nav.schoolId, nav.studentId);
  }),
  getSchoolLessonPlans: persistNavPropsAtom(async (get, nav) => {
    if (!nav.schoolId) return [];
    return lessonServiceFactory(get).getAllSavedForSchool(nav.schoolId);
  }),
  getTutorLessonPlans: atomApiWithNavAndRead(async (get, nav) => {
    if (!nav.schoolId || !nav.tutorId) return [];
    return lessonServiceFactory(get).getAllSavedForTutor(nav.schoolId, nav.tutorId);
  }),

  // School Attendance for current month
  getSchoolAttendancePerMonth: atomApiWithNavAndRead(async (get, nav) => {
    if (!nav.schoolId) return [];
    const r = lessonServiceFactory(get).getAllForSchool(nav.schoolId, {
      start: DT.now().startOf('month').toJSDate(),
      end: DT.now().endOf('month').toJSDate(),
    });
    return r;
  }),
  // SCHEDULED  TutorDashboard SessionCard
  getScheduledTutorLessonPlans: atomApiWithNavAndRead(async (get, nav) => {
    if (!nav.schoolId || !nav.tutorId) return [];
    const schoolData = await get(SchoolService).getSchool(nav.districtId || 1, nav.schoolId);
    return lessonServiceFactory(get).getAllForTutor(nav.schoolId, nav.tutorId, {
      // start: sessionStartDate(schoolData.timezone), // WIP-schedule start date
      end: AddDays(new Date(), LESSON_PLAN_DAYS_AHEAD, schoolData.timezone, true),
    });
  }),

  getScheduledSchoolLessonPlans: atomApiWithNavAndRead(async (get, nav) => {
    if (!nav.schoolId) return [];
    const schoolData = await get(SchoolService).getSchool(nav.districtId || 1, nav.schoolId);
    const r = lessonServiceFactory(get).getAllForSchool(nav.schoolId, {
      // start: sessionStartDate(schoolData.timezone), // WIP-schedule start date
      end: AddDays(new Date(), LESSON_PLAN_DAYS_AHEAD, schoolData.timezone, true),
    });
    return r;
  }),

  // RECENT TutorDetailScreen (IC)
  getTutorRecentLessonPlans: persistNavPropsAtom(async (get, nav) => {
    if (!nav.schoolId || !nav.tutorId) return [];
    const schoolData = await get(SchoolService).getSchool(nav.districtId || 1, nav.schoolId);
    return lessonServiceFactory(get).getAllForTutor(nav.schoolId, nav.tutorId, {
      // start: sessionStartDate(schoolData.timezone), // WIP-schedule start date
      end: AddDays(new Date(), 0, schoolData.timezone, true),
    });
  }),
  getStudentRecentLessonPlans: persistNavPropsAtom(async (get, nav) => {
    if (!nav.schoolId || !nav.studentId) return [];
    const schoolData = await get(SchoolService).getSchool(nav.districtId || 1, nav.schoolId);
    return lessonServiceFactory(get).getAllForStudent(nav.schoolId, nav.studentId, {
      // start: sessionStartDate(schoolData.timezone), // WIP-schedule start date
      end: AddDays(new Date(), 0, schoolData.timezone, true),
    });
  }),

  // UPCOMING ObserveTutorSelectionScreen (IC) TutorDetailScreen (IC)
  getStudentUpcomingLessonPlans: persistNavPropsAtom(async (get, nav) => {
    if (!nav.schoolId || !nav.studentId) return [];
    const schoolData = await get(SchoolService).getSchool(nav.districtId || 1, nav.schoolId);
    return lessonServiceFactory(get).getAllForStudent(nav.schoolId, nav.studentId, {
      start: AddDays(new Date(), 0, schoolData.timezone, true),
      end: AddDays(new Date(), LESSON_PLAN_DAYS_AHEAD, schoolData.timezone, true),
    });
  }),
  getTutorUpcomingLessonPlans: persistNavPropsAtom(async (get, nav) => {
    if (!nav.schoolId || !nav.tutorId) return [];
    const schoolData = await get(SchoolService).getSchool(nav.districtId || 1, nav.schoolId);
    return lessonServiceFactory(get).getAllForTutor(nav.schoolId, nav.tutorId, {
      start: AddDays(new Date(), 0, schoolData.timezone, true),
      end: AddDays(new Date(), LESSON_PLAN_DAYS_AHEAD, schoolData.timezone, true),
    });
  }),

  // ALL INCLUDING SCHEDULED ObserveTutorSelectionScreen (IC) TutorDetailScreen (IC)
  getStudentLessonPlansPlusScheduled: persistNavPropsAtom(async (get, nav) => {
    if (!nav.schoolId || !nav.studentId) return [];
    const schoolData = await get(SchoolService).getSchool(nav.districtId || 1, nav.schoolId);
    return lessonServiceFactory(get).getAllForStudent(nav.schoolId, nav.studentId, {
      // start: sessionStartDate(schoolData.timezone), // WIP-schedule start date
      end: AddDays(new Date(), LESSON_PLAN_DAYS_AHEAD, schoolData.timezone, true),
    });
  }),
};

export const topGroupingsByImportance = atomApiWithNavAndRead(async (get, nav) => {
  const { schoolId, studentId } = nav;
  if (!studentId || !schoolId)
    return { nextLessonPlans: [] as SessionLessonPlanDto[], currentLessonPlans: [] as SessionLessonPlanDto[], active: true };
  const student = await get(StudentService).getStudent(schoolId, studentId);

  if (student.active)
    return {
      currentLessonPlans: get(LessonPlanServiceAtoms.getStudentUpcomingLessonPlans),
      nextLessonPlans: get(LessonPlanServiceAtoms.getStudentRecentLessonPlans),
      active: student.active,
    };
  const allPlans = get(LessonPlanServiceAtoms.getStudentLessonPlansPlusScheduled);
  const byCompletion = _.groupBy(allPlans, (plan) =>
    plan.attendanceStatus === AttendanceStatusTypes.Attended && (plan.submittedUserId ?? 0) > 0 ? 'yes' : 'no',
  );

  return { currentLessonPlans: byCompletion['no'], nextLessonPlans: byCompletion['yes'], active: student.active };
});

import { Flex, HStack } from '@chakra-ui/react';
import { AttendanceStatusTypes } from '@edanalytics/ff_be_se';
import { FunctionComponent } from 'react';
import {
  AttendanceCellHookData,
  AttendanceStatusIconKeys,
  AttendanceStatusIconMap,
  AttendanceStatusTipMap,
  getValidAttendanceStatusKeys,
  hasActualLesson,
} from '../Utility';

export interface AttendanceRecordDayEditProps {
  statusChange: (status: AttendanceStatusTypes) => void;
  children?: React.ReactNode;
  attendanceStatuses: (AttendanceStatusTypes | undefined)[];
}

export const AttendanceEditInternal: FunctionComponent<AttendanceRecordDayEditProps> = (props: AttendanceRecordDayEditProps) => {
  const { statusChange, attendanceStatuses, children } = props;
  const keys = attendanceStatuses.filter((a) => a !== undefined) as AttendanceStatusTypes[];

  return (
    <>
      <HStack fontSize={12} justify="center">
        {keys.map((key) => {
          const tip = AttendanceStatusTipMap[key];
          const Icon = AttendanceStatusIconMap[key];
          // shadow={`${selectedStatus === numKey.toString() ? '0px 0px 0px 3px lightGray' : ''}`}
          return (
            <Flex
              title={tip}
              key={key}
              bg={'white'}
              w="20px"
              h="20px"
              alignItems="center"
              justifyContent="center"
              border={`1px solid black`}
              onClick={() => statusChange(key)}
            >
              <Icon />
            </Flex>
          );
        })}
      </HStack>
      {children}
    </>
  );
};

type AttendanceEditProps = AttendanceCellHookData & {
  attendanceStatuses: (AttendanceStatusTypes | undefined)[];
};

export const AttendanceEdit: FunctionComponent<AttendanceEditProps> = (props) => {
  const { statusChange: oldStatusChange, attendanceStatuses } = props;
  const newProps = {
    statusChange: (status: AttendanceStatusTypes) => {
      oldStatusChange({ ...props, attendanceStatus: status });
    },
    includeNoRecordIcon: hasActualLesson(props),
    attendanceStatuses,
  };

  return <AttendanceEditInternal {...newProps}>{props.children}</AttendanceEditInternal>;
};

import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Divider, MenuItem, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export interface FecMenuProps {
  schoolId?: number;
}

export const FecMenu: React.FunctionComponent<FecMenuProps> = ({ schoolId }: FecMenuProps) => {
  const navigate = useNavigate();
  const navClick = (to: string) => () => {
    navigate(to);
  };

  return (
    <>
      {schoolId === undefined && (
        <MenuItem color={'ff.blue'} onClick={navClick('/schools')}>
          <Text as="i" color="blue">
            Select a school
          </Text>
          <Text as="i"> to view tutors and students</Text>
        </MenuItem>
      )}
      {schoolId !== undefined && (
        <>
          <MenuItem color={'ff.blue'} onClick={navClick(`/schools/${schoolId}/dashboard`)}>
            Dashboard
          </MenuItem>
          <MenuItem color={'ff.blue'} isDisabled={!schoolId} onClick={navClick(`/schools/${schoolId}/tutors`)}>
            All Tutors
          </MenuItem>
          <MenuItem color={'ff.blue'} isDisabled={!schoolId} onClick={navClick(`/schools/${schoolId}/tutors/assign`)}>
            Assign Tutors
          </MenuItem>
          <MenuItem color={'ff.blue'} isDisabled={!schoolId} onClick={navClick(`/schools/${schoolId}/students`)}>
            All Students
          </MenuItem>
          <MenuItem color={'ff.blue'} isDisabled={!schoolId} onClick={navClick(`/schools/${schoolId}/lesson-plans`)}>
            All Lesson Plans
          </MenuItem>
          <MenuItem color={'ff.blue'} onClick={navClick('/districts')}>
            School Year/Semesters
          </MenuItem>
          <MenuItem color={'ff.blue'} onClick={navClick(`/schools/${schoolId}/users`)}>
            School Directory
          </MenuItem>
          <MenuItem color={'ff.blue'} onClick={navClick(`/schools/${schoolId}/family-events`)}>
            All Events
          </MenuItem>
          <MenuItem color={'ff.blue'} onClick={navClick(`/schools/${schoolId}/family-interactions`)}>
            All Family Interactions
          </MenuItem>
          <MenuItem color={'ff.blue'} onClick={navClick(`/schools/${schoolId}/attendance`)}>
            Monthly Attendance
          </MenuItem>
          <MenuItem color={'ff.blue'} onClick={navClick('/forms')}>
            Forms
          </MenuItem>
          <Divider borderColor={'ff.blue'} />
        </>
      )}
      <MenuItem color={'ff.blue'} onClick={navClick('/logout')}>
        <ExternalLinkIcon /> Logout
      </MenuItem>
    </>
  );
};

/* eslint-disable max-classes-per-file */
import { Grid, GridItem } from '@chakra-ui/react';
import { FamilyEventAttendanceDto, FamilyEventDto, PostFamilyEventDto, PutFamilyEventDto } from '@edanalytics/ff_be_se';
import { Form, FormikProps, FormikHelpers } from 'formik';
import { useAtom } from 'jotai';
import { DateTime as DT } from 'luxon';
import React from 'react';
import { FAMILY_EVENT_INTERVAL_MINS } from '../../config/constants';
import { SchoolServiceAtoms } from '../../services/School';
import { FfFormikDateTimePicker } from '../Formik/FfFormikDateTimePicker';
import { FfFormikForm } from '../Formik/FfFormikForm';
import { FfFormikInput } from '../Formik/FfFormikInput';
import { FfFormikTextarea } from '../Formik/FfFormikTextarea';
import { TimePicker } from '../TimePickerFFormik';
import { getRoundedDate } from '../Utils';

interface FamilyEventFormProps {
  formRef?: any;
  familyEvent?: FamilyEventDto;
  isLoading: boolean;
  onSave?: (values: any) => Promise<void>;
  onDateChange: (date: Date) => void;
}

export const FamilyEventForm: React.FunctionComponent<FamilyEventFormProps> = ({
  formRef,
  familyEvent,
  onSave,
  isLoading,
  onDateChange,
}) => {
  const [startDate, setStartDate] = React.useState<Date | undefined>(undefined);
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const initialDate = getRoundedDate(FAMILY_EVENT_INTERVAL_MINS, new Date()).toISOString();
  const [initVals, setInitVals] = React.useState<FamilyEventDto>();

  const setInitialModel = () => {
    setInitVals(
      familyEvent ??
        ({
          ...new FamilyEventDto(),
          startDate: initialDate,
          endDate: initialDate,
          schoolId: school?.id,
        } as FamilyEventDto),
    );
  };

  React.useEffect(() => {
    if (familyEvent) {
      setStartDate(DT.fromISO(familyEvent.startDate).toJSDate());
    } else {
      setStartDate(DT.fromISO(initialDate).toJSDate());
    }
    formRef.current?.resetForm();
    setInitialModel();
  }, [familyEvent]);

  const updateEndDate = (formik: FormikProps<FamilyEventDto>, start: Date) => {
    const datePortion = DT.fromJSDate(start).toFormat('yyyy-MM-dd');
    const startTime = DT.fromISO(formik.values.startDate).toFormat('HH:mm');
    const endTime = DT.fromISO(formik.values.endDate).toFormat('HH:mm');
    const newStartDate = DT.fromFormat(`${datePortion} ${startTime}`, 'yyyy-MM-dd HH:mm').toJSDate();
    const newEndDate = DT.fromFormat(`${datePortion} ${endTime}`, 'yyyy-MM-dd HH:mm').toJSDate();
    formik.setFieldValue('startDate', newStartDate.toISOString());
    formik.setFieldValue('endDate', newEndDate.toISOString());
  };

  const isSubmitted = !!familyEvent?.submittedAt;

  const fieldOpacity = isLoading ? 0.4 : 1;

  return (
    <>
      {initVals && (
        <FfFormikForm
          initialValues={initVals as FamilyEventDto}
          formRef={formRef}
          validationClass={familyEvent?.id ? PutFamilyEventDto : PostFamilyEventDto}
          submit={onSave ?? (() => Promise.resolve())}
          validateOnMount={true}
        >
          {(formik) => (
            <Form>
              <Grid templateColumns="repeat(2, 1fr)" mb="2em">
                <GridItem pr="2em">
                  <FfFormikInput id="name" label="Event Title" type="text" isDisabled={isLoading || isSubmitted} />

                  {startDate && (
                    <FfFormikDateTimePicker
                      id="startDateOnly"
                      label="Event Date"
                      isDisabled={isLoading || isSubmitted}
                      filterDate={(date: Date) => DT.fromJSDate(date) >= DT.now().startOf('day')}
                      timeIntervals={FAMILY_EVENT_INTERVAL_MINS}
                      selectedDate={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                        updateEndDate(formik, date);
                        onDateChange(date);
                      }}
                      dateFormat={'MM/dd/yyyy'}
                    />
                  )}
                  <TimePicker
                    id={`startDate`}
                    endId={`endDate`}
                    label={'Event Start/End Time'}
                    format={'ISO'}
                    rangeMode
                    dontShowError
                    isDisabled={isLoading || isSubmitted}
                  />
                  <FfFormikInput id="location" label="Event Location" type="text" isDisabled={isLoading || isSubmitted} />
                </GridItem>
                <GridItem pl="2em">
                  <FfFormikTextarea
                    id="description"
                    label="Event Description"
                    noOfLines={10}
                    h="125px"
                    isDisabled={isLoading || isSubmitted}
                  />
                  <FfFormikTextarea id="notes" label="Post-Event Notes" noOfLines={10} h="125px" isDisabled={isLoading || isSubmitted} />
                </GridItem>
              </Grid>
            </Form>
          )}
        </FfFormikForm>
      )}
    </>
  );
};

import React from 'react';
import { Box, Circle, Flex } from '@chakra-ui/react';

export const AttendanceAttendedSubmittedIcon = () => <Circle size="12px" bg={'ff.green'} color="black" />;
export const AttendanceAttendedIcon = () => <Circle size="12px" color="black" borderColor="ff.green" borderWidth="2px" />;
export const AttendanceStudentAbsenceIcon = () => (
  <Box textAlign="center" color={'ff.magenta'} fontSize="11px" fontWeight="bold" w="100%">
    SA
  </Box>
);
export const AttendanceTutorAbsenceIcon = () => (
  <Box textAlign="center" color={'ff.orange'} fontSize="11px" fontWeight="bold" w="100%">
    TA
  </Box>
);
export const AttendanceEventAbsenceIcon = () => (
  <Box textAlign="center" color={'ff.darkGray'} fontSize="11px" fontWeight="bold" w="100%">
    EA
  </Box>
);
export const AttendanceHolidayIcon = () => (
  <Box textAlign="center" color={'ff.blue'} fontSize="11px" fontWeight="bold" w="100%">
    H
  </Box>
);

export const AttendanceMakeUpIcon = () => (
  <Box textAlign="center" borderColor={'ff.teal'} borderWidth="2px" borderStyle="solid" backgroundColor="white" w="100%" h="100%"></Box>
);
export const NoAttendanceRecord = () => (
  <Box color="white" fontSize="11px" textAlign="center" bg="magenta" w="100%" h="100%">
    ?
  </Box>
);

export const LessonPlanCreated = () => (
  <Box
    color="white"
    fontSize="11px"
    textAlign="center"
    borderColor={'ff.blue'}
    borderStyle="solid"
    borderWidth="2px"
    w="100%"
    h="100%"
  ></Box>
);

export const ScheduledSession = () => (
  <Box
    color="white"
    fontSize="11px"
    textAlign="center"
    borderColor={'ff.blue'}
    borderStyle="dotted"
    borderWidth="2px"
    w="100%"
    h="100%"
  ></Box>
);

type Props = {
  name: string;
  children: React.ReactNode;
  bg?: string;
};

export const Legend: React.FC<Props> = ({ name, children, bg }) => (
  <>
    <Flex bg={`${bg || 'white'}`} w="20px" h="20px" alignItems="center" justifyContent="center">
      {children}
    </Flex>
    <Box>{name}</Box>
  </>
);

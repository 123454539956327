import React from 'react';
import { Button, ButtonGroup } from '@chakra-ui/react';
import { EditIcon } from '../../Icons/EditIcon';

export interface AttendanceRecordEditProps {
  editToggleCb: () => void;
}

export const AttendanceChartEdit: React.FunctionComponent<AttendanceRecordEditProps> = (props: AttendanceRecordEditProps) => {
  const [state, setState] = React.useState('closed');
  return (
    <>
      {state === 'closed' && (
        <EditIcon
          cb={() => {
            props.editToggleCb();
            setState('editing');
          }}
        />
      )}
      {state === 'editing' && (
        <ButtonGroup>
          <Button
            onClick={() => {
              props.editToggleCb();
              setState('closed');
            }}
          >
            Done
          </Button>
        </ButtonGroup>
      )}
    </>
  );
};

import { Container, Flex, Heading } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import _ from 'lodash';
import React from 'react';
import { AttendanceTableByMonth } from '../../components/AttendanceRecord/AttendanceTableByMonth';
import { DebugCard } from '../../components/DebugCard';
import { EngagementSummaryAccordion } from '../../components/FamilyInteraction/EngagementSummaryAccordion';
import { FfDivider } from '../../components/FfDivider';
import { GroupedLessonPlans } from '../../components/GroupedLessonPlans';
import { getDateYearMonthKey } from '../../utils/utils';
import { StudentServiceAtoms } from '../../services/Student';
import { topGroupingsByImportance } from '../../services/LessonPlan/LessonPlanService';
import { SchoolServiceAtoms } from '../../services/School';
import { StudentDetailExtended } from '../../components/StudentDetailExtended';
import { ENABLE_STATS_WIDGETS } from '../../config/constants';
import { CreateMetricsGrid, CreateMetricsGridStub } from '../../components/MetricCard';
import { RunningRecordChart } from '../../components/RunningRecord/RunningRecordChart';
import { StudentBanner } from '../../components/StudentBanner';
import { StudentDetailBasic } from '../../components/StudentDetailBasic';

function groupLessons<T extends { startDateTime: string }>(recentLessonPlans: T[]) {
  const sortedRecentLessonPlans = _.sortBy(recentLessonPlans, [(o) => o.startDateTime]).reverse();
  const groupedRecentLessonPlans = _.groupBy(sortedRecentLessonPlans, (s) => getDateYearMonthKey(s, 'startDateTime'));
  return groupedRecentLessonPlans;
}

export const StudentDetailScreen: React.FunctionComponent<{ tutor: boolean }> = ({ tutor }) => {
  const [school] = useAtom(SchoolServiceAtoms.getCurrentSchool);
  const [student] = useAtom(StudentServiceAtoms.getCurrentStudent);
  const [studentMetrics] = ENABLE_STATS_WIDGETS ? useAtom(StudentServiceAtoms.getStudentMetrics) : [];
  const [{ nextLessonPlans, currentLessonPlans, active: studentActive }] = useAtom(topGroupingsByImportance);

  const groupedCurrentLessonPlans = groupLessons(currentLessonPlans);
  const groupedNextLessonPlans = groupLessons(nextLessonPlans);

  const currentHeading = studentActive ? 'Upcoming Sessions' : 'Incomplete Sessions';
  const nextHeading = studentActive ? 'Past Sessions' : 'Completed Sessions';

  return (
    <Container maxWidth="100%" fontSize="12px">
      {student && (
        <>
          <Heading color={'ff.blue'}>Student Summary ({tutor ? 'Tutor' : 'Admin'})</Heading>
          <FfDivider />
          <StudentBanner student={student} isEditLinkEnabled={true} showEnrollmentLink={true} />
          <FfDivider />
          <Flex mb={'5em'}>
            {tutor ? <StudentDetailBasic student={student} /> : <StudentDetailExtended student={student} />}
            {ENABLE_STATS_WIDGETS ? <CreateMetricsGrid metricData={studentMetrics} /> : <CreateMetricsGridStub />}
          </Flex>
          {student && groupedCurrentLessonPlans && groupedNextLessonPlans && school?.id && (
            <>
              <GroupedLessonPlans school={school} groupedLessonPlans={groupedCurrentLessonPlans} heading={currentHeading} link={true} />
              <GroupedLessonPlans school={school} groupedLessonPlans={groupedNextLessonPlans} heading={nextHeading} link={true} />
              <AttendanceTableByMonth />
              <RunningRecordChart />
              {!tutor && (
                <>
                  <EngagementSummaryAccordion />
                </>
              )}
            </>
          )}
        </>
      )}
      <DebugCard
        data={[
          { name: 'student', data: student },
          { name: 'currentPlans', data: groupedCurrentLessonPlans },
          { name: 'nextPlans', data: groupedNextLessonPlans },
        ]}
      />
    </Container>
  );
};

/* eslint-disable max-classes-per-file */
import { IsNotEmpty, IsNumber, IsBoolean, IsString, IsOptional } from 'class-validator';

// IMPORTANT: this file is a copy of \ff_be_se\shared\models\PutStudentDto.ts
// the annotations do not survive the compilation and are needed for forms.

export class PutStudentContactDto {
  @IsNumber()
  @IsOptional()
  public id: number;

  @IsString()
  @IsNotEmpty()
  public firstName: string;

  @IsString()
  @IsNotEmpty()
  public lastName: string;

  @IsString()
  @IsOptional()
  public email?: string;

  @IsString()
  @IsOptional()
  public phone?: string;

  @IsString()
  @IsOptional()
  public altPhone?: string;

  @IsString()
  @IsOptional()
  public address?: string;

  @IsString()
  @IsOptional()
  public relationship?: string;
}

export class PutStudentDto {
  @IsNumber()
  @IsOptional()
  public id: number;

  @IsNumber()
  @IsNotEmpty()
  public schoolId: number;

  @IsString()
  @IsNotEmpty()
  public firstName: string;

  @IsString()
  @IsNotEmpty()
  public lastName: string;

  @IsBoolean()
  @IsOptional()
  public photoConsent?: boolean;

  @IsString()
  @IsOptional()
  public languages?: string;

  @IsString()
  @IsOptional()
  public allergies?: string;

  @IsString()
  @IsOptional()
  public teacher?: string;

  @IsBoolean()
  @IsOptional()
  public programConsent?: boolean;

  @IsNumber()
  @IsNotEmpty()
  public gradeLevel: number;

  @IsNumber()
  @IsOptional()
  public readingLevel?: number;

  // Optional nested validation not supported as of 22/9/9
  // https://github.com/typestack/class-validator/pull/584
  @IsOptional()
  public primaryContact?: PutStudentContactDto;

  @IsOptional()
  public alternateContact?: PutStudentContactDto;
}

/* eslint-disable no-nested-ternary */
import { Box, Button, Container, Heading, Link, Select, Stack, Text, useToast, Flex } from '@chakra-ui/react';

import { FFRoles, StudentDto } from '@edanalytics/ff_be_se';
import { ColumnDef } from '@tanstack/react-table';
import { atom, useAtom, useAtomValue } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import { useNavigate } from 'react-router-dom';
import _, { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { DebugCard } from '../../components/DebugCard';
import { Empty } from '../../components/Empty';
import { EnrollmentDialog } from '../../components/Enrollment/EditEnrollmentDialog';
import { FfTable } from '../../components/FfTable';
import { RowSelectedCb } from '../../components/FfTable/FfTable';
import { studentSort } from '../../components/FfTable/Filters';
import { persistNavAtom } from '../../atoms/navAtom';
import {
  GradeLevelLabel,
  ReadingLevelLabel,
  ShortFormatDate,
  StudentEnrollmentLink,
  StudentLink,
  TeacherLabel,
  TutorLabel,
} from '../../components/Utils';
import {
  activateStudents,
  deactivateStudents,
  editEnrollmentAtomApi,
  editStudentEnrollment,
  setEditStateAtom,
} from '../../services/EnrollmentAtoms';
import { SchoolServiceAtoms } from '../../services/School';
import { StudentServiceAtoms } from '../../services/Student';
import { userInfoAtom } from '../../ffApi';

/*
// Here are the cases:
// - From a list of students
//  - From links in the table
//    1. Activate a specific student, with the id of the student
//    2. Deactivate a specific student, with the id of the student and the id of the enrollment
//    3. Edit the enrollment dates of a specific student, with the id of the student and the ids of the enrollments
//  - From the bulk edit buttons, with student checkboxes
//    4. Activate a list of students, with the ids of the students. Only enter one date
//    5. Deactivate a list of students, with the ids of the students. Only enter one date
// - From a single student enrollment (from the student details page)
//  - From links in the table
//    6. Edit the enrollment dates of a specific student, with the id of the student
//    7. Only if it is the last enrollment, allow to remove the exit date
//    8. Only if it is the last enrollment, add a delete link to remove the enrollment
//  - From the top buttons
//    9. Show Activate button if the student is deactivated. Only enter one date
//    10. Show Deactivate button if the student is activated. Only enter one date
*/

const EditEnrollmentLink = (hook: () => void): any => (
  <Link cursor="pointer" textDecoration="underline !important" color={'ff.pink'} onClick={hook}>
    Edit
  </Link>
);

interface ActivateDeactivateLinkProps {
  student: StudentDto;
}

const ActivateDeactivateLink: React.FunctionComponent<ActivateDeactivateLinkProps> = ({ student }): any => {
  // We can only go one step into the future
  const Dt = (d: string) => {
    let dt = new Date(d);
    dt = dt.toString() === 'Invalid Date' ? new Date() : dt;
    return dt;
  };
  const enabled = student.active ? Dt(student.deactivatedAt) <= new Date() : Dt(student.activatedAt) <= new Date();
  const setEditState = useUpdateAtom(setEditStateAtom);
  const hook = () =>
    enabled &&
    setEditState(
      // enrollment case
      student.active ? deactivateStudents([student]) : activateStudents([student]),
    );

  return (
    <Link cursor="pointer" textDecoration="underline !important" color={'ff.pink'} onClick={hook}>
      {student.active ? 'Deactivate' : 'Activate'}
    </Link>
  );
};

interface ExtraHeadingProps {
  selection: string;
  setSelection: (value: string) => void;
  onClick: () => void;
  noIds: boolean;
}

const ExtraHeading: React.FunctionComponent<ExtraHeadingProps> = ({ noIds, selection, setSelection, onClick }) => (
  <>
    <Box>
      <Heading size="xs" mb="0.3em">
        I want to ...
      </Heading>
      <Select bg="white" size="sm" maxWidth={'14em'} defaultValue={'View'} onChange={(e) => setSelection(e.target.value)}>
        <option value="View">View All Students</option>
        <option value="Activate">Activate Multiple Students</option>
        <option value="Deactivate">Deactivate Multiple Students</option>
      </Select>
    </Box>
    {selection !== 'View' && (
      <Box padding="1.4em 0 0 0">
        <Button variant={'cta'} disabled={noIds} size="sm" onClick={() => onClick()}>
          {selection === 'Activate' ? 'Activate' : 'Deactivate'}
        </Button>
      </Box>
    )}
  </>
);

export const selectedRowIndexesAtom = atom([] as number[]);
export const viewAtom = atom<'View' | 'Activate' | 'Deactivate'>('View');

// a read only atom that returns the selected students
export const filteredStudentsAtom = atom((get) => {
  const selection = get(viewAtom);

  let students = get(StudentServiceAtoms.getCurrentStudents) ?? [];
  if (students.length === 0) return [];
  students = students.filter(
    (s) => selection === 'View' || (s.active && selection === 'Deactivate') || (!s.active && selection === 'Activate' && !s.nextActiveAt),
  );
  // students = selection === 'View' ? students : students.filter((s, i) => selectedRowIndexes.includes(i));
  students = _.sortBy(students, [(s) => s.lastName, (s) => s.firstName]);

  return students;
});

export const StudentsScreen: React.FunctionComponent = () => {
  const school = useAtomValue(SchoolServiceAtoms.getCurrentSchool);
  const students = useAtomValue(filteredStudentsAtom);
  const [selectedRowIndexes, setSelectedRowIndexes] = useAtom(selectedRowIndexesAtom);
  const toast = useToast();
  const setEditState = useUpdateAtom(setEditStateAtom);
  const [selection, setSelection] = useAtom(viewAtom);
  const validationMessage = useAtomValue(editEnrollmentAtomApi);
  const [userInfo] = useAtom(userInfoAtom);
  const nav = useNavigate();
  const navProps = useAtomValue(persistNavAtom);

  const transform = (student: StudentDto) => {
    const { activatedAt, deactivatedAt, ...rest } = student;
    const rowHighlightClass = student.active ? '' : selection === 'Activate' ? '' : 'inactive-student';
    return { ...rest, activatedAt, deactivatedAt, rowHighlightClass };
  };

  useEffect(() => {
    setSelectedRowIndexes([]);
  }, [selection]);

  useEffect(() => {
    setSelection('View');
  }, []);

  useEffect(() => {
    if (validationMessage === 'Ok') {
      setSelectedRowIndexes([]);
    }
  }, [validationMessage]);

  const columns: ColumnDef<StudentDto>[] = [
    {
      accessorFn: (row) => row,
      id: 'fullName',
      cell: (info: any) => StudentLink(info.getValue()),
      header: () => 'Name',
      filterFn: 'student',
      sortingFn: studentSort,
    },
    {
      accessorKey: 'gradeLevel',
      id: 'gradeLevel',
      size: 80,
      cell: (info: any) => GradeLevelLabel(info.getValue()),
      header: () => 'Grade',
    },
    {
      accessorKey: 'readingLevel',
      id: 'readingLevel',
      size: 80,
      cell: (info: any) => ReadingLevelLabel(info.getValue()),
      header: () => 'Level',
    },
    {
      accessorKey: 'teacher',
      id: 'teacher',
      cell: (info: any) => TeacherLabel(info.getValue()),
      header: () => 'Teacher',
    },
    {
      accessorKey: 'activatedAt',
      accessorFn: (o: StudentDto) => o,
      size: 80,
      cell: (info: any) => (
        <Text>
          {ShortFormatDate(info.getValue().nextActiveAt ?? info.getValue().activatedAt)}
          {info.getValue().nextActiveAt ? '*' : ''}
        </Text>
      ),
      header: () => 'Active',
    },
    {
      accessorKey: 'deactivatedAt',
      size: 80,
      cell: (info: any) => <Text>{ShortFormatDate(info.getValue())}</Text>,
      header: () => 'Inactive',
    },
    {
      id: 'edit',
      accessorFn: (o) => o,
      cell: (info: any) =>
        userInfo.roleId !== FFRoles.Tutor &&
        EditEnrollmentLink(() =>
          setEditState(
            // enrollment case
            editStudentEnrollment(info.getValue() as StudentDto, true),
          ),
        ),
      header: () => '',
    },
    {
      id: 'activate',
      accessorFn: (o) => o,
      cell: (info: any) =>
        userInfo.roleId !== FFRoles.Tutor &&
        (info.getValue().nextActiveAt ? <></> : <ActivateDeactivateLink student={info.getValue() as StudentDto} />),
      header: () => '',
    },
    {
      id: 'enrollmentLink',
      accessorFn: (o) => o,
      cell: (info: any) =>
        school && <StudentEnrollmentLink school={school} student={info.getValue() as StudentDto} text={'See Enrollment History'} />,
      header: () => '',
    },
  ];

  const rowSelectCb = (rows: RowSelectedCb) => {
    setSelectedRowIndexes(Object.keys(rows.data).map((k) => Number(k)));
  };

  const onClickAddNewStudent = () => {
    nav(`/schools/${navProps.schoolId}/students/add-new`);
  };

  return (
    <Container maxWidth="100%">
      <Flex mb="1em">
        <Box flex={1}>
          <Heading color={'ff.blue'} mb="1em">
            All Students
          </Heading>
        </Box>
        {userInfo.roleId !== FFRoles.Tutor && (
          <Box textAlign="right" flex={0}>
            <Button disabled={false} isLoading={false} variant={'cta'} onClick={onClickAddNewStudent}>
              Add New Student
            </Button>
          </Box>
        )}
      </Flex>
      <Stack>
        <FfTable
          extraHeading={
            <ExtraHeading
              selection={selection}
              onClick={() => {
                setEditState(
                  selection === 'Activate' // enrollment case
                    ? activateStudents(selectedRowIndexes.map((i) => students[i]))
                    : deactivateStudents(selectedRowIndexes.map((i) => students[i])),
                );
              }}
              setSelection={setSelection as (value: string) => void}
              noIds={selectedRowIndexes.length === 0}
            />
          }
          columns={columns}
          selectedRowIndexes={selectedRowIndexes}
          rowSelectedCb={(rows: any) => rowSelectCb(rows)}
          data={students.map(transform)}
          enableRowSelection={selection === 'Activate' || selection === 'Deactivate'}
        />
        <Text fontSize="sm">* Student set to be active at a future date</Text>
      </Stack>
      <EnrollmentDialog />
      <DebugCard data={[{ name: 'students', data: students }]} />
    </Container>
  );
};

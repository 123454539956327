import _ from 'lodash';
import { LessonPlanService, LessonPlanServiceAtoms } from '../../../services/LessonPlan/LessonPlanService';
import { StudentServiceAtoms } from '../../../services/Student';
import { atomApiWithNavAndRead, atomApiWithRead } from '../../../utils/async-atom';
import { districtMonthsAtom } from '../AttendanceMonthSelect/districtMonthsAtom';
import { AttendanceMonthData } from '../AttendanceRows/AttendanceRecordRowProps';
import { Enrollment, mapByStudent } from './AttendanceMapping';
import { LESSON_PLAN_DAYS_AHEAD } from '../../../config/constants';
import { SchoolService } from '../../../services/School';
import { AddDays } from '../../../utils/TimeHelper';
import { studentAtom } from '../../FamilyInteraction/GenericInteractionForm';
import { getEndOfMonth } from '../Utility';

export const currentStudentEnrollment = atomApiWithNavAndRead<Enrollment[]>((get, nav) => {
  if (!nav.schoolId) return Promise.resolve([]);

  const studentEnrollmentsData = get(StudentServiceAtoms.getCurrentStudentEnrollment) ?? [];
  const studentEnrollments = studentEnrollmentsData.map(({ entryDate, exitDate, studentId, ...rest }) => {
    const a = {
      ...rest,
      entryDate: typeof entryDate === 'string' ? (entryDate as string).substring(0, 10) : entryDate?.toISOString().substring(0, 10),
      exitDate: typeof exitDate === 'string' ? (exitDate as string).substring(0, 10) : exitDate?.toISOString().substring(0, 10),
      student: {
        id: studentId,
        schoolId: nav.schoolId!,
        fullName: 'No student',
      },
      schoolId: nav.schoolId!,
    };
    return a;
  });

  return Promise.resolve(studentEnrollments);
});

export const getStudentAttendance = atomApiWithNavAndRead(async (get, nav) => {
  if (!nav.schoolId) return [];
  const student = get(StudentServiceAtoms.getCurrentStudent) ?? get(studentAtom);
  const studentId = student?.id;
  if (!studentId) return [];

  const schoolData = await get(SchoolService).getSchool(nav.districtId || 1, nav.schoolId);

  const endDateAhead = AddDays(new Date(), LESSON_PLAN_DAYS_AHEAD, schoolData.timezone, true);
  const endOfMonthDate = getEndOfMonth(new Date());

  const attendanceRecord = await get(LessonPlanService).getAllForStudent(nav.schoolId, studentId, {
    oneSemester: nav.oneSemester,
    end: endDateAhead > endOfMonthDate ? getEndOfMonth(endDateAhead) : endOfMonthDate, // WIP  END OF MONTH 28 DAYS FROM NOW
  });

  return [student, attendanceRecord] as [typeof student, typeof attendanceRecord];
});

export const studentAttendanceAtom = atomApiWithRead<{ [key: string]: AttendanceMonthData }>((get) => {
  const [studentRecord, attendanceRecord] = get(getStudentAttendance);
  if (!studentRecord) return Promise.resolve({});
  const studentEnrollments = get(currentStudentEnrollment);
  const districtMonths = get(districtMonthsAtom);

  if (!attendanceRecord || attendanceRecord.length === 0) return Promise.resolve({});
  const byMonth = _.groupBy(attendanceRecord, (l) => l.startDateTime.substring(0, 7));

  const defaultLessons = [
    {
      student: {
        id: studentRecord.id,
        schoolId: studentRecord.schoolId,
        fullName: studentRecord.fullName,
      },
      byUser: {
        id: -1,
        fullName: 'No tutor',
      },
    },
  ];

  const byMonthMapped = districtMonths
    .filter(({ pastToNextMonth }) => pastToNextMonth)
    .reduce((obj, month) => {
      const monthString = month.val;
      const newRow = mapByStudent(byMonth[monthString] ?? defaultLessons, monthString, studentEnrollments);
      if (newRow.attendance.some((a) => a.enrolled)) {
        obj[monthString] = mapByStudent(byMonth[monthString] ?? defaultLessons, monthString, studentEnrollments);
      }
      return obj;
    }, {} as { [key: string]: AttendanceMonthData });

  return Promise.resolve(byMonthMapped as { [key: string]: AttendanceMonthData });
});

import { Box, Link, Popover, PopoverArrow, PopoverBody, PopoverContent, PopoverTrigger, useDisclosure } from '@chakra-ui/react';
import { useAtom } from 'jotai';
import { useUpdateAtom } from 'jotai/utils';
import _ from 'lodash';
import React, { FunctionComponent } from 'react';
import { AttendanceStatusTypes } from '@edanalytics/ff_be_se';
import { FfRouterLink } from '../../FfRouterLink';
import { attendanceEditAtom, dayOfMonthEditAtom, updateScheduleAtom, updateScheduleAllStudents } from '../State/AttendanceAtoms';
import { AttendanceCellData, AttendanceCellHookData, AttendanceInfo, getValidAttendanceStatusKeys, hasCalendarLesson } from '../Utility';
import { AttendanceDisplayBox } from './AttendanceDisplayBox';
import { AttendanceEdit } from './AttendanceEditBox';

export interface AttendanceStatusBoxProps extends Partial<AttendanceInfo> {
  enrolled: boolean;
}

export interface AttendanceRecordDayProps {
  date?: Date;
  attendanceStatus: AttendanceStatusBoxProps;
  statusChange: (attendanceStatus: AttendanceInfo) => void;
}

// updating the state at a higher level closes the popup.
// at a lower level creates a problem with refs.
// it's easier to just use a local state, for this case.
// almost identical to AttendanceByStudentHeaderPopup
export const AttendanceBoxPopupInternal: React.FunctionComponent<
  AttendanceCellHookData & { showWeekDays?: boolean; children: React.ReactNode }
> = (props) => {
  const [edit] = useAtom(attendanceEditAtom);
  const [dateEdit, setDateEdit] = useAtom(dayOfMonthEditAtom);

  const { onOpen, onClose, isOpen } = useDisclosure();
  const attendanceStatuses = getValidAttendanceStatusKeys(props);
  const editable = props.enrolled && edit && attendanceStatuses.length > 0;

  // open popup
  const onClick = (dProps: Partial<AttendanceInfo>) => {
    if (props.enrolled) return;
    setDateEdit(dProps.date);
  };

  const statusChangeLocal = (att: Partial<AttendanceInfo>) => {
    props.statusChange(att);
    onClose();
  };

  // close popup without updating attendance
  const onCloseLocal = () => {
    setDateEdit(undefined);
    onClose();
  };

  // enlarged-box or normal-box
  const className = props.date === dateEdit || isOpen ? 'enlarged-box' : 'normal-box';

  const newProps = { ...props, onClick, className, showWeekDays: props.showWeekDays, attendanceStatuses };

  // EDIT MODE
  return editable ? (
    <Popover placement="bottom" isOpen={isOpen} onOpen={onOpen} onClose={onCloseLocal}>
      <PopoverTrigger>
        <Box>
          <AttendanceDisplayBox {...newProps} />
        </Box>
      </PopoverTrigger>
      <PopoverContent w="200px" color="white" bg="white">
        <PopoverArrow bg="white" border="1px solid" />
        <PopoverBody textAlign="center">
          <AttendanceEdit {...props} attendanceStatuses={attendanceStatuses}>
            {props.children}
          </AttendanceEdit>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  ) : (
    // DISPLAY MODE
    <div>
      <AttendanceDisplayBox {...newProps} />
    </div>
  );
};

interface LessonLinkProps {
  lessonPlanId?: number;
  studentId?: number;
  schoolId?: number;
}

export const LessonLink: FunctionComponent<LessonLinkProps> = ({ lessonPlanId, studentId, schoolId }) =>
  lessonPlanId && studentId && schoolId ? (
    <FfRouterLink fontSize="medium" to={`/schools/${schoolId}/students/${studentId}/lesson-plans/${lessonPlanId}`}>
      Go To Lesson Plan
    </FfRouterLink>
  ) : (
    <></>
  );

const ResetAttendanceEditLink: FunctionComponent<AttendanceCellData> = (props) => {
  const updateSingleSchedule = useUpdateAtom(updateScheduleAtom);

  const resetAttendance = () => {
    updateSingleSchedule({ ...props, attendanceStatus: undefined });
  };

  return (
    <Link fontSize="medium" onClick={resetAttendance}>
      Reset
    </Link>
  );
};

export const AttendanceBoxByStudentPopup: React.FunctionComponent<AttendanceCellData & { showWeekDays?: boolean }> = React.memo((props) => {
  const updateAttendance = useUpdateAtom(updateScheduleAtom);
  const setDateEdit = useUpdateAtom(dayOfMonthEditAtom);

  const validStatuses = getValidAttendanceStatusKeys(props);
  const canRemove = validStatuses.some((a) => a === undefined);
  const canLink = validStatuses.some((a) => a === AttendanceStatusTypes.Attended);

  const statusChangeLocal = (att: Partial<AttendanceInfo>) => {
    updateAttendance(att);
    setDateEdit(undefined);
  };

  return (
    <AttendanceBoxPopupInternal {...props} statusChange={statusChangeLocal}>
      {canRemove ? <ResetAttendanceEditLink {...props} /> : <></>}
      {canLink ? <LessonLink {...props} /> : <></>}
    </AttendanceBoxPopupInternal>
  );
}, _.isEqual);

export const AttendanceBoxByMonthPopup: React.FunctionComponent<AttendanceCellData & { showWeekDays?: boolean }> = React.memo((props) => {
  const updateAttendance = useUpdateAtom(updateScheduleAtom);
  const setDateEdit = useUpdateAtom(dayOfMonthEditAtom);

  const validStatuses = getValidAttendanceStatusKeys(props);
  const canRemove = validStatuses.some((a) => a === undefined);
  const canLink = validStatuses.some((a) => a === AttendanceStatusTypes.Attended);

  const statusChangeLocal = (att: Partial<AttendanceInfo>) => {
    updateAttendance(att);
    setDateEdit(undefined);
  };

  return (
    <AttendanceBoxPopupInternal {...props} statusChange={statusChangeLocal}>
      {canRemove ? <ResetAttendanceEditLink {...props} /> : <></>}
      {canLink ? <LessonLink {...props} /> : <></>}
    </AttendanceBoxPopupInternal>
  );
}, _.isEqual);
